<template>
  <v-menu v-model="menu" :close-on-content-click="false">
    <template v-slot:activator="{ props }">
      <!--Activator Button-->
      <v-btn v-bind="props" class="px-4 label_btn" variant="flat">
        {{ tags_btn }}
        <v-icon class="ml-2">mdi mdi-menu-down</v-icon>
      </v-btn>
    </template>
    <!--Menu Container-->
    <v-sheet color="white">
      <!--Tags Container-->
      <v-card
        elevation="0"
        max-height="300"
        class="overflow-auto scroll"
        color="white"
      >
        <v-list lines="one" density="compact">
          <v-list-item
            v-if="tags_list.length != 0"
            @click="filtering_candidates(-1, tags_list.length)"
          >
            <template v-slot:prepend>
              <v-icon size="small" v-show="filter_tags_value[tags_list.length]"
                >mdi mdi-filter</v-icon
              >
              <v-icon size="small" v-show="!filter_tags_value[tags_list.length]"
                >mdi mdi-filter-off</v-icon
              >
            </template>

            <v-list-item-title class="mr-3" style="color: #34495e"> {{ no_tags_filter }} </v-list-item-title>
          </v-list-item>
          <v-list-item
            v-for="(item, index) in tags_list"
            :key="item"
            @click="filtering_candidates(item.id, index)"
          >
            <template v-slot:prepend>
              <v-icon size="small" v-show="filter_tags_value[index]"
                >mdi mdi-filter</v-icon
              >
              <v-icon size="small" v-show="!filter_tags_value[index]"
                >mdi mdi-filter-off</v-icon
              >
            </template>

            <v-list-item-title class="mr-3" style="color: #34495e">{{
              item.texto
            }}</v-list-item-title>

            <template v-slot:append>
              <!--Icon to edit a tag-->
              <div>
                <v-btn
                  variant="text"
                  size="small"
                  icon="mdi mdi-pencil"
                  style="color: #34495e"
                  :disabled="filter"
                >
                </v-btn>
                <EditTagDialog :tag="cloneTag(item)" />
              </div>
              <!--Icon to delete a tag-->
              <div>
                <v-btn
                  variant="text"
                  size="small"
                  icon="mdi mdi-trash-can"
                  style="color: #34495e"
                  :disabled="filter"
                >
                </v-btn>
                <DeleteTagDialog :tag="item" />
              </div>
            </template>
          </v-list-item>
        </v-list>
      </v-card>

      <!--New Tag Button-->
      <v-btn
        value="new_label"
        width="100%"
        append-icon="mdi mdi-plus"
        class="new_label_item text-none"
        rounded="lg"
        size="large"
      >
        {{ new_tag_btn }}

        <!--Dialog to add a new tag-->
        <v-dialog
          v-model="dialog"
          activator="parent"
          persistent
          :width="mdAndUp ? '25%' : '90%'"
          @keydown.enter="newTag"
        >
          <v-card
            class="d-flex justify-center text-center"
          >
            <v-container>
              <!--Title Dialog-->
              <v-card-title class="text-uppercase" style="color: #34495e"
                >{{ title }} </v-card-title
              >
              <v-card-text>
                <v-form v-model="form"  @submit.prevent>
                  <!--Input Dialog-->
                  <v-row dense class="mt-5">
                    <v-col cols="12">
                      <v-text-field
                        v-model="newtag"
                        required
                        autofocus
                        density="compact"
                        variant="solo"
                        class="newtag_tf"
                        type="text"
                        bg-color="#BDF0EE"
                        :placeholder="newtag_placeholder"
                        :rules="newtagRules"
                        hide-details
                        single-line
                      >
                        <template v-slot:prepend-inner>
                          <v-icon color="#00C3C0"
                            >mdi mdi-label-multiple</v-icon
                          >
                        </template>
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <!--Buttons Dialog-->
                  <v-row dense class="mt-8">
                    <v-col cols="12">
                      <!--Dialog Cancel Button -->
                     <v-btn color="error" @click="dialog = false" class="mr-3">
                        {{ cancel_btn }}
                      </v-btn>
                      <!--Dialog Save Button -->
                      <v-btn
                        class="dialog_save_btn"
                        :disabled="!form"
                        @click.once="newTag"
                      >
                        {{ save_btn }}
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>
            </v-container>
          </v-card>
        </v-dialog>
      </v-btn>
    </v-sheet>
  </v-menu>

  <v-snackbar
    v-model="snackbar"
    :timeout="2000"
    variant="elevated"
    class="mb-5"
    location="bottom right"
    color="error"
    width="auto"
    transition="scroll-x-reverse-transition"
  >
    <h3 class="text-white">
      <v-icon color="white" class="mr-1" size="30">mdi mdi-check-circle</v-icon>
      Esa etiqueta ya existe
    </h3>
  </v-snackbar>
</template>

<script>
import { defineComponent } from "vue";
import { mapState } from "vuex";
import { mapMutations } from "vuex";
import { useDisplay } from "vuetify";

//API Services
import TagsService from "@/services/TagsService";

// Components
import DeleteTagDialog from "@/components/DashboardComponents/ProfilesEvaluation/DeleteTagDialog.vue";
import EditTagDialog from "@/components/DashboardComponents/ProfilesEvaluation/EditTagDialog.vue";

export default defineComponent({
  name: "TagsMenu",
  data() {
    return {
      //Form
      form: false,

      menu: false,

      //Filter
      filter: false,
      append_icons: true,
      selected_tags: [],
      selected_tags_to_filter: [],
      no_tags_filter: "",

      //SnackBar
      snackbar: false,

      //Label
      tags_btn: "",
      new_tag_btn : "",

      //New Label Dialog
      dialog: false,
      title : "",

      //New Label Text Field Placeholder Dialog
      newtag_placeholder: "",

      //Buttons Dialog
      cancel_btn: "",
      save_btn: "",

      valid: false,
      newtag: "",
      newtagRules: [(v) => !!v || "Nombre de la etiqueta requerido"],

      all_tags: [],
    };
  },
  components: {
    DeleteTagDialog,
    EditTagDialog,
  },

  computed: {
    ...mapState([
      "tags_list",
      "newtag_dialog",
      "user_info",
      "candidates_to_show",
      "candidates",
      "filter_tags_value",
    ]),
  },

  created() {
    this.set_texts();
  },

  methods: {
    ...mapMutations([
      "add_new_tag",
      "delete_tag",
      "setTags_list",
      "filter_candidates",
      "show_all_candidates",
      "setFalse_filter_tag_value",
      "setTrue_filter_tag_value",
    ]),

    set_texts (){
      let t = sessionStorage.getItem("texts");
      let texts = JSON.parse(t);

      this.tags_btn = texts[71];
      this.newtag_placeholder = texts[75];
      this.cancel_btn = texts[36];
      this.save_btn = texts[76];

      this.title = texts[74];
      this.new_tag_btn = texts[73];

      this.no_tags_filter = texts[72];
    },

    icon_filter_model() {
      this.selected_tags = [];
      for (let i = 0; i < this.tags_list.length; i++) {
        this.selected_tags.push(false);
      }
      if(this.tags_list.length != 0){
        this.selected_tags.push(false);
      }
    },

    //Filter Tags
    filter_off() {
      this.filter = false;
      this.append_icons = true;
      this.selected_tags = [];
      this.show_all_candidates();
    },

    filter_on() {
      this.filter = true;
      this.append_icons = false;
    },

    filtering_candidates(tag_id, tag_index) {
      this.filter = true;
      if (this.selected_tags_to_filter.includes(tag_id)) {
        this.setFalse_filter_tag_value(tag_index);
        let index = this.selected_tags_to_filter.indexOf(tag_id);
        this.selected_tags_to_filter.splice(index, 1);
        if (this.selected_tags_to_filter.length == 0) {
          this.filter = false;
          this.show_all_candidates();
        } else {
          this.filter_candidates(this.selected_tags_to_filter);
        }
      } else {
        this.setTrue_filter_tag_value(tag_index);
        this.selected_tags_to_filter.push(tag_id);
        this.filter_candidates(this.selected_tags_to_filter);
      }
    },

    //Clone Tag to edit
    cloneTag(tag) {
      let t = structuredClone(tag);
      return t;
    },

    //Save New Label
    save_new_tag(tag) {
      if (this.tags_list.includes(tag)) {
        this.newtag = "";
        this.snackbar = true;
      } else {
        this.add_new_tag(tag);
        this.dialog = false;
        this.newtag = "";
      }
    },

    async newTag() {
      let formData = new FormData();
      formData.append("proyecto_id", this.user_info.tokens.tokenPE);
      formData.append("texto", this.newtag);

      try {
        const response = await TagsService.newTag(formData);
        let data = response.data;

        if (data.code === 200) {
          this.dialog = false;
          this.newtag = "";
          this.loadAllTags();
        }
      } catch (error) {
        console.log(error);
      }
    },

    //Aux
    async loadAllTags() {
      try {
        const response = await TagsService.getAllTags(
          this.user_info.tokens.tokenPE
        );
        let data = response.data;

        this.setTags_list(data);
      } catch (error) {
        console.log(error);
      }
    },
  },


  setup() {
    // Destructure only the keys we want to use
    const { mdAndUp } = useDisplay();

    return { mdAndUp };
  },
});
</script>

<style scoped>
.label_btn {
  background-color: #00c3c0;
  color: white;
}

.item_list :hover{
  background: #f4ffff;
}

.new_label_item {
  font-size: 16px;
  color: #34495e;
  background-color: #bdf0ee;
}

.newtag_tf {
  font-style: italic;
  font-weight: bold;
  background-color: #bdf0ee;
  width: auto;
}

.dialog_save_btn {
  background-color: #34495e;
  color: white;
}
</style>