<template>
  <v-sheet class="mb-8" height="100%" width="100%" color="transparent">
    <!--Contact Button-->
    <v-row dense class="mt-5 mx-3 d-flex justify-center">
      <v-col cols="12">
        <v-btn
          block
          rounded="lg"
          density="comfortable"
          size="large"
          class="font-weight-bold btn py-7"
          href="https://web.culturalfitsolutions.com/contacto-plataforma-cultural-fit"
          target="_blank"
        >
          {{ contact_btn }}
        </v-btn>
      </v-col>
    </v-row>

    <!--Support Contact Button-->
    <v-row dense class="mt-3 mx-3 d-flex justify-center">
      <v-col cols="12" md="10">
        <v-btn
          block
          rounded="lg"
          density="comfortable"
          size="large"
          class="font-weight-bold btn py-7"
          href="https://web.culturalfitsolutions.com/ticketing-plataforma-cultural-fit"
          target="_blank"
        >
          {{ support_contact_btn }}
        </v-btn>
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
import { useDisplay } from "vuetify";
import { mapState } from "vuex";

export default {
  name: "ProfilesEvaluationNavBar",
  components: {},

  data: () => ({
    dialog: false,

    //Label
    link_btn_line1: "Assessment de Evaluación",
    link_btn_line2: "de Perfiles (No Ánonimo)",
    link_mdbtn_line1: "Assessment de Evaluación",
    link_mdbtn_line2: "de Perfiles",
    link_mdbtn_line3: "(No Ánonimo)",
    support_contact_btn: "Contactar con soporte",

    contact_btn: "Contáctanos",
  }),

  computed: {
    ...mapState(["external_link"]),
  },

  methods: {
    async write_to_clipboard() {
      try {
        await navigator.clipboard.writeText(this.external_link);
      } catch (err) {
        console.error("Error al copiar al portapapeles:", err);
      }
      this.dialog = true;
    },
  },

  setup() {
    // Destructure only the keys we want to use
    const { md } = useDisplay();

    return { md };
  },
};
</script>

<style scoped>
.btn {
  background-color: #34495e;
  color: white;
  font-size: 12px;
}
</style>
