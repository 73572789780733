<!-- eslint-disable vue/no-side-effects-in-computed-properties -->
<template>
  <v-tooltip :text="tooltip" location="top">
    <template v-slot:activator="{ props }">
      <apexchart
        v-bind="props"
        type="radar"
        :height="chart_height"
        :options="chartOptions"
        :series="org_serie"
      ></apexchart>
    </template>
  </v-tooltip>
</template>
  
  <script>
import { mapState } from "vuex";
import { useDisplay } from "vuetify";
import { computed } from "vue";

export default {
  name: "OneSerieSpiderChart",
  props: {
    titles: {
      type: Object,
    },
  },
  data: () => ({
    tooltip: "",

    chartOptions: {
      chart: {
        type: "radar",
        toolbar: {
          show: false,
        },
      },
      xaxis: {
        categories: [],
        labels: {
          show: true,
          style: {
            colors: [
              "#34495e",
              "#34495e",
              "#34495e",
              "#34495e",
              "#34495e",
              "#34495e",
            ],
            fontSize: "11px",
            fontFamily: "Arial",
          },
        },
      },
      legend: {
        show: "true",
        position: "top",
      },

      yaxis: {
        show: false,
      },
      stroke: {
        width: 1,
        colors: ["#00C3C0"],
      },
      markers: {
        size: 0,
      },
      fill: {
        colors: ["#00C3C0"],
      },
    },
  }),

  computed: {
    ...mapState(["org_serie", "archetypes_titles", "org_chart_options"]),
  },

  methods: {
    set_texts() {
      let t = sessionStorage.getItem("texts");
      let texts = JSON.parse(t);
      this.tooltip = texts[48];

      let a = sessionStorage.getItem("archetypes");
      this.chartOptions.xaxis.categories = JSON.parse(a);
    },
  },

  created() {
    //this.setCategories();
    this.set_texts();
  },

  setup() {
    // Destructure only the keys we want to use
    const { mdAndUp } = useDisplay();
    const { xsAndUp } = useDisplay();

    const { name } = useDisplay();

    const chart_height = computed(() => {
      // name is reactive and
      // must use .value
      switch (name.value) {
        case "xs":
          return "200%";
        case "sm":
          return "200%";
        case "md":
          return (90 * ((45 * ((92 * window.innerHeight) / 100)) / 100)) / 100;
        case "lg":
          return (100 * ((45 * ((92 * window.innerHeight) / 100)) / 100)) / 100;
        case "xl":
          return (100 * ((45 * ((92 * window.innerHeight) / 100)) / 100)) / 100;
        case "xxl":
          return (100 * ((45 * ((92 * window.innerHeight) / 100)) / 100)) / 100;
      }

      return undefined;
    });

    // eslint-disable-next-line vue/no-dupe-keys
    return { chart_height, mdAndUp, xsAndUp };
  },
};
</script>