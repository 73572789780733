<template>
  <div :class="mdAndUp ? 'text-center mt-n6' : ''">
    <v-tooltip
      :text="tooltip"
      location="top"
    >
      <template v-slot:activator="{ props }">
        <apexchart
          v-bind="props"
          type="radar"
          :height="chart_height"
          :options="chartOptions"
          :series="teamAndCompanyAdjustment.currentVSTeam"
        ></apexchart>
      </template>
    </v-tooltip>
  </div>
</template>
    
<script>
import { mapState } from "vuex";
import { useDisplay } from "vuetify";
import { computed } from "vue";

export default {
  name: "OrgTeamEngagementChart",
  props: {
    arq_titles: {
      type : Array,
    }
  },
  data: () => ({
    series: [
      {
        name: "Cultura de las personas del equipo",
        data: [70, 50, 90, 45, 70, 50],
      },
      {
        name: "Cultura de la organización",
        data: [50, 80, 40, 80, 95, 90],
      },
    ],
    chartOptions: {
      chart: {
        height: 350,
        type: "radar",
        toolbar: {
          show: false,
        },
      },
      xaxis: {
        categories : [],
        labels: {
          show: true,
          style: {
            colors: [
              "#34495e",
              "#34495e",
              "#34495e",
              "#34495e",
              "#34495e",
              "#34495e",
            ],
            fontSize: "11px",
            fontFamily: "Arial",
          },
        },
      },

      yaxis: {
        show: false,
      },
      legend: {
        show: true,
        offsetY: -24,
        labels: {
          colors: "#34495e",
        },
        fontWeight: 600,
        fontSize: '9px',
        markers: {
          width: 12,
          height: 12,
          strokeWidth: 0,
          strokeColor: "#fff",
          fillColors: ["#00C3C0", "#34495E"],
          radius: 0,
          onClick: undefined,
        },
      },
      stroke: {
        width: 1,
        colors: ["#00C3C0", "#34495E"],
      },
      markers: {
        size: 0,
      },
      fill: {
        colors: ["#00C3C0", "#34495E"],
      },
    },

    tooltip: "",
  }),

  computed: {
    ...mapState(["teamAndCompanyAdjustment"]),
  },

  methods: {
    set_texts (){
      let t = sessionStorage.getItem("texts");
      let texts = JSON.parse(t);
      this.tooltip = texts[52];

      let a = sessionStorage.getItem("archetypes");
      this.chartOptions.xaxis.categories = JSON.parse(a);
    }
  },

  created () {
    this.set_texts();
  },

  setup() {
    // Destructure only the keys we want to use
    const { mdAndUp } = useDisplay();
    const { xsAndUp } = useDisplay();

    const { name } = useDisplay();

    const chart_height = computed(() => {
      // name is reactive and
      // must use .value
      switch (name.value) {
        case "xs":
          return "200%";
        case "sm":
          return "200%";
        case "md":
          return (95 * ((45 * ((92 * window.innerHeight) / 100)) / 100)) / 100;
        case "lg":
          return (100 * ((45 * ((92 * window.innerHeight) / 100)) / 100)) / 100;
        case "xl":
          return (100 * ((45 * ((92 * window.innerHeight) / 100)) / 100)) / 100;
        case "xxl":
          return (100 * ((45 * ((92 * window.innerHeight) / 100)) / 100)) / 100;
      }

      return undefined;
    });

    // eslint-disable-next-line vue/no-dupe-keys
    return { chart_height, mdAndUp, xsAndUp };
  },
};
</script>