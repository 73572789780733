<template>
  <v-sheet color="transparent" :height="bottom_sheet_height" width="100%">
    <v-row dense style="height: 70%">
      <!--Company Engagement-->
      <v-col cols="12" md="3" class="text-center cardwhiteEV">
        <h3 class="text-uppercase" style="color: #34495e">
          {{ title1_line1 }}
        </h3>

        <v-row dense>
          <v-col cols="12" class="d-flex justify-center">
            <div class="w-75">
              <p class="subtitle">{{ subtitle1 }}</p>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" :class="mdAndUp ? '' : 'd-flex justify-center'">
            <Person_EthicChart />
          </v-col>
        </v-row>
      </v-col>

      <!--Kpis Engagement Graphic-->
      <v-col
        cols="12"
        md="3"
        :class="mdAndUp ? 'text-center  cardwhiteEV' : 'text-center'"
      >
        <!--Title-->
        <v-row dense class="">
          <v-col cols="12">
            <h3 class="text-uppercase" style="color: #34495e">
              {{ title2 }}
            </h3>
          </v-col>
        </v-row>
        <!--Progress Circular-->
        <v-row dense class="mt-5 d-flex justify-center">
          <v-col cols="6">
            <v-row dense class="mt-5">
              <v-col cols="12">
                <v-progress-circular
                  class="mb-1"
                  :rotate="360"
                  :size="120"
                  :width="8"
                  :model-value="ethicalData.integrityIndicator"
                  :color="color1"
                  bg-color="transparent"
                >
                  <v-avatar size="105" :color="background_color1">
                    <h1 style="color: #34495e">
                      {{ ethicalData.integrityIndicator }}
                    </h1>
                  </v-avatar>
                </v-progress-circular>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <!--Legend-->
        <v-row dense :class="mdAndUp ? 'mt-10' : 'mt-10 mx-5'">
          <v-col cols="12">
            <div class="rounded-pill">
              <v-row dense class="d-flex justify-center">
                <V-col cols="4">
                  <h5>BAJO</h5>
                </V-col>
                <V-col cols="4">
                  <h5>ESTÁNDAR</h5>
                </V-col>
                <V-col cols="4">
                  <h5>ALTO</h5>
                </V-col>
              </v-row>
            </div>
          </v-col>
        </v-row>
        <v-row dense :class="mdAndUp ? '' : 'mx-3'">
          <v-col cols="12">
            <div class="rounded-pill legend py-3 w-100">
              <v-row dense class="d-flex justify-center mx-1">
                <V-col cols="4" class="d-flex align-center justify-center">
                  <div
                    class="rounded-pill pl-1 pt-2 w-25"
                    style="background-color: #fffc8b"
                  ></div>
                  <p class="ml-1 legend_text">Menos de 60</p>
                </V-col>
                <V-col cols="4" class="d-flex align-center justify-center">
                  <div
                    class="rounded-pill pl-1 pt-2 w-25"
                    style="background-color: #cadfb8"
                  ></div>
                  <p class="ml-1 legend_text">60-80</p>
                </V-col>
                <V-col cols="4" class="d-flex align-center justify-center">
                  <div
                    class="rounded-pill pl-1 pt-2 w-25"
                    style="background-color: #b1d095"
                  ></div>
                  <p class="ml-1 legend_text">Más de 80</p>
                </V-col>
              </v-row>
            </div>
          </v-col>
        </v-row>
      </v-col>
      <!--Company Engagement-->

      <v-col cols="11" md="5">
        <v-row dense>
          <EthicCommonBehavior />
        </v-row>
      </v-col>
    </v-row>

    <v-row class="text-justify footer">
      <p>
        Este índice <strong>no es un veredicto final</strong> sobre la ética de
        una persona. Actúa como un indicador que evalúa la mayor o menor
        predominancia en el candidato de los valores inherentes a la ética,
        dentro del total de sus valores. Un índice bajo no señala per se una
        ausencia de ética, sino oportunidades para alinear más estrechamente las
        acciones con los valores éticos. <br />
        Queda prohibida la reproducción total o parcial del material protegido
        por estos derechos de propiedad intelectual (NÚMERO DE ASIENTO REGISTRAL
        04 / 2022 / 3374), o su uso en cualquier forma, o por cualquier medio,
        ya sea electrónico o mecánico, incluyendo fotocopiado, grabación,
        transmisión o cualquier sistema de almacenamiento y recuperación de
        información, sin el permiso por escrito de Cultural Fit Solutions S.L.
      </p></v-row
    >
  </v-sheet>
</template>

<script>
import { defineComponent } from "vue";
import { useDisplay } from "vuetify";
import { computed } from "vue";

// Components

import Person_EthicChart from "@/components/DashboardComponents/ProfilesEvaluation/Person_EthicChart.vue";
import EthicCommonBehavior from "@/components/DashboardComponents/ProfilesEvaluation/EthicCommonBehavior.vue";
import { mapState } from "vuex";
export default defineComponent({
  name: "CandidateEthicalView",

  components: {
    EthicCommonBehavior,
    Person_EthicChart,
  },

  data: () => ({
    //Labels
    title1_line1: "Valores de Comportamiento Ético",
    title1_line2: "Comportamiento Ético",
    subtitle1:
      "Predominancia de valores en el comportamiento ético de la persona",

    title2: "Índice de Comportamiento Ético",

    title3_line1: "Ajuste Cultural",
    title3_line2: "Mentalidad Persona-Cultura Deseada",
    subtitle3:
      "Identifica en qué grado encaja la mentalidad de la persona con la cultura meta",
  }),

  computed: {
    ...mapState(["engagementData", "ethicalData"]),
    color1() {
      return this.color_integrityIndicator();
    },

    color2() {
      return this.color_des_org_fit();
    },

    background_color1() {
      return this.background_color_integrityIndicator();
    },

    /*  background_color2() {
      return this.background_color_integrityIndicator();
    }, */
  },

  methods: {
    color_integrityIndicator() {
      if (this.ethicalData.integrityIndicator < 60) {
        return "#F7E66B";
      }
      if (this.ethicalData.integrityIndicator > 80) {
        return "#8ABE66";
      }
      if (
        this.ethicalData.integrityIndicator >= 60 &&
        this.ethicalData.integrityIndicator <= 80
      ) {
        return "#CADFB8";
      }
    },

    color_des_org_fit() {
      if (this.engagementData.des_org_fit < 60) {
        return "#F7E66B";
      }
      if (this.engagementData.des_org_fit > 80) {
        return "#8ABE66";
      }

      if (
        this.engagementData.des_org_fit >= 60 &&
        this.engagementData.des_org_fit <= 80
      ) {
        return "#CADFB8";
      }
    },

    background_color_des_org_fit() {
      if (this.engagementData.des_org_fit < 60) {
        return "#FBF3BB";
      }
      if (this.engagementData.des_org_fit > 80) {
        return "#C9E1B9";
      }
      if (
        this.engagementData.des_org_fit >= 60 &&
        this.engagementData.des_org_fit <= 80
      ) {
        return "#E7F0DE";
      }
    },

    background_color_integrityIndicator() {
      if (this.ethicalData.integrityIndicator < 60) {
        return "#FBF3BB";
      }
      if (this.ethicalData.integrityIndicator > 80) {
        return "#C9E1B9";
      }
      if (
        this.ethicalData.integrityIndicator >= 60 &&
        this.ethicalData.integrityIndicator <= 80
      ) {
        return "#E7F0DE";
      }
    },
  },

  setup() {
    // Destructure only the keys we want to use
    const { mdAndUp } = useDisplay();

    const { name } = useDisplay();

    const bottom_sheet_height = computed(() => {
      // name is reactive and
      // must use .value
      switch (name.value) {
        case "xs":
          return "auto";
        case "sm":
          return "auto";
        case "md":
          return (80 * ((92 * window.innerHeight) / 100)) / 100;
        case "lg":
          return (85 * ((92 * window.innerHeight) / 100)) / 100;
        case "xl":
          return (85 * ((92 * window.innerHeight) / 100)) / 100;
        case "xxl":
          return (85 * ((92 * window.innerHeight) / 100)) / 100;
      }

      return undefined;
    });

    return { mdAndUp, bottom_sheet_height };
  },
});
</script>

<style scoped>
.subtitle {
  color: #585858;
  font-weight: bold;
  font-size: 14px;
}

.col {
  border: 1px solid #00c3c0 !important;
}

.legend {
  border: 1px solid black !important;
}

.legend_text {
  font-size: 13px;
}
.cardwhiteEV {
  background-color: white;
  border-radius: 10px;
  border: 1px solid #efefef;
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
  /*  padding: 20px;*/
  margin-left: 2%;
  margin-top: 2%;
  margin-bottom: 10%;
}
.footer {
  font-size: 58%;
  font-style: italic;
  color: #585858;
  padding: 2%;
  margin-right: 45%;
}
</style>
