<script setup>
import { computed, defineProps } from "vue";
import { useStore } from "vuex";
import DictionaryForReport from "@/globals/DictionaryForReport";
import CandidateReport_Ideal36_Chart from "@/components/DashboardComponents/ProfilesEvaluation/CandidateReport/CandidateReport_Ideal36_Chart.vue";

const store = useStore();

const props = defineProps({
    report36Data: Array,
    name: String,
    paramName: String,
    comparative: String,
    enterprise: String,
    chartColors: {
      type: Array,
      default: () => ["#02c071", "#34495e"],
    },
});

const AdjustValues = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][111];
});

const footerText = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][3];
});
</script>

<template>
  <div id="appendix1Page2" class="page">
    <div class="page-body">
      <div class="main-container">
        <div class="title">
          {{ AdjustValues }}
          <strong>{{ props.name }}</strong> & 
          <span v-if="props.paramName"><strong>{{ props.paramName }}</strong></span>
          <span v-else>{{ props.comparative }} en <strong>{{ props.enterprise }}</strong></span>
        </div>

        <div class="chart2">
          <CandidateReport_Ideal36_Chart
            width="630px"
            height="630px"
            v-bind:series="props.report36Data"
            :colors="props.chartColors"
          />
        </div>
      </div>
    </div>
    <div class="page-footer">
      {{ footerText }}
    </div>
  </div>
</template>

<style lang="scss" scoped>
.chart {
  width: 260px;
  height: 260px;
  margin-left: auto;
  margin-right: auto;
}
.chart2 {
  width: 630px;
  height: 630px;
  margin-left: auto;
  margin-right: auto;
}

.page1-lines-logo {
  display: block;
  width: 58px;
  position: absolute;
  margin-top: 380px;
  right: 0px;
  transform: rotateY(180deg);
}

table {
  td {
    border: none !important;
  }
}
</style>