<!-- eslint-disable vue/no-mutating-props -->
<template>
  <!--Dialog to add a new label-->
  <v-dialog
    v-model="login_email_validation_dialog"
    persistent
    :width="mdAndUp ? '40%' : '90%'"
  >
    <v-card class="d-flex justify-center text-center">
      <!--ToolBar to close the dialog-->
      <v-toolbar color="transparent" height="50">
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn icon @click="closeDialog" :ripple="false" variant="text">
            <v-icon color="#34495e">mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-container fluid>
        <!-- Dialog Title-->
        <h1 class="text-uppercase" style="color: #34495e">
          {{ dialog_title }}
        </h1>
        <!--Dialog Subtitle 1-->
        <v-row dense class="d-flex justify-center text-center">
          <v-col cols="11" md="10">
            <div>
              <p class="text-subtitle-1 mt-3" style="color: #585858">
                {{ dialog_subtitle1 }}
              </p>
            </div>
          </v-col>
        </v-row>
        <!--Dialog Subtitle 1-->
        <v-row dense class="d-flex justify-center text-center">
          <v-col cols="11" md="6">
            <div>
              <h4 class="mt-3" style="color: #34495e">
                {{ dialog_subtitle2 }}
              </h4>
            </div>
          </v-col>
        </v-row>

        <v-card-text>
          <v-form v-model="form">
            <!--Dialog Input-->
            <v-row dense class="mt-3 d-flex justify-center">
              <v-col cols="11" md="6">
                <v-text-field
                  v-model="code_value"
                  required
                  variant="flat"
                  class="code_tf"
                  type="text"
                  bg-color="#BDF0EE"
                  :placeholder="code_placeholder"
                  :rules="codeRules"
                  hide-details
                  single-line
                  @keydown.enter="checkPin"
                >
                  <template v-slot:prepend-inner>
                    <v-icon color="#00C3C0">mdi mdi-email-lock</v-icon>
                  </template>
                </v-text-field>
              </v-col>
            </v-row>
            <!--Dialog Buttons-->
            <v-row dense class="mt-5 d-flex justify-center">
              <v-col cols="11" md="6">
                <!--Validating Code Button Dialog-->
                <v-btn
                  block
                  class="validate_code_btn py-6"
                  :disabled="!form"
                  @click="checkPin"
                >
                  {{ validate_code }}
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
          <!--Dialog Question-->
          <!-- <v-row dense class="d-flex justify-center text-center">
            <v-col cols="11" md="6">
              <div>
                <h4 class="mt-5" style="color: #34495e">
                  {{ dialog_question }}
                </h4>
              </div>
            </v-col>
          </v-row>
         
          <v-row dense class="d-flex justify-center text-center">
            <v-col cols="11" md="6">
              <v-btn variant="text" ripple="false" style="color: #00c3c0">
                <v-icon class="mr-2">mdi mdi-sync</v-icon>
                {{ dialog_resend_email }}
              </v-btn>
            </v-col>
          </v-row> -->
        </v-card-text>
      </v-container>
    </v-card>
  </v-dialog>

  <v-snackbar
    v-model="snackbar"
    :timeout="2000"
    variant="elevated"
    class="mb-5"
    location="bottom right"
    color="error"
    width="auto"
    transition="scroll-x-reverse-transition"
  >
    <h3 class="text-white">
      <v-icon color="white" class="mr-1" size="30">mdi mdi-check-circle</v-icon>
      {{ snack_message }}
    </h3>
  </v-snackbar>
</template>
    
  <script>
import { useDisplay } from "vuetify";
import { mapMutations, mapState } from "vuex";
import { ROUTES } from "@/router/names";

//API Services
import LoginService from "@/services/LoginService";

export default {
  name: "LoginEmailValidationDialog",
  props: {
    user_email: {
      type: String,
    },
  },
  data: () => ({
    // Form data
    form: false,

    //Snackbar Notification
    snackbar: false,
    snack_message: "",

    //Verification Dialog
    dialog: false,

    //Dialog texts
    dialog_title: "",
    dialog_subtitle1: "",
    dialog_subtitle2: "",

    dialog_question: "¿No has recibido el código?",
    dialog_resend_email: "Reenviar email",

    //Dialog Text Field Placeholder
    code_placeholder: "",

    //Button Dialog
    validate_code: "",

    valid: false,
    code_value: "",
    codeRules: [(v) => !!v || "Codigo requerido"],

    ROUTES: ROUTES,
  }),

  computed: {
    ...mapState(["login_email_validation_dialog"]),
  },

  components: {},

  created() {
    this.set_texts();
  },

  methods: {
    ...mapMutations(["setFalse_login_email_validation_dialog", "setUser_info"]),

    set_texts() {
      let t = sessionStorage.getItem("texts");
      let texts = JSON.parse(t);

      this.dialog_title = texts[14];
      this.dialog_subtitle1 = texts[15];
      this.dialog_subtitle2 = texts[16];
      this.code_placeholder = texts[17];
      this.validate_code = texts[18];
    },

    async checkPin() {
      let formData = new FormData();
      formData.append("email", this.user_email);
      formData.append("pin", this.code_value);

      try {
        const response = await LoginService.checkPin(
          formData,
          this.user_email.authorisation_token
        );
        let data = response.data;

        if (data.status == "500") {
          this.snack_message = data.message;
          this.snackbar = true;
          this.code_value = "";
        }

        if (data.status == "200") {
          this.setUser_info(data);
          this.setFalse_login_email_validation_dialog();
          this.$router.push(ROUTES.RECOVER_PASSWORD);
        }
      } catch (error) {
        console.log(error);
      }
    },

    closeDialog() {
      this.setFalse_login_email_validation_dialog();
      this.code_value = "";
    },
  },
  setup() {
    // Destructure only the keys we want to use
    const { mdAndUp } = useDisplay();

    return { mdAndUp };
  },
};
</script>
  
<style scoped>
.code_tf {
  font-style: italic;
  font-weight: bold;
  background-color: #bdf0ee;
  width: auto;
}

.validate_code_btn {
  background-color: #34495e;
  color: white;
}
</style>