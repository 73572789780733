<template>
  <v-main class="main_bg">
    <!--Profile App Bar-->
    <v-app-bar
      fixed
      elevate-on-scroll
      :elevation="0"
      :height="app_bar_height"
      color="#34495E"
    >
      <v-toolbar color="#34495E">
        <v-row dense align="center">
          <v-col cols="10" md="2">
            <!--Avatar Cultural Fit in colors-->
            <v-avatar color="#34495E" size="150" rounded="0" class="ml-10">
              <v-img
                src="~@/assets/White-GreenLogo.png"
                max-height="100%"
                max-width="100%"
                contain
              ></v-img>
            </v-avatar>
          </v-col>

          <v-col md="7" lg="6" class="hidden-sm-and-down">
            <!--App Bar Tabs-->
            <!-- <v-tabs
              mandatory="false"
              class="ml-16 hidden-sm-and-down text-white"
              color="#00C3C0"
              height="50"
            >
            
              <v-tab
                :value="1"
                class="text-start text-none tabs"
                prepend-icon="mdi mdi-chart-multiple"
                @click="goto_dashboard_1"
              >
                <h3>
                  Diagnóstico <br />
                  de cultura corporativa
                </h3>
              </v-tab>

            
              <v-tab
                :value="2"
                prepend-icon="mdi mdi-file-document-multiple-outline"
                class="text-start text-none tabs"
                @click="goto_dashboard_2"
              >
                <h3>
                  {{ profiles_evaluation }}
                </h3>
              </v-tab>
            </v-tabs> -->
          </v-col>

          <!--User Info-->
          <v-col md="3" lg="4" class="hidden-sm-and-down">
            <v-row dense>
              <v-col
                cols="12"
                md="4"
                xl="5"
                class="d-flex align-center justify-end"
              >
                <v-avatar color="white">
                  <v-icon color="#00c3c0">mdi mdi-account</v-icon>
                </v-avatar>
              </v-col>
              <v-col md="5" xl="5" class="text-start">
                <h4 class="text-white text-uppercase hidden-sm-and-down">
                  {{ user_info.company_name }}
                </h4>
                <h5 style="color: #00c3c0">
                  {{ user_info.full_name }}
                </h5>
              </v-col>
              <!--Log out-->
              <v-col md="3" xl="2" class="d-flex align-center">
                <v-tooltip :text="logout" location="start">
                  <template v-slot:activator="{ props }">
                    <v-btn
                      v-bind="props"
                      :class="xlAndUp ? 'text-white' : 'text-white ml-8'"
                      icon="mdi mdi-logout"
                      @click="log_out"
                      :ripple="false"
                      variant="text"
                    >
                    </v-btn>
                  </template>
                </v-tooltip>
              </v-col>
            </v-row>
          </v-col>

          <!--Responsive-->
          <v-col cols="2" class="hidden-sm-and-up text-center">
            <!--Menu responsive to device md and down with the links-->
            <v-menu>
              <template v-slot:activator="{ props }">
                <v-btn
                  v-bind="props"
                  class="hidden-sm-and-up text-white"
                  size="x-large"
                  icon="mdi-menu"
                >
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  :title="user_info.company_name"
                  :subtitle="user_info.full_name"
                >
                  <template v-slot:prepend>
                    <v-avatar color="white">
                      <v-icon color="#00c3c0">mdi mdi-account</v-icon>
                    </v-avatar>
                  </template>
                </v-list-item>
                <v-list-item
                  :value="logout"
                  prepend-icon="mdi mdi-logout"
                  @click="log_out"
                >
                  <v-list-item-title>{{ logout }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>
        </v-row>
      </v-toolbar>
    </v-app-bar>

    <!--End Profile App Bar-->

    <!--Edit Profile View-->
    <v-sheet
      :height="main_container_height"
      color="transparent"
      class="d-flex align-center"
    >
      <v-row dense style="heigth: 100%">
        <v-col cols="12" class="d-flex align-center justify-center">
          <router-view />
        </v-col>
      </v-row>
    </v-sheet>
  </v-main>
</template>

<script>
import { ROUTES } from "@/router/names";
import { mapMutations, mapState } from "vuex";
import { useDisplay } from "vuetify";
import { computed } from "vue";

export default {
  name: "EditProfileLayout",
  components: {},

  data: () => ({
    //App Bar Tabs Labels
    logout: "Cerrar Sesión",

    app_bar_height: (8 * window.innerHeight) / 100,

    ROUTES: ROUTES,
  }),

  computed: {
    ...mapState(["user_info"]),
  },

  created() {
    this.set_texts();
  },

  methods: {
    ...mapMutations([
      "dashboard_step_to_1",
      "dashboard_step_to_2",
      "dashboard_step_to_3",
      "detailed_view_change_step",
    ]),

    set_texts() {
      let t = sessionStorage.getItem("texts");
      let texts = JSON.parse(t);
      this.logout = texts[27];
    },

    goto_dashboard_1() {
      this.dashboard_step_to_1();
      this.$router.replace(ROUTES.DASHBOARD);
    },
    goto_dashboard_2() {
      this.dashboard_step_to_2();
      this.$router.replace(ROUTES.DASHBOARD);
    },
    goto_dashboard_3() {
      this.dashboard_step_to_3();
      this.$router.replace(ROUTES.DASHBOARD);
    },

    log_out() {
      this.$router.replace(ROUTES.LOGIN);
      this.dashboard_step_to_2();
      this.detailed_view_change_step();
      sessionStorage.removeItem("current_user");
      sessionStorage.removeItem("authorisation_token");
    },
  },

  setup() {
    // Destructure only the keys we want to use
    const { mdAndUp } = useDisplay();
    const { xlAndUp } = useDisplay();
    const { xsAndUp } = useDisplay();

    const { name } = useDisplay();

    const main_container_height = computed(() => {
      // name is reactive and
      // must use .value
      switch (name.value) {
        case "xs":
          return "auto";
        case "sm":
          return "auto";
        case "md":
          return (92 * window.innerHeight) / 100;
        case "lg":
          return (92 * window.innerHeight) / 100;
        case "xl":
          return (92 * window.innerHeight) / 100;
        case "xxl":
          return (92 * window.innerHeight) / 100;
      }

      return undefined;
    });

    // eslint-disable-next-line vue/no-dupe-keys
    return { main_container_height, xlAndUp, mdAndUp, xsAndUp };
  },
};
</script>

<style scoped>
.testing {
  color: #00c3c0;
}

.main_bg {
  background-color: #fbfbfb;
}

.tabs {
  font-size: 12px;
}
</style>
