<template>
  <v-main>
    <LoginAppBar app />
    <v-sheet :height="mdAndUp ? main_container_height : ''">
      <v-row dense class="row">
        <v-col cols="12" class="d-flex align-center">
          <LoginView />
        </v-col>
      </v-row>
    </v-sheet>
  </v-main>
</template>
  
  <script>
import { useDisplay } from "vuetify";

import LoginAppBar from "@/components/LoginComponents/LoginAppBar.vue";
import LoginView from "@/views/LoginView.vue";

export default {
  name: "LoginLayout",
  components: {
    LoginAppBar,
    LoginView,
  },
  data() {
    return {
      main_container_height: (88 * window.innerHeight) / 100,
    };
  },

  computed: {},

  setup() {
    // Destructure only the keys we want to use
    const { lgAndUp } = useDisplay();
    const { mdAndUp } = useDisplay();

    return { lgAndUp, mdAndUp };
  },
};
</script>

  <style scoped>
.row {
  height: 100% !important;
}
</style>