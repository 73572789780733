<template>
  <v-card
    elevation="0"
    :class="
      mdAndUp
        ? 'overflow-auto principles_card cardwhitePValues'
        : 'mt-1 mb-10 text-center'
    "
    :height="principles_card_height"
    width="100%"
  >
    <v-row
      dense
      :class="mdAndUp ? 'text-justify mx-3 mt-2' : 'text-justify mx-3 mt-5'"
      style="height: 90%"
    >
      <v-col cols="12" md="12" class="d-flex flex-column justify-space-around">
        <div dense class="d-flex">
          <v-col cols="3" class="d-flex align-center borderblackPredValues">
            <h5 class="text-uppercase">Valores Predominantes</h5>
          </v-col>
          <v-col
            cols="5"
            class="ml-3 d-flex align-center borderblackPredValues"
          >
            <h5 class="text-uppercase">Comportamientos esperados</h5>
          </v-col>
          <v-col
            cols="3"
            class="ml-3 d-flex align-center borderblackPredValues"
          >
            <h5 class="text-uppercase">Competencias esperadas</h5>
          </v-col>
        </div>

        <div
          dense
          v-for="(item, index) in predValuesData.valuesTitle"
          :key="item"
          class="d-flex flex-column justify-space-around"
        >
          <v-row :class="mdAndUp ? 'mx-2 pa-0 col3' : 'mb-8 col3'">
            <v-col
              cols="3"
              class="d-flex align-center text-center linencolorPredValues"
            >
              <p class="descriptionPredValues">{{ item }}</p>
            </v-col>
            <v-col
              cols="5"
              class="ml-3 d-flex align-center pa-0 linencolorPredValues"
            >
              <div class="ml-3 mr-3">
                <p class="descriptionPredValues">
                  {{ predValuesData.valuesTexts[index] }}
                </p>
              </div>
            </v-col>
            <v-col cols="3" class="ml-3 linencolorPredValues">
              <h4 class="descriptionPredValues">
                {{ predValuesData.valuesCompetences[index] }}
              </h4>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { mapState } from "vuex";
import { computed } from "vue";
import { useDisplay } from "vuetify";

export default {
  name: "PredValuesBehavior",

  data: () => ({}),

  computed: {
    ...mapState(["predValuesData"]),
  },

  setup() {
    // Destructure only the keys we want to use
    const { mdAndUp } = useDisplay();
    const { xsAndUp } = useDisplay();

    const { name } = useDisplay();

    const principles_card_height = computed(() => {
      // name is reactive and
      // must use .value
      switch (name.value) {
        case "xs":
          return "auto";
        case "sm":
          return "auto";
        case "md":
          return (80 * ((92 * window.innerHeight) / 100)) / 100;
        case "lg":
          return (74 * ((92 * window.innerHeight) / 100)) / 100;
        case "xl":
          return (83 * ((92 * window.innerHeight) / 100)) / 100;
        case "xxl":
          return (83 * ((92 * window.innerHeight) / 100)) / 100;
      }

      return undefined;
    });

    // eslint-disable-next-line vue/no-dupe-keys
    return {
      principles_card_height,
      mdAndUp,
      xsAndUp,
    };
  },
};
</script>

<style>
.descriptionPredValues {
  color: #636363;
  font-size: 13px;
}
.description {
  color: #585858;
  font-size: 12px;
}
.bordertable {
  padding: 18px;
  color: #000000;
}
.borderupdown {
  border-top: 1px solid #34495e;
  border-bottom: 1px solid #34495e;
}
.borderblackPredValues {
  border-bottom: 1px solid #000000 !important;
}

.principles_card::-webkit-scrollbar {
  -webkit-appearance: none;
}

.principles_card::-webkit-scrollbar:vertical {
  width: 0px;
}

.principles_card::-webkit-scrollbar-button:increment,
.contenedor::-webkit-scrollbar-button {
  display: none;
}

.principles_card::-webkit-scrollbar:horizontal {
  height: 10px;
}

.principles_card::-webkit-scrollbar-thumb {
  background-color: #00c3c0;
  border-radius: 20px;
  border: 2px solid #f1f2f3;
}

.principles_card::-webkit-scrollbar-track {
  border-radius: 10px;
}
.cardwhitePValues {
  background-color: #ffffff;
  border-radius: 10px;
  border: 1px solid #efefef;

  margin-top: 1%;
  margin-left: 2%;
  margin-right: 2%;
}
.linencolorPredValues {
  border-bottom: 1px solid #85848466 !important;
  color: #898989;
}
</style>
