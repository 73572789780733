<template>
  <v-card
    elevation="0"
    :class="
      mdAndUp
        ? 'cardwhitefArea overflow-y-auto'
        : 'mt-1 text-center cardwhitefArea '
    "
    :height="principles_card_height"
    width="95%"
  >
    <v-row class="mx-0" justify="space-around">
      <div
        class="d-flex flex-column justify-content align-center"
        style="margin-left: 5%"
      >
        <h3 class="text-uppercase mt-5 mx-10 text-center" style="color: #34495e">
          Perfiles laborales de alto y bajo encaje
        </h3>
        <p class="subtitlep mx-10 text-center">
          Compara la mentalidad de la persona con las&nbsp;
          <span
            class="link"
            @click="parametrization_view_change_step_to_desktop(4)"
          >
            mentalidades parametrizadas
          </span>
        </p>
      </div>
      <v-col 
        cols="5" 
        class="d-flex justify-center align-center borderblackFAreas pb-2"
      >
        <h5 style="color: #585858;" class="descriptionFitA text-uppercase">
          Alto encaje <v-icon class="highEng">mdi-arrow-up</v-icon>
        </h5>
      </v-col>

      <v-col 
        cols="5"
        class="d-flex justify-center align-center borderblackFAreas pb-2"
      >
        <h5 style="color: #585858" class="descriptionFitA text-uppercase">
          Bajo encaje <v-icon class="lowEng">mdi-arrow-down</v-icon>
        </h5>
      </v-col>
    </v-row>

    <v-row :class="mdAndUp ? ' mx-7 ' : 'mx-3 mt-5'" style="height: 100%">
      <v-col class="d-flex flex-column">
        <v-row style="display: flex; justify-content: space-evenly">
          <v-col cols="5" class="d-flex pa-0">
            <!-- alto encaje -->
            <ul class="unorderList">
              <li
                v-for="(item, index) in topFiveOverSixty(candidateMindsetFit)"
                :key="index"
                class="descriptionfArea borderblackFAreas2 list"
              >
                <div style="flex-direction: row">

                  <div :style="{ fontWeight: getFontWeight(item.parameter_id) }">
                    {{ item.parameter_name }}
                  </div>

                  <div :style="{ fontWeight: getFontWeight(item.parameter_id) }">
                    {{ `${item.mindsetFit} %` }}
                  </div>
                </div>
              </li>
            </ul>
          </v-col>

          <!-- bajo encaje -->
          <v-col cols="5" class="d-flex pa-0 ml-13">
            <div>
              <ul class="unorderList">
                <li
                  v-for="(item, index) in bottomFiveBelowSixty(candidateMindsetFit)"
                  :key="index"
                  class="descriptionfArea borderblackFAreas2b list"
                >
                  <div style="flex-direction: row">
                    <div :style="{ fontWeight: getFontWeight(item.parameter_id) }">
                      {{ `${item.parameter_name}` }}
                    </div>
                    <div  :style="{ fontWeight: getFontWeight(item.parameter_id) }">
                      {{ `${item.mindsetFit} %` }}
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { computed } from "vue";
import { useDisplay } from "vuetify";
import { mapMutations, mapState } from "vuex";
import ParametersId from "@/globals/ParametersId";

export default {
  name: "FitAreasSection",

  props: {
    candidateMindsetFit: {
      type: Object,
    },
  },

  computed: {
    ...mapState([
      "cultureProfileData",
      "fitAreasData",
      "detailed_view_step",
    ]),
  },

  methods: {
    ...mapMutations([
      "parametrization_view_change_step_to_desktop",
      "detailed_view_step",
    ]),

    topFiveOverSixty(mindsetFitList){
      const overSixty = mindsetFitList.filter(item => item.mindsetFit > 60);
      const personalizedProfiles = overSixty.filter(item => !ParametersId.defaultParametersId.includes(item.parameter_id))
      const defaultProfiles = overSixty.filter(item => ParametersId.defaultParametersId.includes(item.parameter_id))
      return (personalizedProfiles.concat(defaultProfiles)).slice(0, 5)
    },
    bottomFiveBelowSixty(mindsetFitList){
      const bottomSixty = mindsetFitList.filter(item => item.mindsetFit < 60);
      const personalizedProfiles = bottomSixty.filter(item => !ParametersId.defaultParametersId.includes(item.parameter_id))
      const defaultProfiles = bottomSixty.filter(item => ParametersId.defaultParametersId.includes(item.parameter_id))
      return (personalizedProfiles.concat(defaultProfiles)).slice(-5)
    },
    getFontWeight(parameter_id) {
      return (parameter_id >= 91 && parameter_id <= 111) ? 'normal' : 'bold';
    }

  },

  setup() {
    // Destructure only the keys we want to use
    const { mdAndUp } = useDisplay();
    const { xsAndUp } = useDisplay();

    const { name } = useDisplay();

    const principles_card_height = computed(() => {
      // name is reactive and
      // must use .value
      switch (name.value) {
        case "xs":
          return "auto";
        case "sm":
          return "auto";
        case "md":
          return (80 * ((92 * window.innerHeight) / 100)) / 100;
        case "lg":
          return (79 * ((92 * window.innerHeight) / 100)) / 100;
        case "xl":
          return (60 * ((92 * window.innerHeight) / 100)) / 100;
        case "xxl":
          return (83 * ((92 * window.innerHeight) / 100)) / 100;
      }

      return undefined;
    });

    // eslint-disable-next-line vue/no-dupe-keys
    return {
      principles_card_height,
      mdAndUp,
      xsAndUp,
    };
  },
};
</script>

<style>
.borderblackFAreas {
  border-bottom: 1px solid #000000;
}
.borderblackFAreas2 {
  border-bottom: 3px solid #b1d095;
  color: #777777;
}
.borderblackFAreas2b {
  border-bottom: 3px solid #f9d786;
  color: #777777;
}
.descriptionfArea {
  color: #797979;
  font-size: 12px;
}
.descriptionFitA {
  color: #585858;
  font-size: 16px;
}

.col2 {
  border-left: 2px solid #00c3c0 !important;
}
.col1 {
  border-right: 2px solid #00c3c0 !important;
}

.principles_card::-webkit-scrollbar {
  -webkit-appearance: none;
}

.principles_card::-webkit-scrollbar:vertical {
  width: 10px;
}

.principles_card::-webkit-scrollbar-button:increment,
.contenedor::-webkit-scrollbar-button {
  display: none;
}

.principles_card::-webkit-scrollbar:horizontal {
  height: 10px;
}

.principles_card::-webkit-scrollbar-thumb {
  background-color: #00c3c0;
  border-radius: 20px;
  border: 2px solid #f1f2f3;
}

.principles_card::-webkit-scrollbar-track {
  border-radius: 10px;
}
.cardwhitefArea {
  background-color: #ffffff;
  border-radius: 10px;
  border: 1px solid #efefef;

  margin-top: 0%;
  margin-left: 1%;
}
.list {
  list-style-type: none;
  margin: 18px 0px 15px 0px;
}
.highEng {
  color: #b1d095;
  font-size: 25px;
}
.lowEng {
  color: #f9d786;
  font-size: 25px;
}
.subtitlep {
  color: #585858;

  font-size: 13px;
}
.link {
  color: #00c3c0;
  cursor: pointer;
}
.link:hover {
  color: #1c8b89;
}
.unorderList {
  width: 100%;
}
</style>
