<template>
  <v-form v-model="form">
    <v-row>
      <v-col cols="12">
        <v-text-field
          v-model="email"
          required
          density="compact"
          class="text-fields"
          type="email"
          bg-color="#BDF0EE"
          :placeholder="email_placeholder"
          :rules="emailRules"
          variant="flat"
          autocomplete="username"
          single-line
          hide-details
          @keydown.enter="ckeckFormToLogin"
        >
          <template v-slot:prepend-inner>
            <v-icon color="#00C3C0">mdi mdi-email</v-icon>
          </template>
        </v-text-field>
      </v-col>

      <v-col cols="12">
        <v-text-field
          v-model="password"
          required
          density="compact"
          class="text-fields text-grey-darken-2"
          :append-inner-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
          :type="show ? 'text' : 'password'"
          @click:append-inner="show = !show"
          bg-color="#BDF0EE"
          :placeholder="password_placeholder"
          autocomplete="current-password"
          variant="flat"
          single-line
          hide-details
          :rules="passwordRules"
          @keydown.enter="ckeckFormToLogin"
        >
          <template v-slot:prepend-inner>
            <v-icon color="#00C3C0">mdi mdi-lock</v-icon>
          </template>
        </v-text-field>
      </v-col>
      <v-col cols="12" class="text-right">
        <v-btn
          variant="text"
          class="text-decoration-underline x-small text-none"
          :ripple="false"
          style="color: #34495e"
          @click="ckeckFormToRecoverPassword"
          >{{ forgotten_password }}
          <LoginEmailValidationDialog :user_email="email" />
        </v-btn>
      </v-col>
    </v-row>

    <v-row :class="lgAndUp ? 'mt-16' : 'mt-8'">
      <v-col cols="12" :class="mdAndUp ? '' : 'mb-10'">
        <v-btn
          block
          rounded="lg"
          :disabled="!form"
          density="comfortable"
          size="large"
          class="font-weight-bold login_button"
          @keydown.enter="ckeckFormToLogin"
          @click="ckeckFormToLogin"
        >
          {{ login_btn_label }}
        </v-btn>
      </v-col>
    </v-row>
  </v-form>

  <RegistrationEmailValidationDialog :user_email="email" />

  <v-snackbar
    v-model="snackbar"
    :timeout="11000"
    variant="elevated"
    class="mb-5"
    location="bottom right"
    color="error"
    width="auto"
    transition="scroll-x-reverse-transition"
  >
    <h3 class="text-white">
      <v-icon color="white" class="mr-1" size="30">mdi mdi-check-circle</v-icon>
      {{ snack_message }}
    </h3>
  </v-snackbar>
  <v-snackbar
    v-model="snackbar2"
    :timeout="29500"
    variant="elevated"
    class="mb-5"
    location="bottom right"
    color="error"
    width="auto"
    transition="scroll-x-reverse-transition"
  >
    <h3 class="text-white">
      <v-icon color="white" class="mr-1" size="30">mdi mdi-check-circle</v-icon>
      {{ snack_message }}
      <br />
      <a
        href="https://meetings-eu1.hubspot.com/ltorres1"
        target="_blank"
        style="color: white"
      >
        Pulsa aquí para solicitar una reunión
      </a>
    </h3>
  </v-snackbar>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import { ROUTES } from "/src/router/names";
import LoginService from "@/services/LoginService";

import LoginEmailValidationDialog from "./LoginEmailValidationDialog.vue";
import RegistrationEmailValidationDialog from "./RegistrationEmailValidationDialog.vue";

export default {
  name: "CredentialsForm",
  data: () => ({
    // Form data
    form: false,

    //Show Password
    show: false,

    //Show Dialog
    login_email_validation_dialog: false,

    //Snackbar Notification
    snackbar: false,
    snackbar2: false,
    snack_message: "",

    //Text Field Model
    email: "",
    password: "",

    ROUTES: ROUTES,

    //Text Field Rules
    passwordRules: [
      (v) => !!v || "Contraseña requerida",
      (v) =>
        (v && v.length <= 20) ||
        "La contraseña no debe tener más de 20 caracteres",
    ],
    emailRules: [(v) => !!v || "Usuario requerido"],

    //Login Form TextField Placeholder
    email_placeholder: "",
    password_placeholder: "",

    forgotten_password: "",

    //LoginForm Action Button
    login_btn_label: "",
    end_trial_period: "",
    bad_credentials: "",
  }),

  computed: {
    ...mapState(["super_user"]),
  },

  components: {
    LoginEmailValidationDialog,
    RegistrationEmailValidationDialog,
  },

  methods: {
    ...mapMutations([
      "setTrue_login_email_validation_dialog",
      "setTrue_registration_email_validation_dialog",
      "setUser_info",
      "setUser_tokens",
      "dashboard_step_to_2",
    ]),

    set_texts() {
      let t = sessionStorage.getItem("texts");
      let texts = JSON.parse(t);

      this.email_placeholder = texts[7];
      this.password_placeholder = texts[9];
      this.login_btn_label = texts[0];
      this.forgotten_password = texts[12];
      this.end_trial_period = texts[101];
      this.bad_credentials = texts[102];
    },

    async loginUser() {
      let formData = new FormData();
      formData.append("consultorEmail", this.email);
      formData.append("password", this.password);

      try {
        const response = await LoginService.loginUser(formData);
        let data = response.data;
        if (data.status === "success") {
          this.setUser_info(data);
          this.goto_dashboard();
        }
      } catch (error) {
        if (error.response.status === 430) {
          this.snack_message = this.end_trial_period;
          this.snackbar2 = true;
        } else if (error.response.status === 420) {
          /*if (error.response.data.status === "inactivo") */
          this.setTrue_registration_email_validation_dialog();
        } else if (error.response.status === 401) {
          /* error.response.data.status === "error"  */
          this.snack_message = this.bad_credentials;
          this.snackbar = true;
          this.email = "";
          this.password = "";
        } else {
          console.log(error);
        }
      }
    },

    async sendPinToRecoverPassword() {
      try {
        const response = await LoginService.sendPinToRecoverPassword(
          this.email
        );
        var data = response.data;
        if (data.status == "200") {
          this.setTrue_login_email_validation_dialog();
        } else {
          this.snack_message = data.message;
          this.snackbar = true;
          this.email = "";
          this.password = "";
        }
      } catch (error) {
        console.log(error);
      }
    },

    goto_dashboard() {
      this.dashboard_step_to_2();
      this.$router.replace({ name: ROUTES.DASHBOARD });
    },

    validEmail(email) {
      var re =
        /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },

    validPassword(password) {
      var re =
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&]{8,15}/;
      return re.test(password);
    },

    ckeckFormToRecoverPassword() {
      if (this.email !== "") {
        if (!this.validEmail(this.email)) {
          this.snack_message = "El email debe ser válido";
          this.snackbar = true;
          this.email = "";
        } else {
          this.sendPinToRecoverPassword();
        }
      } else {
        this.snack_message = "Email Obligatorio";
        this.snackbar = true;
      }
    },

    ckeckFormToLogin() {
      if (!this.validEmail(this.email)) {
        this.snack_message = "El email debe ser válido";
        this.snackbar = true;
        this.email = "";
      } else {
        this.loginUser();
      }
    },
  },

  mounted() {
    this.set_texts();
  },
};
</script>

<style scoped>
.text-fields {
  font-style: italic;
  font-weight: bold;
  background-color: #bdf0ee;
}

.login_button {
  background-color: #34495e !important;
  color: white !important;
}

.text-fields :-webkit-autofill,
.text-fields :-webkit-autofill:hover,
.text-fields :-webkit-autofill:focus,
.text-fields :-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: #585858 !important;
}
</style>
