export default {
  contact_link:
    "https://web.culturalfitsolutions.com/contacto-plataforma-cultural-fit-2",
  test_link: "https://loving-germain.82-223-101-75.plesk.page/testcultura/",
  support_contact_link:
    "https://web.culturalfitsolutions.com/ticketing-plataforma-cultural-fit",
  report_link:
    "https://web.culturalfitsolutions.com/contacto-plataforma-cultural-fit-2",
  main_page_link: "https://culturalfitsolutions.com/",
  main_page_link22: "https://culturalfitsolutions.com/",
};
