<template>
  <v-sheet class="" color="transparent" width="100%">
    <!-- Static text and other buttons -->
    <!-- <v-row dense class="mt-3 d-flex justify-center">
      <v-col cols="10">
        <v-btn
          block
          rounded="lg"
          density="comfortable"
          size="large"
          class="font-weight-bold btn py-7 smallerTButton"
          @click="parametrization_view_change_step(2)"
        >
          {{ paraIDealMent }}
             <v-tooltip activator="parent" location="end"> Descripción de botón </v-tooltip>
        </v-btn>
      </v-col>
    </v-row> -->

    <!--Share Link Button-->
    <v-row
      dense
      :class="
        mdAndUp
          ? 'mt-5 d-flex justify-center'
          : 'mt-5 mx-3 d-flex justify-center'
      "
    >
      <v-col cols="12" md="10">
        <v-btn
          v-if="md"
          block
          rounded="lg"
          density="comfortable"
          size="large"
          class="font-weight-bold btn py-8 smallerTButton"
          @click="write_to_clipboard"
        >
          {{ link_mdbtn_line1 }} <br />
          {{ link_mdbtn_line2 }} <br />
          {{ link_mdbtn_line3 }}
          <v-tooltip activator="parent" location="end">{{
            tooltip_link
          }}</v-tooltip>
        </v-btn>
        <v-btn
          v-else
          block
          rounded="lg"
          density="comfortable"
          size="large"
          class="font-weight-bold btn py-7 smallerTButton"
          @click="write_to_clipboard"
        >
          {{ link_btn_line1 }} <br />
          {{ link_btn_line2 }}
          <v-tooltip activator="parent" location="end">{{
            tooltip_link
          }}</v-tooltip>
        </v-btn>
        <v-dialog
          v-model="dialog"
          :width="mdAndUp ? '50%' : '90%'"
          transition="dialog-top-transition"
        >
          <v-card
            class="d-flex justify-center text-center"
            color="#00C3C0"
            theme="dark"
          >
            <v-container>
              <!-- Dialog Title-->
              <h2 class="text-uppercase" style="color: white">
                <v-icon>mdi mdi-check-circle-outline</v-icon> {{ dialog_title }}
              </h2>
              <!--Dialog Subtitle 1-->
              <v-row dense class="d-flex justify-center text-center">
                <v-col cols="11" md="10">
                  <div>
                    <p class="text-subtitle-1 mt-3" style="color: white">
                      {{ dialog_subtitle }}
                    </p>
                  </div>
                </v-col>
              </v-row>
              <v-card-text>
                <a
                  :href="external_link"
                  target="_blank"
                  class="mt-5"
                  style="color: white"
                  >{{ external_link }}
                </a>
              </v-card-text>
            </v-container>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
    <v-row
      dense
      :class="
        mdAndUp
          ? 'd-flex justify-center text-justify'
          : 'd-flex justify-center text-center mx-3'
      "
    >
      <v-col cols="12" md="10">
        <P class="font-weight-bold" style="color: #585858; font-size: 14px"
          >{{ static_text }}
        </P>
      </v-col>
    </v-row>
    <!--Contact Button-->
    <v-row dense class="mt-5 d-flex justify-center hidden-sm-and-down">
      <v-col cols="10">
        <v-btn
          block
          rounded="lg"
          density="comfortable"
          size="large"
          class="font-weight-bold btn py-7 hidden-sm-and-down smallerTButton"
          :href="contact_link"
          target="_blank"
        >
          {{ contact_btn }}
        </v-btn>
      </v-col>
    </v-row>

    <!--Support Contact Button-->
    <v-row dense class="mt-3 d-flex justify-center hidden-sm-and-down">
      <v-col cols="10">
        <v-btn
          block
          rounded="lg"
          density="comfortable"
          size="large"
          class="font-weight-bold btn py-7 hidden-sm-and-down smallerTButton"
          :href="support_contact_link"
          target="_blank"
        >
          {{ support_contact_btn }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row dense class="mt-3 d-flex justify-center hidden-sm-and-down">
      <v-col cols="10">
        <v-btn
          block
          rounded="lg"
          density="comfortable"
          size="large"
          class="font-weight-bold btn py-9 hidden-sm-and-down"
          @click="showIntegrationsDialog"
        >
          Integraciones
        </v-btn>

        <!-- Nuevo Dialogo para el Botón Integraciones -->
        <v-dialog
          v-model="integrationsDialog"
          :width="mdAndUp ? '50%' : '90%'"
          transition="dialog-top-transition"
        >
          <v-card
            class="d-flex justify-center text-center"
            color="#00C3C0"
            theme="dark"
          >
            <v-container>
              <!-- Título del Dialogo -->
              <h2 class="text-uppercase" style="color: white">
                <v-icon>mdi mdi-connection</v-icon>
                {{ integrationsDialogTitle }}
              </h2>
              <!-- Subtítulo del Dialogo -->
              <v-row dense class="d-flex justify-center text-center">
                <v-col cols="11" md="10">
                  <div>
                    <p class="text-subtitle-1 mt-3" style="color: white">
                      {{ integrationsDialogSubtitle }}
                    </p>
                  </div>
                </v-col>
              </v-row>
              <v-card-text>
                <v-btn
                  block
                  rounded="lg"
                  density="comfortable"
                  size="large"
                  class="font-weight-bold btn py-7 TTButton"
                  @click="handleTeamTailorClick"
                >
                  TeamTailor
                </v-btn>
              </v-card-text>
            </v-container>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
import Links from "@/globals/Links";
import { useDisplay } from "vuetify";
import { mapState, mapMutations, mapActions } from "vuex";
import { ROUTES } from "@/router/names";

export default {
  name: "ProfilesEvaluationNavBar",
  data: () => ({
    dialog: false,
    integrationsDialog: false,
    integrationsDialogTitle: "Integraciones",
    integrationsDialogSubtitle: "Aquí puedes gestionar tus integraciones.",
    // Label
    static_text: "",
    link_btn_line1: "",
    link_btn_line2: "",
    link_mdbtn_line1: "",
    link_mdbtn_line2: "",
    link_mdbtn_line3: "",
    support_contact_btn: "",
    contact_btn: "",
    support_contact_link: "",
    contact_link: "",
    tooltip_link: "",
    dialog_title: "",
    dialog_subtitle: "",
    paraIDealMent: "",
  }),
  computed: {
    ...mapState(["external_link", "dashboard_step"]),
  },
  watch: {
    dashboard_step(newVal) {
      if (newVal === 4) {
        this.integrationsDialog = false;
      }
    },
  },
  methods: {
    ...mapMutations([
      "parametrization_view_change_step",
      "dashboard_step_to_4",
    ]),
    ...mapActions(["fetchIntegrationKey"]),
    showIntegrationsDialog() {
      this.integrationsDialog = true;
    },
    handleTeamTailorClick() {
      this.dashboard_step_to_4();
      this.fetchIntegrationKey();
    },
    async write_to_clipboard() {
      try {
        await navigator.clipboard.writeText(this.external_link);
      } catch (err) {
        console.error("Error al copiar al portapapeles:", err);
      }
      this.dialog = true;
    },
    goToParameterization() {
      this.$router.push(ROUTES.BOX_PARAMETERIZATION);
    },
    set_texts() {
      let t = sessionStorage.getItem("texts");
      let texts = JSON.parse(t);

      this.static_text = texts[65];
      this.link_btn_line1 = texts[66];
      this.link_btn_line2 = texts[67];
      this.link_mdbtn_line1 = texts[68];
      this.link_mdbtn_line2 = texts[69];
      this.link_mdbtn_line3 = "(" + texts[70] + ")";
      this.contact_btn = texts[45];
      this.support_contact_btn = texts[46];
      this.tooltip_link = texts[60];

      this.dialog_title = texts[63];
      this.dialog_subtitle = texts[92] + ":";

      this.contact_link = Links.contact_link;
      this.support_contact_link = Links.support_contact_link;
      this.paraIDealMent = texts[108];
    },
  },
  created() {
    this.set_texts();
  },
  setup() {
    const { mdAndUp } = useDisplay();
    const { md } = useDisplay();

    return { mdAndUp, md };
  },
};
</script>

<style scoped>
.btn {
  background-color: #00c3c0;
  color: white;
  font-size: 12px;
}
.smallerTButton {
  font-size: 10px !important;
}
.TTButton {
  font-size: 15px !important;
  background-color: white !important;
  color: #00c3c0 !important;
}
</style>
