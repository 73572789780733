<template>
  <v-sheet
    color="transparent"
    height="100%"
    width="100%"
    :class="mdAndUp ? '' : ''"
  >
    <!--Get Company Report Button-->
    <v-row dense class="mt-3 d-flex justify-center">
      <v-col cols="10" md="10">
        <v-btn
          block
          rounded="lg"
          density="comfortable"
          size="large"
          class="font-weight-bold btn py-7"
          :href="report_link"
          target="_blank"
        >
          {{ report_btn }}
          <v-tooltip activator="parent" location="end">
            {{ tooltip_report }}
          </v-tooltip>
        </v-btn>
      </v-col>
    </v-row>

    <!--Contact Button-->
    <v-row dense class="mt-3 d-flex justify-center">
      <v-col cols="10" md="10">
        <v-btn
          block
          rounded="lg"
          density="comfortable"
          size="large"
          class="font-weight-bold btn py-7"
          :href="contact_link"
          target="_blank"
        >
          {{ contact_btn }}
        </v-btn>
      </v-col>
    </v-row>

    <!--Support Contact Button-->
    <v-row dense class="mt-3 d-flex justify-center">
      <v-col cols="10" md="10">
        <v-btn
          block
          rounded="lg"
          density="comfortable"
          size="large"
          class="font-weight-bold btn py-7"
          :href="support_contact_link"
          target="_blank"
        >
          {{ support_contact_btn }}
        </v-btn>
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
import { mapState } from "vuex";
import Links from "@/globals/Links";

export default {
  name: "DashboardButtonsSectionMobile",

  data: () => ({
    //alert
    alert: false,

    dialog: false,

    snackbar: true,

    //Label
    report_btn: "Informe Detallado",
    contact_btn: "Contáctanos",
    support_contact_btn: "Contactar con soporte",
    support_contact_link: "",
    contact_link: "",
    report_link: "",
    tooltip_report: "",
  }),

  computed: {
    ...mapState(["summary", "internal_link"]),
  },

  methods: {
    async write_to_clipboard() {
      try {
        await navigator.clipboard.writeText(this.internal_link);
      } catch (err) {
        console.error("Error al copiar al portapapeles:", err);
      }
      this.dialog = true;
    },

    set_texts() {
      let t = sessionStorage.getItem("texts");
      let texts = JSON.parse(t);
      this.report_btn = texts[44];
      this.contact_btn = texts[45];
      this.support_contact_btn = texts[46];

      this.tooltip_report = texts[61];

      this.contact_link = Links.contact_link;
      this.support_contact_link = Links.support_contact_link;
      this.report_link = Links.report_link;
    },
  },

  created() {
    this.set_texts();
  },
};
</script>

<style scoped>
.btn {
  background-color: #34495e;
  color: white;
  font-size: 12px;
}
</style>
