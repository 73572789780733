<template>
  <v-container fluid class="containerBoxPara">
    <v-row class="d-flex justify-center mt-4">
      <v-btn icon @click="infoDialog = true">
        <v-icon class="icon-info">mdi-information</v-icon>
      </v-btn>
      <span class="instru"> -- Instrucciones</span>
    </v-row>

    <v-row>
      <v-col cols="12" md="1">
        <v-row>
          <v-row justify="center" class="my-1"
            ><strong>Prioridad</strong></v-row
          >
          <v-col
            v-for="n in 6"
            :key="`top-${n}`"
            :style="{ backgroundColor: colorScale[n - 1] }"
            class="drag-item2"
            cols="12"
          >
            {{ "Top " + n }}
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="2" class="mx-4">
        <v-row>
          <v-row justify="center" class="my-1"
            ><strong> Mentalidad </strong>
          </v-row>
          <v-col
            v-for="(header, index) in headers"
            :key="`header-${index}`"
            :draggable="true"
            @dragstart="handleDragStart(index)"
            @dragover.prevent
            @drop="handleDrop(index)"
            @dragenter.prevent="dragEnter(index)"
            class="drag-item"
            cols="12"
          >
            {{ header }}
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="12" md="8">
        <v-row>
          <v-row justify="center" class="my-1"
            ><strong> Definición </strong></v-row
          >
          <v-col
            v-for="(definition, index) in definitions"
            :key="`definition-${index}`"
            class="drag-item2 definitionItem"
            cols="12"
            v-html="definition"
          >
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-btn class="mt-3 mx-auto btnParaG" @click="go_back()">
        <v-icon class="iconBtnPG">mdi mdi-keyboard-return</v-icon>
        Volver <br />a panel
      </v-btn>
      <v-btn class="mt-3 mx-auto btn" @click="go_next()">Siguiente</v-btn>
    </v-row>

    <v-dialog v-model="infoDialog" max-width="600px">
      <v-card>
        <v-card-title class="headline">Instrucciones</v-card-title>
        <v-card-text>
          <v-row justify="center  ">
            Bienvenido a nuestra herramienta de parametrización de mentalidad
            ideal basada en valores personales del Cultural Fit Assessment
            Method (CFAM®). <br /><br />Este paso te ayudará a establecer el
            perfil ideal de mentalidad y valores de las personas que conformen
            tu equipo.<br />
          </v-row>
          <v-row justify="center ">
            <p>
              <strong>Paso 1:</strong> ¿Cuál es la mentalidad ideal de una
              persona de tu equipo? <br />
              <br />
              <strong>Haz click, Arrastra y Suelta</strong> <br />las siguientes
              6 mentalidades del CFAM® para ordenarlas de mayor a menor orden de
              prioridad.<br /><br />
              Una vez establecido el orden, pulsa en el botón<br />
              <strong>"Siguiente"</strong>.
            </p>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="infoDialog = false"
            >Cerrar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";

export default {
  data() {
    return {
      infoDialog: false,
      colorScale: [
        "#B1D095",
        "#CADFB8",
        "#E7F7F6",
        "#FFFC8B",
        "#F9D786",
        "#EF857D",
      ],
      originalHeaders: [
        "People",
        "Innovation",
        "Goals",
        "Norms",
        "Digital",
        "ESG",
      ],
      headers: [],
      definitions: [
        "Mentalidad enfocada en el bienestar y el desarrollo de las personas, el mantenimiento de un clima laboral positivo y unas buenas relaciones de trabajo.<br/> Ello la lleva a que la cohesión y la satisfacción propia y de los demás primen sobre otros objetivos.",
        "Mentalidad dinámica, creativa, autónoma, orientada a la innovación, y que presente tolerancia al riesgo y atrevimiento a la hora de actuar. <br/>  Promueve la generación de ideas innovadoras, el cambio positivo y la disposición para tomar riesgos.",
        "Mentalidad caracterizada por una orientación clara hacia los resultados, la excelencia y competitidad. Resuelve problemas de manera pragmática con ambición y habilidades de liderazgo. Enfoque en el logro de metas y objetivos, impulsando el éxito y la eficacia en el trabajo.",
        "Mentalidad orientada hacia el cumplimiento de las normas y la burocracia, enfatizando la seguridad, la predictibilidad, el orden y el control. <br/> Especial apego a la existencia de normas estrictas, procedimientos, manuales, y por mantener una noción muy estricta de la jerarquía.",
        "Mentalidad de gran apertura al cambio, analítica y que enfatiza la adaptación al cliente mediante el aprendizaje continuo y la tolerancia al fallo.<br/> Ello la lleva a fomentar y valorar especialmente la cooperación y disposición tanto propia como de sus colaboradores para compartir conocimientos.",
        "Mentalidad caracterizada por un alto compromiso para con la responsabilidad social y la sostenibilidad.<br/> Ello la conduce a esforzarse para lograr unos objetivos sociales y a generar un impacto positivo en el medio ambiente y la sociedad.",
      ],
      draggingIndex: -1,
    };
  },
  computed: {
    ...mapState(["detailed_view_step", "orderedHeaders"]),
  },
  methods: {
    ...mapMutations(["setOrderedHeaders", "setCurrentStep"]),
    ...mapActions(["resetHeaders"]),
    updateHeaders() {
      this.setOrderedHeaders(this.headers);
    },
    go_next() {
      this.updateHeaders();
      this.setCurrentStep(3); // Cambia la vista
    },
    go_back() {
      this.setCurrentStep(4); // Cambia la vista
    },
    handleDragStart(index) {
      this.draggingIndex = index;
    },
    handleDrop(index) {
      const draggedHeader = this.headers.splice(this.draggingIndex, 1)[0];
      this.headers.splice(index, 0, draggedHeader);

      const draggedDefinition = this.definitions.splice(
        this.draggingIndex,
        1
      )[0];
      this.definitions.splice(index, 0, draggedDefinition);

      this.draggingIndex = -1;
    },
    dragEnter(index) {
      if (this.draggingIndex !== index) {
        const headerToMove = this.headers.splice(this.draggingIndex, 1)[0];
        this.headers.splice(index, 0, headerToMove);

        const definitionToMove = this.definitions.splice(
          this.draggingIndex,
          1
        )[0];
        this.definitions.splice(index, 0, definitionToMove);

        this.draggingIndex = index;
      }
    },
    resetHeadersLocal() {
      this.headers = [...this.originalHeaders];
    },
  },
  watch: {
    detailed_view_step(newStep) {
      if (newStep === 2) {
        this.resetHeadersLocal();
      }
    },
  },
  created() {
    this.resetHeadersLocal();
  },
};
</script>

<style scoped>
.drag-item {
  cursor: grab;
  border: solid 1px #ccc;

  height: 53px;
  text-align: center;
  justify-content: center;

  margin-bottom: 1px;
  border-radius: 5px;
}
.drag-item2 {
  border: solid 1px #ccc;
  height: 53px;
  text-align: center;
  justify-content: center;

  margin-bottom: 1px;
  border-radius: 5px;
}
.drag-item3 {
  border: solid 1px #ccc;
  font-size: 14px;
  text-align: center;
  justify-content: center;

  margin-bottom: 1px;
  border-radius: 5px;
}

.drag-item:hover {
  background-color: #f0f0f0;
}
.drag-item2:hover {
  background-color: #f0f0f0 !important;
}
.containerBoxPara {
  width: 90%;
}
.definitionItem {
  font-size: 12px;
}
.step1Text {
  font-size: 16px;
}
.icon-info {
  font-size: 40px;
}
.instru {
  font-size: 14px;
  margin-top: 10px;
}
</style>
