import API from "./API";

export default {
    loginUser(formData){
        let endpoint = 'auth/login';
        return API().post(endpoint, formData)
    },

    getUserToken(email){
        let endpoint = 'auth/pry/' + email;
        return API().get(endpoint)
    },

    sendPinToRecoverPassword (email){
        let endpoint = 'pin/' + email;
        return API().get(endpoint)
    },

    registerUser(formData){
        let endpoint = 'auth/register';
        return API().post(endpoint, formData)
    },

    checkPin (formData){
        let endpoint = 'pin';
        return API().post(endpoint,  formData)
    }
}