export default {
    spanish:[
        "People",
        "Innovation",
        "Goals",
        "Norms",
        "Digital",
        "ESG"
    ],
    
    english:[],
}