import API from "./API";

export default {
    getAllTags(projectToken){
        let endpoint = 'tags/' + projectToken;
        return API().get(endpoint)
    },

    newTag(formData){
        let endpoint = "tags";
        return API().post(endpoint, formData)
    },

    deleteTag(formData){
        let endpoint = "tags/del";
        return API().post(endpoint, formData)
    },

    editTag(formData){
        let endpoint = "tags";
        return API().put(endpoint, formData)
    },

    //Candidate
    setTagToCandidate(formData){
        let endpoint = "tags2cand";
        return API().post(endpoint, formData)
    },

    updateCandidateTags(candToken){
        let endpoint = "tags2cand/" + candToken;
        return API().get(endpoint)
    },

    deleteCandidateTag(formData){
        let endpoint = "tags2cand/del";
        return API().post(endpoint, formData)
    }



   
    
}
