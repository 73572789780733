<template>
  <v-container fluid class="scrollable-content" style="max-height: 100vh">
    <v-row class="mt-1" justify="space-around" style="margin: 0">
      <v-col class="pa-1" md="6" style="text-align: start">
        <div class="d-block" style="font-size: 1.17rem; font-weight: bold">
          Predominancia de Valores
        </div>
        <div class="d-block" style="font-size: 0.75rem">
          Puedes modificarlos pinchando sobre cualquier valor y arrastrándolo
          hasta la posición que mejor se ajuste.
        </div>
      </v-col>

      <v-row class="nameParam" style="flex: none">
        <v-text-field
          v-model="IdealName"
          label="Modifica aquí el nombre de la parametrización ideal si fuera necesario"
          type="input"
          required
          variant="outlined"
          clearable
          :error="nameError"
          :error-messages="nameErrorMessage"
        ></v-text-field>
      </v-row>
    </v-row>

    <v-row class="mt-n3">
      <v-col cols="12">
        <v-row>
          <v-col
            cols="12"
            md="2"
            v-for="(block, index) in orderedBlocks"
            :key="`block-${index}`"
          >
            <v-card>
              <v-card-text
                class="headerTop"
                :style="{ backgroundColor: colorScale[index] }"
              >
                {{ block.header }}
              </v-card-text>
              <div
                class="drag-area"
                :class="{
                  'dark-hover': recentlySwapped.includes(
                    `item-${idx}-${block.header}`
                  ),
                }"
                v-for="(item, idx) in block.items"
                :key="`item-${idx}-${index}`"
                :draggable="true"
                @dragstart="handleDragStart($event, item, block, idx)"
                @dragover.prevent="handleDragOver"
                @drop="handleDrop($event, idx, block)"
                @dragend="handleDragEnd"
              >
                <div>{{ Object.keys(item)[0] }}</div>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row justify="center" style="height: fit-content">
      <v-col md="1" style="height: fit-content">
        <v-btn class="mt-8 btnParaMo" @click="handleGoBack">
          <v-icon class="iconBtnPG">mdi mdi-keyboard-return</v-icon>
          Volver <br />a panel
        </v-btn>
      </v-col>
      <v-col cols="12" lg="4" style="height: fit-content">
        <div style="height: fit-content; width: fit-content">
          <h3>Gráfica de mentalidad ideal</h3>
          <apexchart
            type="radar"
            :height="chart_height"
            :options="chartOptions"
            :series="series"
          ></apexchart>
        </div>
      </v-col>

      <v-col cols="12" lg="3" style="height: fit-content">
        <div class="table-container mt-2">
          <h3>Predominancia de Mentalidades</h3>
          <div
            class="table-row"
            v-for="(item, index) in sortedTotalScores2"
            :key="`score-row-${index}`"
          >
            <div class="title-cell">
              {{ item.category.replace("Total", "") }}
            </div>
            <div class="number-cell">Top {{ index + 1 }}</div>
          </div>
        </div>
      </v-col>

      <v-col cols="12" lg="2" style="height: fit-content">
        <v-btn class="mt-8 btn" @click="showConfirmDialog"> Grabar </v-btn>
      </v-col>
    </v-row>
    <v-dialog v-model="confirmDialogVisible" persistent max-width="500px">
      <v-card>
        <v-card-title>Confirmación</v-card-title>
        <v-card-text>
          ¿Estás seguro de que deseas modificar esta parametrización de
          mentalidad ideal en base a valores?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="confirmParametrization"
            >Confirmar</v-btn
          >
          <v-btn color="red darken-1" text @click="cancelParametrization"
            >Cancelar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row dense class="mt-3 d-flex justify-center">
      <v-col cols="10" md="10">
        <v-dialog
          v-model="dialogVisible"
          :width="mdAndUp ? '50%' : '70%'"
          transition="dialog-top-transition"
        >
          <v-card
            class="d-flex justify-center text-center"
            color="#00C3C0"
            theme="dark"
          >
            <v-btn
              icon
              class="close-dialog"
              @click="dialogVisible = false"
              style="
                position: absolute;
                top: 2px;
                right: 2px;
                background-color: #506274;
              "
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>

            <v-container>
              <h2 class="text-uppercase" style="color: white">
                <v-icon>mdi mdi-check-circle-outline</v-icon> Enhorabuena
              </h2>
              <v-row dense class="d-flex justify-center text-center">
                <v-col cols="11" md="10">
                  <div>
                    <p class="text-subtitle-1 mt-3" style="color: white">
                      Ya has modificado la mentalidad ideal en base a
                      valores.<br />
                      Puedes ver el nivel de encaje de las personas con este
                      perfil.
                    </p>
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ProfileEvaluationService from "@/services/ProfileEvaluationService"; // Importa tu servicio
import { computed } from "vue";
import { useDisplay } from "vuetify";
import { mapMutations, mapState } from "vuex";

export default {
  data() {
    return {
      IdealName: "",
      dialogVisible: false,
      confirmDialogVisible: false,
      nameError: false,
      nameErrorMessage: "",
      items: [
        { name: "Cercanía", order: 1 },
        { name: "Generosidad", order: 7 },
        { name: "Trabajo en equipo", order: 13 },
        { name: "Lealtad", order: 19 },
        { name: "Confianza", order: 25 },
        { name: "Compromiso", order: 31 },
        { name: "Dinamismo", order: 2 },
        { name: "Creatividad", order: 8 },
        { name: "Autonomía", order: 14 },
        { name: "Innovación", order: 20 },
        { name: "Tolerancia al riesgo", order: 26 },
        { name: "Atrevimiento", order: 32 },
        { name: "Orientación a resultados", order: 3 },
        { name: "Excelencia", order: 9 },
        { name: "Competitividad", order: 15 },
        { name: "Pragmatismo", order: 21 },
        { name: "Ambición", order: 27 },
        { name: "Liderazgo", order: 33 },
        { name: "Formalidad", order: 4 },
        { name: "Coordinación", order: 10 },
        { name: "Estabilidad", order: 16 },
        { name: "Responsabilidad", order: 22 },
        { name: "Perseverancia", order: 28 },
        { name: "Eficiencia", order: 34 },
        { name: "Apertura al cambio", order: 5 },
        { name: "Enfoque analítico", order: 11 },
        { name: "Cooperación", order: 17 },
        { name: "Tolerancia al fallo", order: 23 },
        { name: "Orientación al aprendizaje", order: 29 },
        { name: "Empatía", order: 35 },
        { name: "Bienestar", order: 6 },
        { name: "Integridad", order: 12 },
        { name: "Transparencia", order: 18 },
        { name: "Respeto", order: 24 },
        { name: "Diversidad", order: 30 },
        { name: "Sostenibilidad", order: 36 },
      ],
      colorScale: [
        "#B1D095",
        "#CADFB8",
        "#E7F7F6",
        "#FFFC8B",
        "#F9D786",
        "#EF857D",
      ],
      draggingItem: null,
      draggingIndex: -1,
      draggingBlock: null,
      recentlySwapped: [],
      series: [
        {
          name: "Predominance Values",
          data: [],
        },
      ],
      chartOptions: {
        chart: {
          type: "radar",
          toolbar: { show: false },
        },

        xaxis: {
          categories: [
            "People",
            "Innovation",
            "Goals",
            "Norms",
            "Digital",
            "ESG",
          ],
          labels: {
            show: true,
            style: {
              colors: [
                "#34495e",
                "#34495e",
                "#34495e",
                "#34495e",
                "#34495e",
                "#34495e",
              ],
              fontSize: "11px",
              fontFamily: "Arial",
            },
          },
        },
        legend: {
          show: "true",
          position: "top",
        },
        yaxis: { show: false },
        stroke: {
          width: 1,
          colors: ["#34495e"],
        },
        markers: { size: 0 },
        fill: { colors: ["#34495e"] },
      },
      totalScores: {
        TotalPeople: 0,
        TotalInnovation: 0,
        TotalGoals: 0,
        TotalNorms: 0,
        TotalDigital: 0,
        TotalESG: 0,
      },
      blocks: [],
      initialBlocks: [],
      top1: [],
      top2: [],
      top3: [],
      top4: [],
      top5: [],
      top6: [],
    };
  },
  computed: {
    ...mapState(["selectedParametrization", "user_info"]),
    orderedBlocks() {
      return this.blocks;
    },
    sortedTotalScores2() {
      let scoresArray = Object.entries(this.totalScores).map(
        ([key, score]) => ({
          category: key,
          score: score,
        })
      );
      scoresArray.sort((a, b) => b.score - a.score);
      return scoresArray;
    },
  },
  methods: {
    ...mapMutations(["setCurrentStep"]),
    showConfirmDialog() {
      if (this.IdealName.startsWith("*")) {
        this.nameError = true;
        this.nameErrorMessage = "El nombre no puede comenzar con *";
        return;
      }
      this.nameError = false;
      this.nameErrorMessage = "";
      this.confirmDialogVisible = true;
    },

    confirmParametrization() {
      this.updateParametrization();
      this.confirmDialogVisible = false;
    },

    cancelParametrization() {
      this.confirmDialogVisible = false;
    },
    handleGoBack() {
      this.resetBlocks();
      this.setCurrentStep(4);
    },
    handleDragStart(event, item, block, itemIndex) {
      this.draggingItem = item;
      this.draggingIndex = itemIndex;
      this.draggingBlock = block;
      event.dataTransfer.effectAllowed = "move";
    },
    handleDragOver(event) {
      event.preventDefault();
      event.dataTransfer.dropEffect = "move";
    },
    handleDrop(event, targetIndex, targetBlock) {
      if (!this.draggingItem) return;

      const draggingItem = this.draggingItem;
      const originalBlock = this.draggingBlock;
      const originalIndex = this.draggingIndex;

      const targetItem = targetBlock.items[targetIndex];
      targetBlock.items.splice(targetIndex, 1, draggingItem);
      originalBlock.items.splice(originalIndex, 1, targetItem);

      const targetBlockIndex = this.blocks.indexOf(targetBlock);
      const originalBlockIndex = this.blocks.indexOf(originalBlock);

      draggingItem.value = 6 - targetBlockIndex;
      targetItem.value = 6 - originalBlockIndex;

      this.recentlySwapped.push(`item-${targetIndex}-${targetBlock.header}`);
      this.recentlySwapped.push(
        `item-${originalIndex}-${originalBlock.header}`
      );

      setTimeout(() => {
        this.recentlySwapped = this.recentlySwapped.filter(
          (item) =>
            item !== `item-${targetIndex}-${targetBlock.header}` &&
            item !== `item-${originalIndex}-${originalBlock.header}`
        );
      }, 1500);

      this.calculateScores();
      this.draggingItem = null;
      this.draggingIndex = -1;
      this.draggingBlock = null;
    },
    handleDragEnd() {
      this.draggingItem = null;
      this.draggingIndex = -1;
      this.draggingBlock = null;
    },
    calculateScores() {
      const itemGroups = {
        People: [
          "Cercanía",
          "Generosidad",
          "Trabajo en equipo",
          "Lealtad",
          "Confianza",
          "Compromiso",
        ],
        Innovation: [
          "Dinamismo",
          "Creatividad",
          "Autonomía",
          "Innovación",
          "Tolerancia al riesgo",
          "Atrevimiento",
        ],
        Goals: [
          "Orientación a resultados",
          "Excelencia",
          "Competitividad",
          "Pragmatismo",
          "Ambición",
          "Liderazgo",
        ],
        Norms: [
          "Formalidad",
          "Coordinación",
          "Estabilidad",
          "Responsabilidad",
          "Perseverancia",
          "Eficiencia",
        ],
        Digital: [
          "Apertura al cambio",
          "Enfoque analítico",
          "Cooperación",
          "Tolerancia al fallo",
          "Orientación al aprendizaje",
          "Empatía",
        ],
        ESG: [
          "Bienestar",
          "Integridad",
          "Transparencia",
          "Respeto",
          "Diversidad",
          "Sostenibilidad",
        ],
      };

      let newSummatoryValues = {
        People: 0,
        Innovation: 0,
        Goals: 0,
        Norms: 0,
        Digital: 0,
        ESG: 0,
      };

      this.blocks.forEach((block, blockIndex) => {
        const blockScore = 6 - blockIndex;
        block.items.forEach((item) => {
          const itemName = Object.keys(item)[0];
          if (itemGroups.People.includes(itemName)) {
            newSummatoryValues.People += blockScore;
          } else if (itemGroups.Innovation.includes(itemName)) {
            newSummatoryValues.Innovation += blockScore;
          } else if (itemGroups.Goals.includes(itemName)) {
            newSummatoryValues.Goals += blockScore;
          } else if (itemGroups.Norms.includes(itemName)) {
            newSummatoryValues.Norms += blockScore;
          } else if (itemGroups.Digital.includes(itemName)) {
            newSummatoryValues.Digital += blockScore;
          } else if (itemGroups.ESG.includes(itemName)) {
            newSummatoryValues.ESG += blockScore;
          }
        });
      });

      this.series[0].data = [
        newSummatoryValues.People,
        newSummatoryValues.Innovation,
        newSummatoryValues.Goals,
        newSummatoryValues.Norms,
        newSummatoryValues.Digital,
        newSummatoryValues.ESG,
      ];

      this.totalScores.TotalPeople = newSummatoryValues.People;
      this.totalScores.TotalInnovation = newSummatoryValues.Innovation;
      this.totalScores.TotalGoals = newSummatoryValues.Goals;
      this.totalScores.TotalNorms = newSummatoryValues.Norms;
      this.totalScores.TotalDigital = newSummatoryValues.Digital;
      this.totalScores.TotalESG = newSummatoryValues.ESG;
    },
    initializeBlocks() {
      const data = this.selectedParametrization.data.split("").map(Number);
      if (data.length !== 36) {
        console.error(
          "La longitud de selectedParametrization.data no es correcta."
        );
        return;
      }

      this.top1 = [];
      this.top2 = [];
      this.top3 = [];
      this.top4 = [];
      this.top5 = [];
      this.top6 = [];

      this.items.forEach((item) => {
        const itemIndex = item.order - 1; // Get the 0-based index
        const score = data[itemIndex]; // Use this index to get the score

        switch (score) {
          case 6:
            this.top1.push({ [item.name]: item.order, value: score });
            break;
          case 5:
            this.top2.push({ [item.name]: item.order, value: score });
            break;
          case 4:
            this.top3.push({ [item.name]: item.order, value: score });
            break;
          case 3:
            this.top4.push({ [item.name]: item.order, value: score });
            break;
          case 2:
            this.top5.push({ [item.name]: item.order, value: score });
            break;
          case 1:
            this.top6.push({ [item.name]: item.order, value: score });
            break;
          default:
            console.error(`Invalid score ${score} for item ${item.name}`);
        }
      });

      this.blocks = [
        { header: "Top 1", items: this.top1 },
        { header: "Top 2", items: this.top2 },
        { header: "Top 3", items: this.top3 },
        { header: "Top 4", items: this.top4 },
        { header: "Top 5", items: this.top5 },
        { header: "Top 6", items: this.top6 },
      ];

      this.initialBlocks = JSON.parse(JSON.stringify(this.blocks)); // Store initial state
      this.calculateScores(); // Initial calculation of scores

      // Prellenar el campo de texto con el nombre actual de la parametrización
      this.IdealName = this.selectedParametrization.name.startsWith("*")
        ? ""
        : this.selectedParametrization.name;
    },
    logUpdatedScores() {
      const updatedData = Array(36).fill(0); // Create an array of 36 zeros

      // Iterate through blocks and items to update scores
      this.blocks.forEach((block) => {
        block.items.forEach((item) => {
          const itemName = Object.keys(item)[0];
          const itemOrder = this.items.find((i) => i.name === itemName).order;
          const itemIndex = itemOrder - 1; // Get the 0-based index
          updatedData[itemIndex] = item.value;
        });
      });

      const ScoreToPut = updatedData.join("");
      console.log(ScoreToPut);
    },
    updateParametrization() {
      const updatedData = Array(36).fill(0);

      this.blocks.forEach((block) => {
        block.items.forEach((item) => {
          const itemName = Object.keys(item)[0];
          const itemOrder = this.items.find((i) => i.name === itemName).order;
          const itemIndex = itemOrder - 1;
          updatedData[itemIndex] = item.value;
        });
      });

      const dataToPut = updatedData.join("");
      const id = this.selectedParametrization.id;
      const name = this.IdealName;
      const data = dataToPut;

      // Check if the name was changed from an asterisk-prefixed one
      if (
        this.selectedParametrization.name.startsWith("*") &&
        !name.startsWith("*")
      ) {
        // Post new parametrization
        ProfileEvaluationService.postParametrizeCandidate(
          name,
          data,
          this.user_info.tokens.tokenPE
        )
          .then((response) => {
            console.log("Parametrización creada con éxito:", response);
            if (response.data.code === 200) {
              this.resetBlocks(); // Restablece los items a su estado inicial
              this.setCurrentStep(4); // Cambia la vista o realiza la acción que necesites
            }
          })
          .catch((error) => {
            console.error("Error al crear la parametrización:", error);
          });
      } else {
        // Update existing parametrization
        ProfileEvaluationService.UpdateParametrizeCandidate(name, data, id)
          .then((response) => {
            console.log("Parametrización actualizada con éxito:", response);
            if (response.data.code === 200) {
              this.resetBlocks(); // Restablece los items a su estado inicial
              this.setCurrentStep(4); // Cambia la vista o realiza la acción que necesites
            }
          })
          .catch((error) => {
            console.error("Error al actualizar la parametrización:", error);
          });
      }
    },
    resetBlocks() {
      this.blocks = JSON.parse(JSON.stringify(this.initialBlocks));
      this.calculateScores();
    },
  },
  watch: {
    selectedParametrization: {
      handler() {
        this.initializeBlocks();
      },
      deep: true,
    },
  },
  created() {
    this.initializeBlocks();
  },
  setup() {
    const { name } = useDisplay();
    const chart_height = computed(() => {
      switch (name.value) {
        case "xs":
          return "200%";
        case "sm":
          return "200%";
        case "md":
          return (90 * ((45 * ((92 * window.innerHeight) / 100)) / 100)) / 100;
        case "lg":
          return (90 * ((45 * ((92 * window.innerHeight) / 100)) / 100)) / 100;
        case "xl":
          return (100 * ((45 * ((92 * window.innerHeight) / 100)) / 100)) / 100;
        case "xxl":
          return (100 * ((45 * ((92 * window.innerHeight) / 100)) / 100)) / 100;
      }
      return undefined;
    });
    return { chart_height };
  },
};
</script>

<style>
.drag-area {
  min-height: 1.025rem;
  width: 100%;
  background-color: #ffffff;
  cursor: grab;
  border-top: 1px solid #f0f0f0;
  padding: 0.345rem;
  font-size: 1rem;
}
@media (max-width: 1468px) {
  .drag-area {
    font-size: 0.775rem;
  }
}

@media (max-width: 768px) {
  .drag-area {
    font-size: 0.875rem;
  }
}

@media (max-width: 480px) {
  .drag-area {
    font-size: 0.75rem;
  }
}

@media (max-width: 320px) {
  .drag-area {
    font-size: 0.65rem;
  }
}

.v-col {
  border: 1px dashed transparent;
}

.v-col.drag-over {
  border-color: #00c3c0;
}

.v-container {
  width: 100%;
  text-align: center;
}

.headerTop {
  width: 100%;
}

.flex-container {
  display: flex;
  flex-wrap: wrap;
  gap: 0;
  font-size: 0.5rem;
}

.flex-item {
  flex: 1 1 calc(100% / 6 - 10px);
  min-width: 9.375rem;
  max-width: calc(100% / 6 - 10px);
  font-size: 0.625rem;
}

.circle-cell,
.number-cell {
  text-align: center;
}

.title-cell {
  flex-grow: 1;
  text-align: left;
}

.v-icon.small {
  font-size: 1rem;
}

.dark-hover {
  transition: background-color 0.3s ease;
  background-color: #555 !important;
  color: white;
}

.borderInstruc {
  border-bottom: #00c3c0 3px solid;
  border-radius: 8px;
  margin-left: 9px;
}
.close-dialog {
  position: absolute;
  top: 2px;
  right: 2px;
  z-index: 10;
}

.close-dialog .v-icon {
  color: white;
}
.nameParam {
  width: 30%;
  margin-top: 10px;
  margin: auto;
}
.nameParam input {
  font-size: 1.5em;
}
.btnParaMo {
  background-color: #34495e;
  color: white !important;
  font-size: 11px;
  padding: 10px;
  justify-content: center;
  text-align: center;

  min-height: 50px;
}
.scrollable-content {
  overflow-y: auto;
}
</style>
