<script setup>
import { defineProps } from 'vue';

let chartOptions = {
  chart: {
    height: 350,
    type: "radar",
    toolbar: {
      show: false,
    },
  },
  xaxis: {
    categories: ['People', 'Innovation', 'Goals', 'Norms', 'Digital', 'ESG'],
    labels: {
      show: true,
      style: {
        colors: [
          "#34495e",
          "#34495e",
          "#34495e",
          "#34495e",
          "#34495e",
          "#34495e",
        ],
        fontSize: "11px",
        fontFamily: "Arial",
      },
    },
  },

  yaxis: {
    show: false,
  },
  legend: {
    show: true,
    offsetY: -24,
    labels: {
      colors: "#34495e",
    },
    fontWeight: 600,
    fontSize: "9px",
    markers: {
      width: 12,
      height: 12,
      strokeWidth: 0,
      strokeColor: "#fff",
      fillColors: props.colors,
      radius: 0,
      onClick: undefined,
    },
  },
  stroke: {
    width: 1,
    colors: props.colors,
  },
  markers: {
    size: 0,
  },
  fill: {
    colors: props.colors,
  },
};

const props = defineProps({
  series: Object,
  height: String,
  width: String,
  colors: {
    type: Array,
    default: () => ["#34495e","#00C3C0"],
  }
})
</script>

<template>
  <apexchart type="radar" :width="width" :height="height" :options="chartOptions" :series="series"></apexchart>
</template>

<style scoped></style>