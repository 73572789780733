export default {
  es: [
    "Tipos de informe de candidato", //0
    "Informe de Valores de", //1
    "Y ajuste cultural con", //2
    "Todos los derechos reservados. El Cultural Fit Assessment Method (CFAM©) es propiedad de Cultural Fit Solutions S.L. Queda prohibida la reproducción total o parcial del material protegido por estos derechos de propiedad intelectual, su uso en cualquier forma, o por cualquier medio, ya sea electrónico o mecánico, incluyendo fotocopiado, grabación, transmisión o cualquier sistema de almacenamiento y recuperación de información, sin el permiso por escrito de Cultural Fit Solutions S.L.", //3
    "El Modelo de 6 culturas y mentalidades del CFAM®", //4
    " El método <strong>CFAM®</strong> es una <strong> metodología científica para evaluar valores predominantes </strong>en personas y empresas. Utiliza una escala validada científicamente para identificar la mentalidad de las personas y compararlos con la cultura de la empresa. Ayuda a predecir qué candidatos tienen el mejor ajuste con el puesto y/o la organización, <strong>permitiendo una selección más objetiva y acertada</strong>.", //5
    "CFAM® <strong>evalúa 36 valores universales y los agrupa en 6 arquetipos</strong>, que nos permiten comprender en profundidad cuál es la mentalidad de las personas y predecir sus comportamientos, competencias, así como su encaje en el puesto y/o la empresa.", //6
    "El método CFAM® fue desarrollado por investigadores de la Universidad de Sevilla<sup>1</sup> y ha sido <strong>validado por diversas instituciones de alto prestigio internacional</strong>.", //7
    "<sup>1</sup>: Prof. Dr. Antonio Luis Leal Rodríguez , Prof. Dr. Carlos Sanchís Pedregosa , Prof. Antonio M. Moreno Moreno miembros del Grupo de Investigación SEJ573 <strong>«Cultura Organizativa, Capacidades y Conocimiento para la Creación de Valor (CAPVALUE)»,.</strong>", //8
    "Resumen ejecutivo", //9
    "Mentalidad de ", //10
    "Mentalidad Predominante 1:", //11
    "Valores predominantes", //12
    "Mentalidad Predominante 2:", //13
    "Encaje de", //14
    "Encaje Mentalidad <br /><strong>Persona-Mentalidad IDEAL</strong>", //15
    "Encaje Mentalidad <br /><strong>Persona-Cultura DESEADA</strong>", //16
    "El CFAM® se <strong>basa en el Modelo de Valores en Competencia</strong>, permitiendo <strong>identificar los valores predominantes</strong> en la persona. Estos valores forman la mentalidad de la persona y ayudan a predecir cuáles serán sus comportamientos, competencias y áreas funcionales en las que se sentirá más cómoda y/o será más eficiente, así como aquellas en las que no. El siguiente <strong> gráfico representa el perfil de mentalidad de la persona basado en sus respuestas al assessment CFAM®</strong>.", //17
    "La siguiente tabla revela el <strong> grado de afinidad del individuo con un arquetipo en particular</strong>, lo cual nos permite comprender de manera clara y concisa las <strong>mentalidades predominantes en la persona</strong>. Esto es fundamental para determinar cómo estas pueden influir en su estilo de trabajo y sus interacciones en el entorno laboral.", //18
    "Mentalidades predominantes:", //19
    "La identificación de estás mentalidades predominantes permiten <strong>identificar los roles donde la persona desarrollará todo su potencial</strong> y aquellas en las que le será más difícil desarrollarse.", //20
    "Roles de alto encaje", //21
    "Roles de bajo encaje", //22
    "<strong>CFAM®</strong> también identifica los <strong>6 valores predominantes de la persona</strong>, ayudando a predecir cuáles serán sus comportamientos y competencias más esperables.", //23
    "VALORES PREDOMINANTES", //24
    "COMPORTAMIENTOS ESPERADOS", //25
    "COMPETENCIAS ESPERADAS", //26
    "Ajuste cultural de ", //27
    "Ajuste Mentalidad de la Persona-Cultura <strong>VIVIDA EN LA EMPRESA</strong>.", //28
    "Esta representación gráfica se complementa con un KPI que representa esta misma información en base 100.", //29
    "KPI de ajuste: Mentalidad de la Persona-Cultura <strong>Actual</strong>.", //30
    "Ajuste Mentalidad de la Persona-Cultura <strong>DESEADA DE LA EMPRESA</strong>.", //31
    "KPI de ajuste: Mentalidad de la Persona-Cultura <strong>Deseada</strong>.", //32

    "Índice de Liderazgo basado en valores de", //33
    "Los siguientes KPIs muestran como de presentes están los valores relacionados con <strong>El Liderazgo Transaccional o Transformacional </strong> en la persona en base a sus respuestas al assessment CFAM®.", //34
    "KPI de Liderazgo Transformacional", //35
    "KPI de Liderazgo Transaccional", //36
    "El siguiente gráfico representa el perfil de <strong>Liderazgo</strong> de la persona en base a sus respuestas al assessment CFAM®.", //37
    "Valores de liderazgo transaccional y transformacional de", //38
    "Los siguientes gráficos muestran como de presentes están los valores relacionados con <strong> El Liderazgo Transaccional o Transformacional </strong> en la persona en base a sus respuestas al assessment CFAM®.", //39
    "Valores de Liderazgo Transformacional", //40
    "Valores de Liderazgo Transaccional", //41
    "Líder Transformacional", //42
    "Líder Ambidiestro", //43
    "Líder indeterminado", //44
    "Líder Transaccional", //45

    "Índice de Comportamiento Ético de", //46
    "El siguiente índice representa como de presentes están los valores relacionados con el <strong>Comportamiento Ético</strong> de la persona en base a sus respuestas al assessment CFAM®.", //47
    "KPI de Comportamiento Ético", //48
    "El siguiente gráfico revela la mayor o menor presencia de cada uno de los valores que conforman el Índice de Comportamiento Ético de la persona:", //49
    "Índice de Comportamiento Ético", //50

    "Valores y Comportamientos Ético de", //51
    "La siguiente Tabla contiene una relación de los <strong>comportamientos más probables</strong> relacionados con los valores de Comportamiento Ético de la persona:", //52
    "Valores", //53
    "Comportamientos", //54
    "Probabilidad", //55
    "Es posible descargar el <strong class='ment-inf'> informe de mentalidad</strong> y los siguientes informes adicionales:", //56
    "Informe de Ajuste con cultura coorporativa", //57
    "Informe de Comportamiento Ético", //58
    "Informe de Liderazgo", //59
    "Opción que compara la cultura corporativa con el candidato<strong> (Es necesario haber diagnosticado la cultura corporativa)</strong>", //60
    "Opción que incluye el índice de comportamiento ético del candidato", //61
    "Opción que incluye el índice de liderazgo del candidato", //62
    "Fecha", //63
    "Bajo", //64
    "Standard", //65
    "Alto", //66
    "Menos de 70", //67
    "De 70 a 80", //68
    "Más de 80", //69
    "Menos de 6", //70
    "De 6 a 8", //71
    "De 8 a 10", //72
    "Cercanía", //73
    "Apertura al cambio", //74
    "Creatividad", //75
    "Integridad", //76
    "Trabajo en equipo", //77
    "Cooperación", //78
    "Innovación", //79
    "Tolerancia al fallo", //80
    "Respeto", //81
    "Confianza", //82
    "Orient.aprendizaje", //83
    "Liderazgo", //84

    "Orientación a resultados", //85
    "Formalidad", //86
    "Excelencia", //87
    "Coordinación", //88
    "Enfoque analítico", //89
    "Estabilidad", //90
    "Ambición", //91
    "Pragmatismo", //92
    "Responsabilidad", //93
    "Perseverancia", //94
    "Competitividad", //95
    "Eficiencia", //96
    "Mentalidad de la persona", //97
    "Cultura actual", //98
    "Cultura deseada", //99
    "Responsabilidad", //100
    "Lealtad", //101
    "Confianza", //102
    "Integridad", //103
    "Transparencia", //104
    "Respeto", //105
    "Alta", //106
    "Media", //107
    " Ajuste de", //108
    "Gráfica de ajuste entre Mentalidad de ", //109
    " KPI de ajuste de ", //110
    " Ajuste de Valores de ", //111
    "Dinamismo", //112
    "Bienestar", //113
    "Generosidad", //114
    "Autonomía", //115
    "Tolerancia al riesgo", //116
    "Diversidad", //117
    "Compromiso", //118
    "Atrevimiento", //119
    "Empatía", //120
    "Sostenibilidad", //121
    "Valores ideales parametrizados", //122
    "Valores de la persona", //123
    "Mentalidad ideal", //124
    "Mentalidad persona", //125
    "Opción que incluye el índice de liderazgo del candidato", //126
    "Informe de MindsetFit", //127
    "Valores vividos en la empresa", // 128
    "Valores deseados en la empresa", // 129
  ],

  en: [
    "Candidate report types", //0
    "Values Report of", //1
    "And cultural fit with", //2
    "All rights reserved. The Cultural Fit Assessment Method (CFAM®) is property of Cultural Fit Solutions S.L. The total or partial reproduction of the material protected by these intellectual property, its use in any form, or by any means, whether electronic or mechanical, including photocopying, recording, transmission or any information storage and retrieval system or otherwise, is prohibited without the written permission of Cultural Fit Solutions S.L.", //3
    "The CFAM® Model of 6 cultures and mindsets", //4
    "The <strong>CFAM®</strong> method is a <strong>scientific methodology for assessing predominant values</strong> in people and companies. It uses a scientifically validated scale to identify the mindset of individuals and compare them with the culture of the company. It helps to predict which candidates have the best fit with the position and/or the organization, <strong>allowing for a more objective and accurate selection</strong>.", //5
    "CFAM® <strong>evaluates 36 universal values and groups them into 6 archetypes</strong>, which enable us to understand in depth what is the mindset of people and predict their behaviors, competencies, as well as their fit with the position and/or the company.", //6
    "The CFAM® method was developed by researchers at the University of Seville<sup>1</sup> and has been <strong>validated by various institutions of high international prestige</strong>.", //7
    "<sup>1</sup>: Prof. Dr. Antonio Luis Leal Rodríguez , Prof. Dr. Carlos Sanchís Pedregosa , Prof. Antonio M. Moreno Moreno, members of the SEJ573 Research Group <strong>«Organizational Culture, Capabilities and Knowledge for Value Creation (CAPVALUE)»,.</strong>", //8
    "Executive summary", //9
    "Mindset of ", //10
    "Predominant Mindset 1:", //11
    "Predominant values", //12
    "Predominant Mindset 2:", //13
    "Fit", //14
    "<br /><strong>Mindset-Current Culture</strong> Fit", //15
    "<br /><strong>Mindset-Desired Culture</strong> Fit", //16
    "CFAM® is <strong>based on the Competing Values Model</strong>, allowing the <strong>identification of the predominant values</strong> of the person. These values shape the person's mindset and help to predict which behaviors, competencies and functional areas in which the individual will feel most confortable and/or efficient, as well as those in which will not. The following <strong>chart represents the person's mindset profile based on the responses to the CFAM® assessment</strong>.", //17
    "The following table reveals the <strong>degree of affinity of the individual with a particular archetype.</strong> This allows us to understand in a clear and concise way the <strong>predominant mindsets of the person</strong>.This is essential to determine how these may influence their work style and interactions in the work environment.", //18
    "Predominant mindsets:", //19
    "The identification of these predominant mentalities allow <strong> identifying the roles where the individual will develop his/her full potential</strong> and those in which it will be more difficult for this person to develop.", //20
    "High-fit roles", //21
    "Low-fit roles", //22
    "<strong>CFAM®</strong> also identifies the <strong>6 predominant values of the person</strong>, helping to predict what their most expected behaviors and competencies will be.", //23
    "PREDOMINANT VALUES", //24
    "EXPECTED BEHAVIORS", //25
    "EXPECTED COMPETENCIES", //26
    "Cultural fit of ", //27
    "Fit Mindset-<strong>Current Culture</strong>.", //28
    "This graphical representation is complemented by a KPI that represents the same information in base 100.", //29
    "Fit KPI: Mindset-<strong>Current Culture</strong>.", //30
    "Mindset Fit-<strong>Desired Culture</strong>.", //31
    "Fit KPI: Mindset-<strong>Desired Culture</strong>.", //32
    "Values-based Leadership Index of", //33
    "The following KPIs show the extent to which the values related to the <strong>Transactional or Transformational Leadership </strong>are present <strong>in the person based on his/her responses to the CFAM® assessment.", //34
    "Transformational Leadership KPI", //35
    "Transactional Leadership KPI", //36
    "The following graph represents the <strong>Leadership</strong> profile of the individual based on his/her responses to the CFAM® assessment.", //37
    "Transactional and transformational leadership values of", //38
    "The following graphs show how present are the values related to <strong> Transactional or Transformational Leadership </strong>in the person based on his/her responses to the CFAM® assessment.", //39
    "Transformational Leadership Values", //40
    "Transactional Leadership Values", //41
    "Transformational Leader", //42
    "Ambidextrous Leader", //43
    "Non-Leader", //44
    "Transactional Leader", //45

    "Ethical Behavior Index of", //46
    "The following index represents the extent to which the values related to the <strong>Ethical Behavior</strong> are present in the person based on his/her responses to the CFAM® assessment.", //47
    "Ethical Behavior KPI", //48
    "The following graph reveals the greater or lesser presence of each of the values that make up the person's Ethical Behavior Index.", //49
    "Ethical Behavior Index", //50
    "Ethical Values and Behaviors of", //51
    "The following Table contains a list of the <strong>most probable behaviors</strong> related to the Ethical Behavior values of the person:", //52
    "Values", //53
    "Behaviors", //54
    "Probability", //55
    "It is possible to download the <strong class='ment-inf'>mindset report</strong> and the following additional reports:", //56
    "Cultural fit report", //57
    "Ethical Behavior report", //58
    "Leadership report", //59
    "Option that compares the corporate culture with the candidate<strong>(It is mandatory to have diagnosed corporate culture)</strong>", //60
    "Option that includes the candidate's ethical behavior index", //61
    "Option that includes the candidate's leadership index", //62
    "Date", //63
    "Low", //64
    "Standard", //65
    "High", //66
    "Less than 70", //67
    "From 70 to 80", //68
    "More than 80", //69
    "Less than 6", //70
    "From 6 to 8", //71
    "From 8 to 10", //72
    "Warmness and friendliness", //73
    "Openness to change", //74
    "Creativity", //75
    "Integrity", //76
    "Teamwork", //77
    "Cooperation", //78
    "Innovation", //79
    "Tolerance for failure", //80
    "Respect", //81
    "Trust", //82
    "Learning focus", //83
    "Leadership", //84
    "Outcome orientation", //85
    "Rule-abiding", //86
    "Excellence", //87
    "Coordination", //88
    "Analytical mindedness", //89
    "Stability", //90
    "Ambition", //91
    "Pragmatism", //92
    "Responsibility", //93
    "Perseverance", //94
    "Competitiveness", //95
    "Efficiency", //96
    "Person's mindset", //97
    "Current culture", //98
    "Desired culture", //99
    "Responsibility", //100
    "Loyalty", //101
    "Trust", //102
    "Integrity", //103
    "Transparency", //104
    "Respect", //105
    "High", //106
    "Medium", //107
    "Fit of", //108
    "Graph of fit between ", //109
    "Fit KPI of ", //110
    "Fit Values of ", //111
    "Dynamism", //112
    "Well-being", //113
    "Generosity", //114
    "Independence", //115
    "Risk friendliness,", //116
    "Diversity", //117
    "Commitment", //118
    "Boldness", //119
    "Empathy", //120
    "Sustainability", //121
    "Parameterized ideal values", //122
    "Person's values", //123
    "Ideal mindset", //124
    "Person's mindset", //125
    "Option that includes MindsetFit", //126
    "MindsetFit report", //127
    "Culture values in the team", //128
    "Desired values in the team", //129
  ],
};
