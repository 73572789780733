<template>
  <div>
    <apexchart
      type="radar"
      :height="chartHeight"
      :options="chartOptions"
      :series="chartSeries"
    ></apexchart>
  </div>
</template>

<script setup>
import { defineProps, computed } from "vue";
import { useStore } from "vuex";

import DictionaryForReport from "@/globals/DictionaryForReport";
const store = useStore();

const Proximity = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][73];
});
const OpennessToChange = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][74];
});
const Creativity = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][75];
});
const Integrity = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][76];
});
const Team = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][77];
});
const Cooperation = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][78];
});
const Innovation = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][79];
});
const FailureTolerance = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][80];
});
const Respect = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][81];
});
const Trust = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][82];
});
const Learning = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][83];
});
const Leadership = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][84];
});

const props = defineProps({
  reportData: Object,
});

const chartHeight = computed(() => {
  return "350"; // Ajusta esta altura según sea necesario
});

// Opciones básicas para el gráfico, puedes ajustar esto según sea necesario
const chartOptions = computed(() => ({
  chart: {
    height: 350,
    type: "radar",
    toolbar: {
      show: false,
    },
  },
  xaxis: {
    categories: [
      Proximity.value,
      OpennessToChange.value,
      Creativity.value,
      Integrity.value,
      Team.value,
      Cooperation.value,
      Innovation.value,
      FailureTolerance.value,
      Respect.value,
      Trust.value,
      Learning.value,
      Leadership.value,
    ],
    labels: {
      show: true,
      style: {
        colors: [
          "#34495e",
          "#34495e",
          "#34495e",
          "#34495e",
          "#34495e",
          "#34495e",
          "#34495e",
          "#34495e",
          "#34495e",
          "#34495e",
          "#34495e",
          "#34495e",
        ],
        fontSize: "12px",
        fontFamily: "Arial",
      },
    },
  },
  yaxis: {
    show: false,
  },
  legend: {
    show: true,
    offsetY: 0,
    labels: {
      colors: "#34495e",
    },
    fontWeight: 600,
    fontSize: "9px",
    markers: {
      width: 12,
      height: 12,
      strokeWidth: 0,
      strokeColor: "#fff",
      fillColors: ["#B39B3E", "#34495e"],
      radius: 0,
      onClick: undefined,
    },
  },
  stroke: {
    width: 1,
    colors: ["#B39B3E", "#34495e"],
  },
  markers: {
    size: 0,
  },
  fill: {
    colors: ["#B39B3E", "#34495e"],
  },
}));

// Extrae las series de `reportData.series_33`
const chartSeries = computed(() => {
  // Asegúrate de que `reportData` y `series_33` existen antes de intentar acceder a ellos
  if (props.reportData && props.reportData.series_33) {
    return props.reportData.series_33;
  }
  // Retorna un arreglo vacío o alguna serie predeterminada si no hay datos disponibles
  return [];
});
</script>
