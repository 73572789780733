<template>
  <div class="scatter-plot-container2">
    <!-- SVG Container -->
    <svg :width="width" :height="height" ref="chart">
      <!-- Cuadrantes -->
      <rect
        :x="padding"
        :y="padding"
        :width="Math.max(xScale(5) - padding, 0)"
        :height="Math.max(yScale(5) - padding, 0)"
        fill="#fff"
      />
      <rect
        :x="xScale(5)"
        :y="padding"
        :width="Math.max(plotWidth - xScale(5), 0)"
        :height="Math.max(yScale(5) - padding, 0)"
        fill="#fff"
      />
      <rect
        :x="padding"
        :y="yScale(5)"
        :width="Math.max(xScale(5) - padding, 0)"
        :height="Math.max(plotHeight - yScale(5), 0)"
        fill="#fff"
      />
      <rect
        :x="xScale(5)"
        :y="yScale(5)"
        :width="Math.max(plotWidth - xScale(5), 0)"
        :height="Math.max(plotHeight - yScale(5), 0)"
        fill="#fff"
      />
      <text
        :x="padding + (xScale(5) - padding) / 2"
        :y="padding + (yScale(5) - padding) / 44.3"
        text-anchor="middle"
        alignment-baseline="central"
        fill="#34495e"
        :style="{ 'font-size': '15px' }"
      >
        {{ TransfoLead }}
      </text>
      <text
        :x="xScale(5) + (plotWidth - xScale(5)) / 1.3"
        :y="padding + (yScale(5) - padding) / 44.3"
        text-anchor="middle"
        alignment-baseline="central"
        fill="#34495e"
        :style="{ 'font-size': '15px' }"
      >
        {{ AmbiLead }}
      </text>
      <text
        :x="padding + (xScale(5) - padding) / 2"
        :y="yScale(5) + (plotHeight - yScale(5)) / 1.1"
        text-anchor="middle"
        alignment-baseline="central"
        fill="#34495e"
        :style="{ 'font-size': '15px' }"
      >
        {{ NoLead }}
      </text>
      <text
        :x="xScale(5) + (plotWidth - xScale(5)) / 1.3"
        :y="yScale(5) + (plotHeight - yScale(5)) / 1.1"
        text-anchor="middle"
        alignment-baseline="central"
        fill="#34495e"
        :style="{ 'font-size': '15px' }"
      >
        {{ TransacLead }}
      </text>
      <!-- Eje X -->
      <line
        :x1="padding"
        :y1="plotHeight"
        :x2="plotWidth"
        :y2="plotHeight"
        stroke="#000"
      />
      <!-- Eje Y -->
      <line
        :x1="padding"
        :y1="padding"
        :x2="padding"
        :y2="plotHeight"
        stroke="#000"
      />

      <!-- Punto de Datos -->
      <circle
        v-for="(point, index) in chartSeries[0].data"
        :key="index"
        :cx="xScale(point.x)"
        :cy="yScale(point.y)"
        r="9"
        fill="#34495e"
      />

      <!-- Lineas de Cuadrantes -->
      <line
        :x1="xScale(6)"
        :y1="padding"
        :x2="xScale(6)"
        :y2="plotHeight"
        stroke="#B39B3D"
        stroke-width="2"
      />
      <line
        :x1="padding"
        :y1="yScale(6)"
        :x2="plotWidth"
        :y2="yScale(6)"
        stroke="#B39B3D"
        stroke-width="2"
      />

      <!-- Etiquetas del Eje X -->
      <text
        v-for="i in 10"
        :key="i"
        :x="xAxisScale(i)"
        :y="plotHeight + 20"
        text-anchor="middle"
      >
        {{ i }}
      </text>
      <!-- Etiquetas del Eje Y -->
      <text
        v-for="i in 10"
        :key="i"
        :x="padding - 20"
        :y="yAxisScale(i) + 5"
        text-anchor="middle"
      >
        {{ i }}
      </text>

      <!-- Título del Eje X -->
      <text :x="plotWidth / 2" :y="plotHeight + 40" text-anchor="middle">
        {{ TKpiTransac }}
      </text>

      <text
        :x="padding - 60"
        :y="plotHeight / 2"
        text-anchor="middle"
        :transform="'rotate(-90,' + (padding - 45) + ',' + plotHeight / 2 + ')'"
      >
        {{ TKpiTransf }}
      </text>
    </svg>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, onBeforeUnmount, defineProps } from "vue";
import { useStore } from "vuex";

import DictionaryForReport from "@/globals/DictionaryForReport";

const store = useStore();
const TransfoLead = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][42];
});
const AmbiLead = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][43];
});
const NoLead = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][44];
});
const TransacLead = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][45];
});
const TKpiTransac = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][36];
});
const TKpiTransf = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][35];
});

const props = defineProps({
  reportData: Object,
});

const padding = 90;
const width = ref(300);
const height = ref(300);

const plotWidth = computed(() => width.value - padding);
const plotHeight = computed(() => height.value - padding);

const xMax = 10;
const yMax = 10;

// Calcula la posición X en base al valor de X y el ancho total del gráfico
const xScale = (x) => {
  return (x / xMax) * (plotWidth.value - padding) + padding;
};

// Calcula la posición Y en base al valor de Y y la altura total del gráfico
const yScale = (y) => {
  return plotHeight.value - (y / yMax) * (plotHeight.value - padding);
};

// Utilizada para posicionar las etiquetas en el eje X.
const xAxisScale = (x) => {
  return (x / xMax) * (plotWidth.value - padding) + padding;
};

// Utilizada para posicionar las etiquetas en el eje Y.
const yAxisScale = (y) => {
  return plotHeight.value - (y / yMax) * (plotHeight.value - padding);
};

const chartSeries = computed(() => [
  {
    name: "Grupo A",
    data: [
      {
        x: props.reportData.KPITTransaccional,
        y: props.reportData.KPITTransformacional,
      },
    ],
  },
]);

const makeResponsive = () => {
  const chartContainer = document.querySelector(".scatter-plot-container2");
  const containerWidth = chartContainer ? chartContainer.offsetWidth : 300;
  width.value = containerWidth;
  height.value = containerWidth; // Asumiendo que quieres mantener el gráfico proporcional
};

onMounted(() => {
  makeResponsive();
  window.addEventListener("resize", makeResponsive);
});

onBeforeUnmount(() => {
  window.removeEventListener("resize", makeResponsive);
});
</script>

<style>
.scatter-plot-container2 {
  margin-left: 3%;
  padding: 0px;
  margin-top: -85px;
}
</style>
