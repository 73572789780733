<template>
  <!--Dialog to delete a tag-->
  <v-dialog
    v-model="dialog"
    activator="parent"
    :width="mdAndUp ? '25%' : '90%'"
  >
    <v-card class="d-flex justify-center text-center">
      <v-container>
        <!--Title Dialog-->
        <v-card-subtitle class="mt-3" style="color: #34495e">
            {{ subtitle }}
          </v-card-subtitle>
        <v-card-title class="" style="color: #00c3c0">
            {{selected_candidate.name}}

            </v-card-title>
     
        <v-card-text>
          <!--Buttons Dialog-->
          <v-row dense class="mt-8">
            <v-col cols="12">
              <!--Dialog Cancel Button -->
              <v-btn @click="dialog = false" class="mr-3 cancel_btn">
                {{ cancel_btn }}
              </v-btn>
              <!--Dialog Save Button -->
              <v-btn color="error" @click="deleteCandidate">
                {{ delete_btn }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import { defineComponent } from "vue";
import { mapState } from "vuex";
import { mapMutations } from "vuex";
import { useDisplay } from "vuetify";

//API Service
import TagsService from "@/services/TagsService";
import ProfileEvaluationService from "@/services/ProfileEvaluationService";

// Components

export default defineComponent({
  name: "DeleteCandidateDialog",
  data() {
    return {
      //New Label Dialog
      dialog: false,

      //Buttons Dialog
      cancel_btn: "Cancelar",
      delete_btn: "Eliminar",

      //Dialog Title
      subtitle: "¿Está seguro que desea eliminar este candidato?",
    };
  },
  components: {
    //CandidatesTable,
  },

  computed: {
    ...mapState(["user_info", "selected_candidate"]),
  },

  methods: {
    ...mapMutations(["setTags_list", "setCandidates", "detailed_view_change_step"]),

    //Delete Candidate
    async deleteCandidate() { 
      try {
        const response = await ProfileEvaluationService.deleteCandidate(this.selected_candidate.token);
        let data = response.data;
        console.log(data);

        if(data.code === 200){
          this.loadAllTags();
          this.loadCandidatesGeneralView();
          this.dialog = false;
          this.detailed_view_change_step();

        }

      } catch (error) {
        console.log(error);
      }
    },

    //Aux
    async loadAllTags() {
      try {
        const response = await TagsService.getAllTags(this.user_info.tokens.tokenPE);
        let data = response.data;

        this.setTags_list(data);

      } catch (error) {
        console.log(error);
      }
    },

    async loadCandidatesGeneralView() {
      try {
        const response =
          await ProfileEvaluationService.loadCandidatesGeneralView(
            this.user_info.tokens.tokenPE,
            "es"
          );
        var data = response.data;

        this.setCandidates(data);
      } catch (error) {
        console.log(error);
      }
    },
  },

  setup() {
    // Destructure only the keys we want to use
    const { mdAndUp } = useDisplay();

    return { mdAndUp };
  },
});
</script>

<style scoped>
.label_btn {
  background-color: #00c3c0;
  color: white;
}

.new_label_item {
  font-size: 16px;
  color: #34495e;
  background-color: #bdf0ee;
}

.newtag_tf {
  font-style: italic;
  font-weight: bold;
  background-color: #bdf0ee;
  width: auto;
}

.cancel_btn {
  background-color: #34495e;
  color: white;
}
</style>