<template>
  <v-sheet :height="main_container_height" width="100%" color="transparent">
    <div :class="mdAndUp ? 'd-flex flex-row' : ''">
      <v-sheet
        color="transparent"
        :height="left_top_sheet_height"
        :width="left_sheet_width"
        class="d-flex align-center"
      >
        <v-row dense :class="mdAndUp ? '' : 'mx-3 mt-5'">
          <v-col cols="12">
            <v-row dense class="">
              <v-col md="12" class="hidden-sm-and-down">
                <div class="d-flex flex-row w-100 align-center ml-10">
                  <h2 class="text-uppercase mr-7" style="color: #34495e">
                    {{ selected_candidate.name }}
                  </h2>
                  <div>
                    <v-btn
                      class="px-4 label_btn"
                      variant="flat"
                      density="compact"
                    >
                      {{ tags_btn }}
                    </v-btn>
                    <CandidateTagsDialog
                      :current_candidate="selected_candidate"
                    />
                  </div>
                </div>
                <div class="d-flex flex-row w-100 align-center ml-10">
                  <p class="mr-7" style="color: #34495e">
                    {{ selected_candidate.email_user }}
                  </p>
                </div>
              </v-col>

              <!--Mobile Responsive-->
              <v-col cols="10" class="hidden-md-and-up">
                <h2 class="text-uppercase" style="color: #34495e">
                  {{ selected_candidate.name }}
                </h2>
                <div class="d-flex flex-row w-100 align-center mb-5">
                  <p class="" style="color: #34495e">
                    {{ selected_candidate.email_user }}
                  </p>
                </div>
                <div>
                  <v-btn
                    class="px-4 label_btn"
                    variant="flat"
                    density="compact"
                  >
                    {{ tags_btn }}
                  </v-btn>
                  <CandidateTagsDialog
                    :current_candidate="selected_candidate"
                  />
                </div>
              </v-col>

              <!--Mobile Responsive-->
              <v-col cols="2" class="hidden-md-and-up">
                <v-menu>
                  <template v-slot:activator="{ props }">
                    <v-btn
                      v-bind="props"
                      class="hidden-md-and-up bg-transparent"
                      size="x-large"
                      color="#34495e"
                      icon="mdi mdi-dots-vertical"
                      variant="text"
                      :ripple="false"
                    >
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item
                      :value="cultural_profile"
                      prepend-icon="mdi mdi-chart-tree"
                      class="rounded-pill"
                      :style="
                        detailed_view_options_step == 1
                          ? 'background-color: #bdf0ee'
                          : ''
                      "
                      @click="detailed_view_option1_change_step"
                    >
                      <v-list-item-title>{{
                        cultural_profile
                      }}</v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      :value="engagement"
                      prepend-icon="mdi mdi-chart-arc"
                      class="rounded-pill"
                      :style="
                        detailed_view_options_step == 2
                          ? 'background-color: #bdf0ee'
                          : ''
                      "
                      @click="detailed_view_option2_change_step"
                    >
                      <v-list-item-title>{{ engagement }}</v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      :value="ethical"
                      prepend-icon="mdi mdi-chart-arc"
                      class="rounded-pill"
                      :style="
                        detailed_view_options_step == 3
                          ? 'background-color: #bdf0ee'
                          : ''
                      "
                      @click="detailed_view_option3_change_step"
                    >
                      <v-list-item-title>{{ ethical }}</v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      :value="leadership"
                      prepend-icon="mdi mdi-chart-arc"
                      class="rounded-pill"
                      :style="
                        detailed_view_options_step == 4
                          ? 'background-color: #bdf0ee'
                          : ''
                      "
                      @click="detailed_view_option4_change_step"
                    >
                      <v-list-item-title>{{ leadership }}</v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      :value="pred_values"
                      prepend-icon="mdi mdi-chart-arc"
                      class="rounded-pill"
                      :style="
                        detailed_view_options_step == 5
                          ? 'background-color: #bdf0ee'
                          : ''
                      "
                      @click="detailed_view_option5_change_step"
                    >
                      <v-list-item-title>{{ pred_values }}</v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      :value="pred_values"
                      prepend-icon="mdi mdi-chart-arc"
                      class="rounded-pill"
                      :style="
                        detailed_view_options_step == 6
                          ? 'background-color: #bdf0ee'
                          : ''
                      "
                      @click="detailed_view_option6_change_step"
                    >
                      <v-list-item-title>{{
                        parameterizationFit
                      }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-col>
            </v-row>

            <v-row dense class="mt-2">
              <v-col md="14" class="hidden-sm-and-down">
                <div class="bg-transparent ml-10">
                  <v-btn-toggle
                    v-model="toggle"
                    group
                    class="h-auto bg-transparent"
                    selected-class="btn_selected"
                  >
                    <v-btn
                      class="font-weight-bold px-16 mx-0 btn_toggles"
                      density="compact"
                      rounded="lg"
                      height="35"
                      width="15%"
                      value="cultural_profile_btn"
                      @click="cultural_btn"
                    >
                      {{ cultural_profile }}
                    </v-btn>

                    <v-btn
                      class="font-weight-bold px-16 mx-0 btn_toggles"
                      density="compact"
                      rounded="lg"
                      height="35"
                      width="15%"
                      value="ethical_btn"
                      @click="ethical_btn"
                    >
                      {{ ethical }}
                    </v-btn>
                    <v-btn
                      class="font-weight-bold px-16 mx-0 btn_toggles"
                      density="compact"
                      rounded="lg"
                      height="35"
                      width="15%"
                      value="leadership_btn"
                      @click="leadership_btn"
                    >
                      {{ leadership }}
                    </v-btn>
                    <v-btn
                      class="font-weight-bold px-16 mx-0 btn_toggles"
                      density="compact"
                      rounded="lg"
                      height="35"
                      width="15%"
                      value="pred_values_btn"
                      @click="pred_values_btn"
                    >
                      {{ pred_values }}
                    </v-btn>
                    <v-btn
                      class="font-weight-bold px-16 mx-0 btn_toggles"
                      density="compact"
                      rounded="lg"
                      height="35"
                      width="15%"
                      value="parameterizationFit_btn"
                      @click="parameterizationFit_btn"
                    >
                      {{ parameterizationFit }}
                    </v-btn>
                    <v-btn
                      class="font-weight-bold px-16 mx-0 btn_toggles"
                      density="compact"
                      rounded="lg"
                      height="35"
                      width="20%"
                      value="engagement_btn"
                      @click="engagement_btn"
                    >
                      {{ engagement }}
                    </v-btn>
                  </v-btn-toggle>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-sheet>
      <v-sheet
        :color="
          detailed_view_options_step == 5 ||
          detailed_view_options_step == 4 ||
          detailed_view_options_step == 3 ||
          detailed_view_options_step == 2 ||
          (detailed_view_options_step == 1 && smAndDown)
            ? 'transparent'
            : 'transparent'
        "
        :height="right_top_sheet_height"
        :width="right_sheet_width"
        class="d-flex align-center"
      >
        <v-row dense>
          <v-col cols="12" class="text-end">
            <div
              :class="
                mdAndUp
                  ? 'd-flex flex-direction:row justify-end mb-5 mr-5'
                  : 'd-flex flex-direction:row justify-end mt-8'
              "
            >
              <v-row
                dense
                :class="mdAndUp ? 'd-flex justify-end mx-5' : 'mx-3 mb-10'"
              >
                <v-col cols="6" md="4">
                  <v-btn
                    :block="mdAndUp ? false : true"
                    class="font-weight-bold top_buttons"
                    rounded="lg"
                    variant="flat"
                    @click="go_back"
                  >
                    <v-icon class="mr-2">mdi mdi-keyboard-return</v-icon>
                    {{ goback }}
                  </v-btn>
                </v-col>
                <v-col cols="6" md="4">
                  <v-btn
                    :block="mdAndUp ? false : true"
                    :class="
                      mdAndUp
                        ? 'font-weight-bold top_buttons ml-5'
                        : 'font-weight-bold top_buttons'
                    "
                    rounded="lg"
                    variant="flat"
                  >
                    <v-icon class="mr-2">mdi mdi-trash-can</v-icon>
                    {{ delete_candidate }}
                    <v-tooltip activator="parent" location="top"
                      >Eliminar Candidato</v-tooltip
                    >
                  </v-btn>
                  <DeleteCandidateDialog />
                </v-col>
              </v-row>
            </div>
          </v-col>
        </v-row>
      </v-sheet>
    </div>

    <v-sheet color="transparent" :height="bottom_sheet_height" width="100%">
      <v-window v-model="detailed_view_options_step">
        <v-window-item :value="1">
          <CandidateCultureProfileView />
        </v-window-item>

        <v-window-item :value="2">
          <CandidateEngagementView />
        </v-window-item>

        <v-window-item :value="3">
          <CandidateEthicalView />
        </v-window-item>
        <v-window-item :value="4">
          <CandidateLeadershipView />
        </v-window-item>

        <v-window-item :value="5">
          <CandidatePredValuesView />
        </v-window-item>

        <v-window-item :value="6">
          <CandidateIdealFitView />
        </v-window-item>
      </v-window>
    </v-sheet>
  </v-sheet>
</template>

<script>
import { defineComponent } from "vue";
import { mapMutations, mapState } from "vuex";
import { useDisplay } from "vuetify";
import { computed } from "vue";

// Components
import CandidateCultureProfileView from "./CandidateCultureProfileView.vue";
import CandidateEngagementView from "./CandidateEngagementView.vue";
import CandidateIdealFitView from "./CandidateIdealFitView.vue";
import CandidateEthicalView from "./CandidateEthicalView.vue";
import CandidateLeadershipView from "./CandidateLeadershipView.vue";
import CandidatePredValuesView from "./CandidatePredValuesView.vue";
import CandidateTagsDialog from "@/components/DashboardComponents/ProfilesEvaluation/CandidateTagsDialog.vue";
import DeleteCandidateDialog from "@/components/DashboardComponents/ProfilesEvaluation/DeleteCandidateDialog.vue";

export default defineComponent({
  name: "CandidateDetailedView",

  data: () => ({
    //Buttons Toggle Label
    cultural_profile: "",
    engagement: "",
    ethical: "",
    leadership: "",
    parameterizationFit: "",

    pred_values: "",
    tags_btn: "",

    //Buttons Label
    goback: "",
    delete_candidate: "",
  }),

  components: {
    CandidateCultureProfileView,
    CandidateEngagementView,
    CandidateEthicalView,
    CandidateLeadershipView,
    CandidatePredValuesView,
    CandidateIdealFitView,
    CandidateTagsDialog,
    DeleteCandidateDialog,
  },

  computed: {
    ...mapState([
      "detailed_view_step",
      "detailed_view_options_step",
      "selected_candidate",
      "user_info",
      "toggle",
    ]),
  },

  methods: {
    ...mapMutations([
      "detailed_view_change_step",
      "detailed_view_option1_change_step",
      "detailed_view_option2_change_step",
      "detailed_view_option3_change_step",
      "detailed_view_option4_change_step",
      "detailed_view_option5_change_step",
      "detailed_view_option6_change_step",
      "change_toggle_to_cultural",
      "change_toggle_to_engagement",
      "change_toggle_to_ethical",
      "change_toggle_to_leadership",
      "change_toggle_to_pred_values",
      "change_toggle_to_parameterizationFit",
    ]),

    go_back() {
      this.detailed_view_option1_change_step();
      this.detailed_view_change_step();
      this.change_toggle_to_cultural();
    },

    cultural_btn() {
      this.detailed_view_option1_change_step();
      this.change_toggle_to_cultural();
    },

    engagement_btn() {
      this.detailed_view_option2_change_step();
      this.change_toggle_to_engagement();
    },
    parameterizationFit_btn() {
      this.detailed_view_option6_change_step();
      this.change_toggle_to_parameterizationFit();
    },
    ethical_btn() {
      this.detailed_view_option3_change_step();
      this.change_toggle_to_ethical();
    },
    leadership_btn() {
      this.detailed_view_option4_change_step();
      this.change_toggle_to_leadership();
    },
    pred_values_btn() {
      this.detailed_view_option5_change_step();
      this.change_toggle_to_pred_values();
    },

    set_texts() {
      let t = sessionStorage.getItem("texts");
      let texts = JSON.parse(t);
      this.cultural_profile = texts[98];
      this.engagement = texts[99];
      this.ethical = texts[103];
      this.leadership = texts[104];
      this.pred_values = texts[105];
      this.tags_btn = texts[71];
      this.goback = texts[33];
      this.delete_candidate = texts[78];
      this.parameterizationFit = texts[107];
    },
  },

  created() {
    this.set_texts();
  },

  setup() {
    // Destructure only the keys we want to use
    const { mdAndUp } = useDisplay();
    const { smAndDown } = useDisplay();
    const { xsAndUp } = useDisplay();

    const { name } = useDisplay();

    const main_container_height = computed(() => {
      // name is reactive and
      // must use .value
      switch (name.value) {
        case "xs":
          return "auto";
        case "sm":
          return "auto";
        case "md":
          return (92 * window.innerHeight) / 100;
        case "lg":
          return (92 * window.innerHeight) / 100;
        case "xl":
          return (92 * window.innerHeight) / 100;
        case "xxl":
          return (92 * window.innerHeight) / 100;
      }

      return undefined;
    });

    const left_top_sheet_height = computed(() => {
      // name is reactive and
      // must use .value
      switch (name.value) {
        case "xs":
          return "auto";
        case "sm":
          return "auto";
        case "md":
          return (20 * ((92 * window.innerHeight) / 100)) / 100;
        case "lg":
          return (20 * ((92 * window.innerHeight) / 100)) / 100;
        case "xl":
          return (15 * ((92 * window.innerHeight) / 100)) / 100;
        case "xxl":
          return (15 * ((92 * window.innerHeight) / 100)) / 100;
      }

      return undefined;
    });

    const right_top_sheet_height = computed(() => {
      // name is reactive and
      // must use .value
      switch (name.value) {
        case "xs":
          return "auto";
        case "sm":
          return "auto";
        case "md":
          return (20 * ((92 * window.innerHeight) / 100)) / 100;
        case "lg":
          return (10 * ((92 * window.innerHeight) / 100)) / 100;
        case "xl":
          return (10 * ((92 * window.innerHeight) / 100)) / 100;
        case "xxl":
          return (10 * ((92 * window.innerHeight) / 100)) / 100;
      }

      return undefined;
    });

    const left_sheet_width = computed(() => {
      // name is reactive and
      // must use .value
      switch (name.value) {
        case "xs":
          return "auto";
        case "sm":
          return "auto";
        case "md":
          return (65 * window.innerWidth) / 100;
        case "lg":
          return (65 * window.innerWidth) / 100;
        case "xl":
          return (65 * window.innerWidth) / 100;
        case "xxl":
          return (65 * window.innerWidth) / 100;
      }

      return undefined;
    });

    const right_sheet_width = computed(() => {
      // name is reactive and
      // must use .value
      switch (name.value) {
        case "xs":
          return "auto";
        case "sm":
          return "auto";
        case "md":
          return (35 * window.innerWidth) / 100;
        case "lg":
          return (35 * window.innerWidth) / 100;
        case "xl":
          return (35 * window.innerWidth) / 100;
        case "xxl":
          return (35 * window.innerWidth) / 100;
      }

      return undefined;
    });

    const bottom_sheet_height = computed(() => {
      // name is reactive and
      // must use .value
      switch (name.value) {
        case "xs":
          return "auto";
        case "sm":
          return "auto";
        case "md":
          return (80 * ((92 * window.innerHeight) / 100)) / 100;
        case "lg":
          return (80 * ((92 * window.innerHeight) / 100)) / 100;
        case "xl":
          return (85 * ((92 * window.innerHeight) / 100)) / 100;
        case "xxl":
          return (85 * ((92 * window.innerHeight) / 100)) / 100;
      }

      return undefined;
    });

    // eslint-disable-next-line vue/no-dupe-keys
    return {
      main_container_height,
      bottom_sheet_height,
      left_sheet_width,
      left_top_sheet_height,
      right_sheet_width,
      right_top_sheet_height,
      mdAndUp,
      smAndDown,
      xsAndUp,
    };
  },
});
</script>

<style scoped>
.label_btn {
  background-color: #00c3c0;
  color: white;
}

.btn_selected {
  color: white !important;
  background-color: #34495e !important;
}

.btn_toggles {
  background-color: #d9dde0;
  color: #34495e;
}

.top_buttons {
  background-color: #00c3c0;
  color: white;
}

.row {
  background-color: #fbfbfb;
}
</style>
