<template>
    <!--Main Container with the picture and form-->
    <RecoverPasswordSheet/>
     
 </template>
 
   <script>
 import { defineComponent } from "vue";
 
 // Components
 import RecoverPasswordSheet from "@/components/RecoverPasswordComponents/RecoverPasswordSheet.vue";
 
 export default defineComponent({
   name: "RecoverPasswordView",
 
   components: {
    RecoverPasswordSheet,
   },
 });
 </script>