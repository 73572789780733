<template>
  <!--Center Card-->
  <v-card
    class="mx-auto"
    :elevation="mdAndUp ? '3' : '0'"
    :height="card_height"
    :width="card_width"
  >
    <v-row dense no-gutters>
      <!--Picture Column-->
      <v-col cols="12" md="6" lg="7">
        <v-img
          src="@/assets/Picture.png"
          :height="card_height"
          cover
          class="text-center"
        >
          <v-sheet
            height="100%"
            color="transparent"
            class="d-flex flex-column justify-space-between justify-center text-center"
          >
            <!-- <v-row>
              <v-col cols="12" class="d-flex align-center justify-center">
                <div class="text-center" style="width: 83%">
                  <h6
                    class="text-transparent"
                    style="font-size: 14px !important"
                  >
                    {{ slogan }}
                  </h6>
                </div>
              </v-col>
            </v-row> -->
            <v-row dense>
              <v-col cols="12" class="d-flex align-center justify-center">
                <!-- <v-avatar
                  color="transparent"
                  :size="smAndDown ? '90%' : '80%'"
                  rounded="0"
                >
                  <v-img src="@/assets/WhiteLogo.png"></v-img>
                </v-avatar> -->
              </v-col>
            </v-row>

            <!-- <v-row dense no-gutters class="mt-15">
              <v-col cols="12" class="d-flex align-center justify-center">
                <div class="text-center" style="">
                  <h6
                    class="text-white"
                    :style="
                      smAndDown
                        ? 'font-size: 9px !important; font-width: bold; font-style: italic'
                        : 'font-size: 14px !important; font-width: bold; font-style: italic'
                    "
                  >
                    {{ slogan }}
                  </h6>
                </div>
              </v-col>
            </v-row> -->
          </v-sheet>
        </v-img>
      </v-col>

      <!--Forms Column-->
      <v-col cols="12" sm="12" md="6" lg="5">
        <v-sheet
          :class="
            mdAndUp
              ? 'mx-auto my-auto d-flex align-center'
              : 'mt-15 mx-auto my-auto d-flex align-center'
          "
          :width="mdAndUp ? '75%' : '100%'"
          :height="card_height"
        >
          <v-sheet class="w-100">
            <v-row>
              <v-col cols="12">
                <!--Form Select Buttons-->
                <v-btn-toggle
                  v-model="toggle"
                  mandatory
                  selected-class="btn_selected"
                >
                  <v-btn
                    class="font-weight-bold px-8 mx-0 btn_toggles"
                    density="compact"
                    rounded="lg"
                    value="signin"
                    @click="step = 2"
                  >
                    {{ signin_label }}
                  </v-btn>
                  <v-btn
                    class="font-weight-bold px-12 mx-0 btn_toggles"
                    density="compact"
                    rounded="lg"
                    value="login"
                    @click="step = 1"
                  >
                    {{ login_label }}
                  </v-btn>
                </v-btn-toggle>
              </v-col>
            </v-row>
            <!--Title-->
            <v-row>
              <v-col cols="12" class="text-center">
                <h4 style="color: #585858">{{ title }}</h4>
              </v-col>
            </v-row>
            <v-row class="mt-1">
              <v-col cols="12">
                <!-- Forms window -->
                <v-window v-model="step">
                  <v-window-item :value="1">
                    <!--Login Form-->
                    <CredentialsForm />
                  </v-window-item>

                  <v-window-item :value="2">
                    <!--Signin Form-->
                    <SigninDataForm />
                  </v-window-item>
                </v-window>
              </v-col>
            </v-row>
          </v-sheet>
        </v-sheet>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { defineComponent } from "vue";
import { useDisplay } from "vuetify";
import { computed } from "vue";

// Components
import SigninDataForm from "@/components/LoginComponents/SigninDataForm.vue";
import CredentialsForm from "@/components/LoginComponents/CredentialsForm.vue";
import { mapState } from "vuex";

export default defineComponent({
  name: "LoginCard",
  data: () => ({
    //Loging Button Toggle is always selected on load
    toggle: "signin",

    //Intro
    title: "",
    slogan: "",
    signin_label: "",
    login_label: "",

    //To change forms window item
    step: 2,

    //Card Height
    height: "",
  }),

  components: {
    SigninDataForm,
    CredentialsForm,
  },

  methods: {
    set_texts() {
      let t = sessionStorage.getItem("texts");
      if (t) {
        let texts = JSON.parse(t);
        this.title = texts[4];
        this.slogan = texts[5];
        this.signin_label = texts[2];
        this.login_label = texts[3];
      }
    },
  },

  created() {
    this.set_texts();
  },

  setup() {
    const { mdAndUp } = useDisplay();
    const { smAndDown } = useDisplay();
    const { xsAndUp } = useDisplay();
    const { name } = useDisplay();

    const card_height = computed(() => {
      switch (name.value) {
        case "xs":
          return "auto";
        case "sm":
          return "auto";
        case "md":
        case "lg":
        case "xl":
        case "xxl":
          return (95 * ((88 * window.innerHeight) / 100)) / 100;
      }
      return undefined;
    });

    const card_width = computed(() => {
      switch (name.value) {
        case "xs":
        case "sm":
          return "90%";
        case "md":
        case "lg":
        case "xl":
        case "xxl":
          return (75 * window.innerWidth) / 100;
      }
      return undefined;
    });

    return { card_height, card_width, mdAndUp, xsAndUp, smAndDown };
  },
  computed: {
    ...mapState([""]),
  },
});
</script>

<style scoped>
.btn_selected {
  color: white !important;
  background-color: #34495e !important;
}

.btn_toggles {
  background-color: #d9dde0;
  color: #34495e;
}
</style>
