<!-- eslint-disable vue/no-mutating-props -->
<!-- eslint-disable vue/no-mutating-props -->
<template>
  <!--Dialog to add a new label-->
  <v-dialog
    v-model="dialog"
    activator="parent"
    persistent
    :width="mdAndUp ? '30%' : '90%'"
  >
    <v-card class="d-flex justify-center">
      <!--ToolBar to close the dialog-->
      <v-toolbar color="transparent" height="50">
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn icon @click="dialog = false" :ripple="false" variant="text">
            <v-icon color="#34495e">mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-container fluid>
        <!-- Dialog Title-->
        <div class="w-100 text-center">
          <h2 class="text-uppercase" style="color: #34495e">
            {{ current_candidate.name }}
          </h2>
        </div>
        <!--Dialog Subtitle 1-->
        <v-row dense class="d-flex justify-center text-center">
          <v-col cols="11" md="10">
            <div>
              <p class="text-subtitle-1 text-uppercase" style="color: #00c3c0">
                {{ dialog_subtitle }}
              </p>
            </div>
          </v-col>
        </v-row>

        <v-card-text>
          <v-row
            dense
            v-if="current_candidate.tags.length === 0"
            align="center"
            justify="start"
            class="mt-2"
          >
            <v-col cols="12" class="text-center">
              <p style="color: #585858">No hay etiquetas asignadas</p>
            </v-col>
          </v-row>

          <v-row dense v-else align="center" justify="start" class="mt-2">
            <v-col
              v-for="(tag, i) in current_candidate.tags"
              :key="tag"
              cols="auto"
            >
              <v-chip
                pill
                closable
                size="small"
                class="chip"
                close-icon="mdi mdi-close-circle-outline"
                @click:close="deleteCandidateTag(i, tag)"
              >
                {{ tag.texto }}
              </v-chip>
            </v-col>
          </v-row>

          <v-divider class="mt-5"></v-divider>
        </v-card-text>
        <v-card-actions>
          <v-card
            elevation="0"
            width="100%"
            max-height="300"
            class="overflow-auto scroll"
          >
            <v-list width="100%">
              <template v-for="tag in tags_list">
                <v-list-item
                  class=""
                  v-if="!myIncludesTag(tag)"
                  :key="tag"
                  @click="setTagToCandidate(tag)"
                >
                  <v-list-item-title> {{ tag.texto }} </v-list-item-title>
                  <template v-slot:append>
                    <v-icon>mdi mdi-plus</v-icon>
                  </template>
                </v-list-item>
              </template>
            </v-list>
          </v-card>
        </v-card-actions>
      </v-container>
    </v-card>
  </v-dialog>
</template>
      
    <script>
import { useDisplay } from "vuetify";
import { mapMutations, mapState } from "vuex";

//API Services
import TagsService from "@/services/TagsService";

export default {
  name: "CandidateTagsDialog",
  props: {
    current_candidate: {
      type: Object,
    },
  },
  data: () => ({
    // Form data
    form: false,

    //Verification Dialog
    dialog: false,

    //Dialog texts
    dialog_subtitle: "Etiquetas",
  }),

  computed: {
    ...mapState(["tags_list"]),
  },

  components: {},

  methods: {
    ...mapMutations(["candidates_equal"]),

    myIncludesTag(tag) {
      let index = 0;

      while (
        index < this.current_candidate.tags.length &&
        this.current_candidate.tags[index].texto !== tag.texto
      ) {
        ++index;
      }

      return index < this.current_candidate.tags.length;
    },

    //Add Tag
    async setTagToCandidate(tag) {
      let formData = new FormData();
      formData.append("candidato_id", this.current_candidate.token);
      formData.append("tag_id", tag.id);

      try {
        const response = await TagsService.setTagToCandidate(formData);
        let data = response.data;
        if (data.code == 200) {
          let new_tag = {
            id: structuredClone(data.message.id),
            tag_id: structuredClone(tag.id),
            texto: structuredClone(tag.texto),
          };
          
          
          // eslint-disable-next-line vue/no-mutating-props
          this.current_candidate.tags.push(new_tag);
          this.candidates_equal();
        }
      } catch (error) {
        console.log(error);
      }
    },

    //Remove Tag from Candidate
    async deleteCandidateTag(tag_index, tag) {
      let formData = new FormData();
      formData.append("id", tag.id);

      try {
        const response = await TagsService.deleteCandidateTag(formData);
        let data = response.data;
        console.log(data);

        // eslint-disable-next-line vue/no-mutating-props
        this.current_candidate.tags.splice(tag_index, 1);
        this.candidates_equal();

      } catch (error) {
        console.log(error);
      }
    },
  },
  setup() {
    // Destructure only the keys we want to use
    const { mdAndUp } = useDisplay();

    return { mdAndUp };
  },
};
</script>
    
<style scoped>
.chip {
  background-color: #c2c8cf;
  font-size: 16px;
}
</style>