export default {
  spanish: [
    "Responsabilidad",
    "Lealtad",
    "Confianza",
    "Integridad",
    "Transparencia",
    "Respeto",
  ],

  english: [],
};
