<template>
  <v-sheet color="transparent" :height="bottom_sheet_height" width="100%">
    <v-row dense style="height: 100%">
      <!--Graficas trnasfo y transac-->

      <v-col cols="12" md="3" class="text-center">
        <v-row dense class="d-flex justify-center cardwhite4">
          <h3 class="text-uppercase" style="color: #34495e">
            {{ title3_line1 }}
          </h3>

          <LeadershipChart />
        </v-row>
        <v-row dense class="d-flex justify-center cardwhite4">
          <h3 class="text-uppercase" style="color: #34495e">
            {{ title4_line1 }}
          </h3>

          <LeadershipChart2 />
        </v-row>
      </v-col>

      <!--Kpis Engagement Graphic-->
      <v-col
        cols="12"
        md="4"
        :class="mdAndUp ? 'text-center  cardwhite3' : 'text-center cardwhite3'"
      >
        <!--Title-->
        <v-row dense class="">
          <v-col cols="12">
            <h3 class="text-uppercase" style="color: #34495e">
              {{ title2 }}
            </h3>
            <p class="subtitle2 mt-2">
              Mide la predominancia en la persona de los valores relacionados
              con cada tipo de liderazgo
            </p>
          </v-col>
        </v-row>
        <!--Progress Circular-->
        <v-row dense class="d-flex justify-center">
          <v-col cols="6">
            <v-row dense>
              <v-col cols="12" class="text-center">
                <p class="subtitle">KPI de Liderazgo Transformacional</p>
              </v-col>
            </v-row>
            <v-row dense class="mt-5">
              <v-col cols="12">
                <v-avatar size="105" :color="background_color1">
                  <h1 style="color: #34495e">
                    {{ leaderData.transformacionalPoints }}
                  </h1>
                </v-avatar>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="6">
            <v-row dense>
              <v-col cols="12" class="text-center">
                <p class="subtitle">KPI de Liderazgo Transaccional</p>
              </v-col>
            </v-row>
            <v-row dense class="mt-5">
              <v-col cols="12">
                <v-avatar size="105" :color="background_color2">
                  <h1 style="color: #34495e">
                    {{ leaderData.transaccionalPoints }}
                  </h1>
                </v-avatar>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <!--Legend-->
        <v-row dense :class="mdAndUp ? 'mt-10' : 'mt-10 mx-5'">
          <v-col cols="12">
            <div class="rounded-pill">
              <v-row dense class="d-flex justify-center">
                <V-col cols="4">
                  <h5>BAJO</h5>
                </V-col>
                <V-col cols="4">
                  <h5>ESTÁNDAR</h5>
                </V-col>
                <V-col cols="4">
                  <h5>ALTO</h5>
                </V-col>
              </v-row>
            </div>
          </v-col>
        </v-row>
        <v-row dense :class="mdAndUp ? '' : 'mx-3'">
          <v-col cols="12">
            <div class="rounded-pill legend py-3 w-100">
              <v-row dense class="d-flex justify-center mx-1">
                <V-col cols="4" class="d-flex align-center justify-center">
                  <div
                    class="rounded-pill pl-1 pt-2 w-25"
                    style="background-color: #fffc8b"
                  ></div>
                  <p class="ml-1 legend_text">Menos de 6</p>
                </V-col>
                <V-col cols="4" class="d-flex align-center justify-center">
                  <div
                    class="rounded-pill pl-1 pt-2 w-25"
                    style="background-color: #cadfb8"
                  ></div>
                  <p class="ml-1 legend_text">6-8</p>
                </V-col>
                <V-col cols="4" class="d-flex align-center justify-center">
                  <div
                    class="rounded-pill pl-1 pt-2 w-25"
                    style="background-color: #b1d095"
                  ></div>
                  <p class="ml-1 legend_text">8-10</p>
                </V-col>
              </v-row>
            </div>
            <p class="discreet-note">
              Este índice mide la prevalencia de valores asociados a los estilos
              de liderazgo, <strong>no es un veredicto final</strong> sobre la
              calidad o capacidad global del liderazgo de un individuo. Un
              índice bajo no señala una falta de habilidades de liderazgo, sino
              áreas donde la persona puede desarrollarse para integrar sus
              prácticas de liderazgo con los valores específicos de liderazgo
              transaccional o transformacional.
            </p>
          </v-col>
        </v-row>
      </v-col>

      <!--Company Engagement-->
      <v-col cols="12" md="4" class="text-center cardwhite2">
        <v-row dense>
          <v-col cols="12">
            <h3 class="text-uppercase" style="color: #34495e">
              {{ title1_line1 }}
            </h3>
            <p class="subtitle2 mt-2">
              El cuadrante en el que se posiciona el punto indica el tipo de
              liderazgo predominante en la persona
            </p>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" :class="mdAndUp ? '' : 'd-flex justify-center'">
            <Person_LeaderChart />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
import { defineComponent } from "vue";
import { useDisplay } from "vuetify";
import { computed } from "vue";

// Components

import Person_LeaderChart from "@/components/DashboardComponents/ProfilesEvaluation/Person_LeaderChart.vue";

import LeadershipChart from "@/components/DashboardComponents/ProfilesEvaluation/LeadershipChart.vue";
import LeadershipChart2 from "@/components/DashboardComponents/ProfilesEvaluation/LeadershipChart2.vue";
import { mapState } from "vuex";

export default defineComponent({
  name: "CandidateEngagementView",

  components: {
    Person_LeaderChart,
    LeadershipChart,
    LeadershipChart2,
  },

  data: () => ({
    //Labels
    title1_line1: "MATRIZ DE LIDERAZGO BASADO EN VALORES",
    title1_line2: "",
    subtitle1:
      "La gráfica muestra el estilo de liderazgo de la persona en base a sus valores predominantes",

    title2: "kpis de Liderazgo",

    title3_line1: "Valores de Liderazgo transformacional",
    title3_line2: "Mentalidad Persona-Cultura Deseada",
    subtitle3:
      "Identifica en qué grado encaja la mentalidad de la persona con la cultura meta",
    title4_line1: "Valores de Liderazgo transaccional",
  }),

  computed: {
    ...mapState(["engagementData", "leaderData"]),
    color1() {
      return this.color_transformacionalPoints();
    },

    color2() {
      return this.color_transaccionalPoints();
    },

    background_color1() {
      return this.background_color_transformacionalPoints();
    },

    background_color2() {
      return this.background_color_transaccionalPoints();
    },
  },

  methods: {
    color_transformacionalPoints() {
      if (this.leaderData.transformacionalPoints < 6) {
        return "#F7E66B";
      }
      if (this.leaderData.transformacionalPoints > 8) {
        return "#8ABE66";
      }
      if (
        this.leaderData.transformacionalPoints >= 6 &&
        this.leaderData.transformacionalPoints <= 8
      ) {
        return "#CADFB8";
      }
    },

    color_transaccionalPoints() {
      if (this.leaderData.transaccionalPoints < 6) {
        return "#F7E66B";
      }
      if (this.leaderData.transaccionalPoints > 8) {
        return "#8ABE66";
      }

      if (
        this.leaderData.transaccionalPoints >= 6 &&
        this.leaderData.transaccionalPoints <= 8
      ) {
        return "#CADFB8";
      }
    },

    background_color_transaccionalPoints() {
      if (this.leaderData.transaccionalPoints < 6) {
        return "#FBF3BB";
      }
      if (this.leaderData.transaccionalPoints > 8) {
        return "#C9E1B9";
      }
      if (
        this.leaderData.transaccionalPoints >= 6 &&
        this.leaderData.transaccionalPoints <= 8
      ) {
        return "#E7F0DE";
      }
    },

    background_color_transformacionalPoints() {
      if (this.leaderData.transformacionalPoints < 6) {
        return "#FBF3BB";
      }
      if (this.leaderData.transformacionalPoints > 8) {
        return "#C9E1B9";
      }
      if (
        this.leaderData.transformacionalPoints >= 6 &&
        this.leaderData.transformacionalPoints <= 8
      ) {
        return "#E7F0DE";
      }
    },
  },

  setup() {
    // Destructure only the keys we want to use
    const { mdAndUp } = useDisplay();

    const { name } = useDisplay();

    const bottom_sheet_height = computed(() => {
      // name is reactive and
      // must use .value
      switch (name.value) {
        case "xs":
          return "auto";
        case "sm":
          return "auto";
        case "md":
          return (80 * ((92 * window.innerHeight) / 100)) / 100;
        case "lg":
          return (85 * ((92 * window.innerHeight) / 100)) / 100;
        case "xl":
          return (85 * ((92 * window.innerHeight) / 100)) / 100;
        case "xxl":
          return (85 * ((92 * window.innerHeight) / 100)) / 100;
      }

      return undefined;
    });

    return { mdAndUp, bottom_sheet_height };
  },
});
</script>

<style scoped>
.subtitle {
  color: #585858;
  font-weight: bold;
  font-size: 14px;
}
.subtitle2 {
  color: #585858;

  font-size: 14px;
}

.col2 {
  border-left: 2px solid #00c3c0 !important;
  border-bottom: 2px solid #00c3c0 !important;
}
.col1 {
  border-right: 1px solid #00c3c0 !important;
  border-bottom: 2px solid #00c3c0 !important;
}

.legend {
  border: 1px solid black !important;
  /*  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
}

.legend_text {
  font-size: 13px;
}
.cardwhite2 {
  background-color: white;
  border-radius: 10px;
  border: 1px solid #efefef;
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
  /*  padding: 20px;*/
  margin-left: 2%;
  margin-top: 1%;
  margin-bottom: 10%;
}
.cardwhite3 {
  background-color: white;
  border-radius: 10px;
  border: 1px solid #efefef;
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
  /*  padding: 20px;*/
  margin-left: 2%;
  margin-top: 1%;
  margin-bottom: 10%;
}
.cardwhite4 {
  background-color: white;
  border-radius: 10px;
  border: 1px solid #efefef;
  width: 100%;
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
  margin-top: 3%;
  margin-left: 2%;
}
.discreet-note {
  font-size: 10px;
  color: #000000;
  margin-top: 10px;
  text-align: justify;
}
</style>
