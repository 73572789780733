<template>
  <!--Corporate Culture Profile-->
  <v-sheet
    :height="charts_container_height"
    color="transparent"
    :class="mdAndUp ? 'ml-6 mt-3' : ''"
  >
    <v-row wrap no-gutters>
      <v-col cols="12" :class="mdAndUp ? '' : 'mt-12 text-center'">
        <v-sheet :height="titles_sheet_height" color="transparent">
          <v-row wrap no-gutters :class="mdAndUp ? '' : ''">
            <!--Org Culture Chart Title-->
            <v-col cols="12" md="7" class="text-center">
              <!--Title-->
              <h5 class="text-uppercase text-myblue">
                {{ title1 }}
              </h5>
              <!--Subtitle-->
              <h5 class="text-uppercase text-myblue">
                {{ user_info.company_name }}
              </h5>
            </v-col>
            <!--Table Title-->
            <v-col
              cols="12"
              md="5"
              class="d-flex align-center justify-center hidden-sm-and-down"
            >
            <v-row class="d-flex justify-center">
              <v-col cols="10"  class="d-flex align-center justify-center ">
                <h5 class="text-uppercase text-myblue hidden-sm-and-down">
                  {{ table_title }}
                </h5>
              </v-col>
              <v-col cols="1"></v-col>
            </v-row>
              
            </v-col>
          </v-row>
        </v-sheet>
      </v-col>
    </v-row>

    <!--Chart-->
    <v-row wrap no-gutters :class="mdAndUp ? '' : ''">
      <v-col cols="12" md="7">
        <v-sheet
          :height="charts_sheet_height"
          color="transparent"
          width="auto"
          :class="mdAndUp ? 'ml-8' : 'd-flex justify-center'"
        >
          <OrgSpiderChart :titles="titles" />
        </v-sheet>
      </v-col>

      <!--Table-->
      <v-col cols="12" md="5">
        <v-sheet
          :height="charts_sheet_height"
          color="transparent"
          class="d-flex align-center justify-center"
        >
          <div class="d-flex flex-column justify-center text-center">
            <h5 class="text-uppercase text-myblue mb-5 hidden-md-and-up">
              {{ table_title }}
            </h5>
            <OrgPredominanceTable :titles="titles" />
          </div>
        </v-sheet>
      </v-col>
    </v-row>
  </v-sheet>

  <!--Engagement-->
  <v-sheet
    :height="charts_container_height"
    color="transparent"
    :class="mdAndUp ? 'ml-6 mt-3' : ''"
  >
    <v-row wrap no-gutters>
      <v-col cols="12">
        <!--Title-->
        <v-row dense :class="mdAndUp ? '' : 'mt-12 text-center'">
          <v-col cols="12">
            <v-sheet
              :height="titles_sheet_height"
              class="d-flex align-center"
              color="transparent"
            >
              <v-row class="pa-0">
                <v-col
                  cols="12"
                  md="7"
                  class="pa-0 text-center d-flex justify-center"
                >
                  <h5 :class="mdAndUp ? 'text-uppercase text-myblue' : 'text-uppercase text-myblue mx-10 mt-5'">
                    {{ subtitle2 }}
                  </h5>
                </v-col>
                <v-col cols="5" class="pa-0 text-center hidden-sm-and-down">
                  <h5 class="text-uppercase text-myblue hidden-sm-and-down">
                    {{ speedometer_title }}
                  </h5>
                </v-col>
              </v-row>
            </v-sheet>
          </v-col>
        </v-row>

        <v-row dense>
          <!--Chart-->
          <v-col cols="12" md="7" :class="mdAndUp ? '' : ''">
            <v-sheet
              :height="charts_sheet_height"
              color="transparent"
              :class="mdAndUp ? 'ml-8' : 'd-flex justify-center'"
            >
              <OrgTeamEngagementChart />
            </v-sheet>
          </v-col>

          <!--Speedometer-->
          <v-col cols="12" md="5" class="">
            <v-sheet
              :height="charts_sheet_height"
              color="transparent"
              class="mt-n2"
            >
              <v-row dense class="hidden-md-and-up">
                <v-col cols="12" class="text-center" :class="mdAndUp ? '' : 'mt-5 mb-5'">
                  <h5 class="text-uppercase text-myblue hidden-md-and-up mt-5">
                    {{ speedometer_title }}
                  </h5>
                </v-col>
              </v-row>
              <v-sheet
                :height="charts_sheet_height"
                color="transparent"
                class="d-flex align-center"
              >
                <EngagementDegree />
              </v-sheet>
            </v-sheet>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-sheet>
</template>
         
<script>
import { mapState } from "vuex";
import { useDisplay } from "vuetify";
import { computed } from "vue";

//Components
import OrgSpiderChart from "@/components/DashboardComponents/CorporateCultureDiagnosis/OrgSpiderChart.vue";
import OrgPredominanceTable from "@/components/DashboardComponents/CorporateCultureDiagnosis/OrgPredominanceTable.vue";
import OrgTeamEngagementChart from "./OrgTeamEngagementChart.vue";
import EngagementDegree from "./EngagementDegree.vue";

export default {
  name: "DashboardChartSection",
  props: {
    titles: {
      type: Object,
    },
  },

  components: {
    OrgSpiderChart,
    OrgPredominanceTable,
    OrgTeamEngagementChart,
    EngagementDegree,
  },

  data: () => ({
    //Label
    title1: "",
    subtitle2: "",
    speedometer_title: "",
    table_title: "",
  }),

  computed: {
    ...mapState(["user_info"]),
  },

  methods:{
    set_texts (){
      let t = sessionStorage.getItem("texts");
      let texts = JSON.parse(t);
      this.title1 = texts[47];
      this.table_title = texts[49];
      this.subtitle2 = texts[51];
      this.speedometer_title = texts[53];
    }
  },

  created(){
    this.set_texts();

  },

  setup() {
    // Destructure only the keys we want to use
    const { mdAndUp } = useDisplay();

    const { name } = useDisplay();

    const charts_container_height = computed(() => {
      // name is reactive and
      // must use .value
      switch (name.value) {
        case "xs":
          return "auto";
        case "sm":
          return "auto";
        case "md":
          return (45 * ((92 * window.innerHeight) / 100)) / 100;
        case "lg":
          return (45 * ((92 * window.innerHeight) / 100)) / 100;
        case "xl":
          return (45 * ((92 * window.innerHeight) / 100)) / 100;
        case "xxl":
          return (45 * ((92 * window.innerHeight) / 100)) / 100;
      }

      return undefined;
    });

    const titles_sheet_height = computed(() => {
      // name is reactive and
      // must use .value
      switch (name.value) {
        case "xs":
          return "auto";
        case "sm":
          return "auto";
        case "md":
          return (13 * ((45 * ((92 * window.innerHeight) / 100)) / 100)) / 100;
        case "lg":
          return (13 * ((45 * ((92 * window.innerHeight) / 100)) / 100)) / 100;
        case "xl":
          return (10 * ((45 * ((92 * window.innerHeight) / 100)) / 100)) / 100;
        case "xxl":
          return (10 * ((45 * ((92 * window.innerHeight) / 100)) / 100)) / 100;
      }

      return undefined;
    });

    const charts_sheet_height = computed(() => {
      // name is reactive and
      // must use .value
      switch (name.value) {
        case "xs":
          return "auto";
        case "sm":
          return "auto";
        case "md":
          return (87 * ((45 * ((92 * window.innerHeight) / 100)) / 100)) / 100;
        case "lg":
          return (87 * ((45 * ((92 * window.innerHeight) / 100)) / 100)) / 100;
        case "xl":
          return (90 * ((45 * ((92 * window.innerHeight) / 100)) / 100)) / 100;
        case "xxl":
          return (90 * ((45 * ((92 * window.innerHeight) / 100)) / 100)) / 100;
      }

      return undefined;
    });

    return {
      mdAndUp,
      charts_container_height,
      titles_sheet_height,
      charts_sheet_height,
    };
  },
};
</script>
       
       <style scoped>
.text-myblue {
  color: #34495e;
}
</style>