<template>
  <div class="scatter-plot-container">
    <!-- SVG Container -->
    <svg :width="width" :height="height" ref="chart">
      <!-- Cuadrantes -->
      <rect
        :x="padding"
        :y="padding"
        :width="Math.max(xScale(5) - padding, 0)"
        :height="Math.max(yScale(5) - padding, 0)"
        fill="#fff"
      />
      <rect
        :x="xScale(5)"
        :y="padding"
        :width="Math.max(plotWidth - xScale(5), 0)"
        :height="Math.max(yScale(5) - padding, 0)"
        fill="#fff"
      />
      <rect
        :x="padding"
        :y="yScale(5)"
        :width="Math.max(xScale(5) - padding, 0)"
        :height="Math.max(plotHeight - yScale(5), 0)"
        fill="#fff"
      />
      <rect
        :x="xScale(5)"
        :y="yScale(5)"
        :width="Math.max(plotWidth - xScale(5), 0)"
        :height="Math.max(plotHeight - yScale(5), 0)"
        fill="#fff"
      />
      <text
        :x="padding + (xScale(5) - padding) / 2"
        :y="padding + (yScale(5) - padding) / 44.3"
        text-anchor="middle"
        alignment-baseline="central"
        fill="#34495e"
        :style="{ 'font-size': '15px' }"
      >
        Líder Transformacional
      </text>
      <text
        :x="xScale(5) + (plotWidth - xScale(5)) / 1.3"
        :y="padding + (yScale(5) - padding) / 44.3"
        text-anchor="middle"
        alignment-baseline="central"
        fill="#34495e"
        :style="{ 'font-size': '15px' }"
      >
        Líder Ambidiestro
      </text>
      <text
        :x="padding + (xScale(5) - padding) / 2"
        :y="yScale(5) + (plotHeight - yScale(5)) / 1.1"
        text-anchor="middle"
        alignment-baseline="central"
        fill="#34495e"
        :style="{ 'font-size': '15px' }"
      >
        Líder indeterminado
      </text>
      <text
        :x="xScale(5) + (plotWidth - xScale(5)) / 1.3"
        :y="yScale(5) + (plotHeight - yScale(5)) / 1.1"
        text-anchor="middle"
        alignment-baseline="central"
        fill="#34495e"
        :style="{ 'font-size': '15px' }"
      >
        Líder Transaccional
      </text>
      <!-- Eje X -->
      <line
        :x1="padding"
        :y1="plotHeight"
        :x2="plotWidth"
        :y2="plotHeight"
        stroke="#000"
      />
      <!-- Eje Y -->
      <line
        :x1="padding"
        :y1="padding"
        :x2="padding"
        :y2="plotHeight"
        stroke="#000"
      />

      <!-- Punto de Datos -->
      <circle
        v-for="(point, index) in chartSeries[0].data"
        :key="index"
        :cx="xScale(point.x)"
        :cy="yScale(point.y)"
        r="9"
        fill="#34495e"
      />

      <!-- Lineas de Cuadrantes -->
      <line
        :x1="xScale(6)"
        :y1="padding"
        :x2="xScale(6)"
        :y2="plotHeight"
        stroke="#B39B3D"
        stroke-width="2"
      />
      <line
        :x1="padding"
        :y1="yScale(6)"
        :x2="plotWidth"
        :y2="yScale(6)"
        stroke="#B39B3D"
        stroke-width="2"
      />

      <!-- Etiquetas del Eje X -->
      <text
        v-for="i in 10"
        :key="i"
        :x="xAxisScale(i)"
        :y="plotHeight + 20"
        text-anchor="middle"
      >
        {{ i }}
      </text>
      <!-- Etiquetas del Eje Y -->
      <text
        v-for="i in 10"
        :key="i"
        :x="padding - 20"
        :y="yAxisScale(i) + 5"
        text-anchor=""
      >
        {{ i }}
      </text>

      <!-- Título del Eje X -->
      <text :x="plotWidth / 2" :y="plotHeight + 40" text-anchor="middle">
        KPI de Liderazgo transaccional
      </text>

      <text
        :x="padding - 60"
        :y="plotHeight / 2"
        text-anchor="middle"
        :transform="'rotate(-90,' + (padding - 45) + ',' + plotHeight / 2 + ')'"
      >
        KPI de Liderazgo transformacional
      </text>
    </svg>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Person_LeaderChart",

  data() {
    return {
      padding: 60,
      width: 400,
      height: 400,
    };
  },
  computed: {
    ...mapState(["leaderData"]),
    plotWidth() {
      return this.width - this.padding;
    },
    plotHeight() {
      return this.height - this.padding;
    },
    xScale() {
      const xMax = 10;
      return (x) => (x / xMax) * (this.plotWidth - this.padding) + this.padding;
    },
    yScale() {
      const yMax = 10;
      return (y) =>
        this.plotHeight - (y / yMax) * (this.plotHeight - this.padding);
    },
    xAxisScale() {
      const xMax = 10;
      return (x) => (x / xMax) * (this.plotWidth - this.padding) + this.padding;
    },
    yAxisScale() {
      const yMax = 10;
      return (y) =>
        this.plotHeight - (y / yMax) * (this.plotHeight - this.padding);
    },
    chartSeries() {
      return [
        {
          name: "Grupo A",
          data: [
            {
              x: this.leaderData.transaccionalPoints,
              y: this.leaderData.transformacionalPoints,
            },
          ],
        },
      ];
    },
  },
  mounted() {
    this.makeResponsive();
    window.addEventListener("resize", this.makeResponsive);

    this.resizeObserver = new ResizeObserver(() => {
      this.makeResponsive();
    });
    this.resizeObserver.observe(this.$refs.chart.parentNode);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.makeResponsive);

    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
  },
  methods: {
    makeResponsive() {
      const chartContainer = this.$refs.chart.parentNode;
      const containerWidth = chartContainer.offsetWidth;
      this.width = containerWidth;
    },
  },
};
</script>

<style>
.scatter-plot-container {
  margin-left: 3%;
  padding: 0px;
  margin-top: -20px;
}
</style>
