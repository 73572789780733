<template>
    <!--Main Container with the picture and form-->
    <ProfileSheet/>
     
 </template>
 
   <script>
 import { defineComponent } from "vue";
 
 // Components
 import ProfileSheet from "@/components/ProfileComponents/ProfileSheet.vue";
 
 export default defineComponent({
   name: "EditProfileView",
 
   components: {
    ProfileSheet,
   },
 });
 </script>