<template>
  <!-- Table -->
  <v-sheet :height="table_sheet_height" width="100%" color="transparent">
    <v-row dense class="mx-4">
      <v-col cols="12" class="text-center">
        <div class="d-flex">
          <h3
            class="text-uppercase mt-6 text-start mr-5"
            style="color: #34495e"
          >
            Panel de gestión de Parametrizaciones
          </h3>
          <v-text-field
            v-model="search"
            density="compact"
            variant="flat"
            :placeholder="'Buscar parametrización...'"
            bg-color="white"
            hide-details
            class="mt-3"
          >
            <template v-slot:append-inner>
              <div
                style="height: 100%; position: absolute"
                class="filter_icon rounded d-flex align-center mt-n2 px-2 ml-n6"
              >
                <v-icon size="25" color="white" location="top right">
                  mdi-magnify
                </v-icon>
              </div>
            </template>
          </v-text-field>
        </div>

        <div v-if="localParamCandidates.length === 0" class="no-param-message">
          <v-btn
            class="new-param-buttonBack"
            @click="detailed_view_change_step(0)"
            ><v-icon class="iconBtnPG mr-2">mdi mdi-keyboard-return</v-icon
            >Volver a perfiles</v-btn
          >
          <v-btn class="new-param-button ml-9" @click="addNewParam">
            Haz tu primera Parametrización
            <v-icon large>mdi-plus</v-icon>
          </v-btn>
        </div>
        <div v-else class="table-container mt-5">
          <table class="styled-table">
            <thead>
              <tr>
                <th>Nombre de parametrización</th>
                <th>Fecha de creación</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in paginatedParams" :key="item.param_id">
                <td class="font-weight-bold">
                  {{ item.param_name || "Nombre no disponible" }}
                </td>
                <td>{{ item.param_fechaRegistro }}</td>

                <td>
                  <div class="d-flex flex-row justify-center align-center">
                    <v-btn
                      v-if="item.param_name.startsWith('*')"
                      density="compact"
                      class="table_actions_icons"
                      @click="confirmClone(item)"
                    >
                      Clonar con otro nombre
                    </v-btn>
                    <v-btn
                      v-else
                      density="compact"
                      class="table_actions_icons"
                      @click="modifyParam(item)"
                    >
                      Modificar
                    </v-btn>
                    <v-btn
                      v-if="!item.param_name.startsWith('*')"
                      density="compact"
                      class="table_actions_icons btn-delete"
                      @click="confirmDelete(item)"
                    >
                      Eliminar
                    </v-btn>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          v-if="localParamCandidates.length > 0"
          class="new-param-bottom mt-5"
        >
          <v-btn
            class="new-param-buttonBack"
            @click="detailed_view_change_step(0)"
            ><v-icon class="iconBtnPG mr-2">mdi mdi-keyboard-return</v-icon
            >Volver a perfiles</v-btn
          >
          <v-btn class="new-param-button" @click="addNewParam">
            Nueva Parametrización
            <v-icon large>mdi-plus</v-icon>
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <v-row wrap no-gutters class="mt-9 ml-5">
      <v-col cols="7" class="d-flex flex-row align-center">
        <h3 class="mr-2" style="color: #34495e">Total:</h3>
        <div class="cand_total">
          <h3 style="color: #00c3c0">{{ localParamCandidates.length }}</h3>
        </div>
      </v-col>
      <v-col cols="3">
        <v-pagination
          v-model="page"
          :length="pageCount"
          density="compact"
          total-visible="5"
          @input="onPageChange"
          style="color: #34495e"
        ></v-pagination>
      </v-col>
    </v-row>
  </v-sheet>

  <!-- Confirm Delete Dialog -->
  <v-dialog v-model="confirmDialogVisible" persistent max-width="500px">
    <v-card>
      <v-card-title>Confirmar Eliminación</v-card-title>
      <v-card-text>
        ¿Estás seguro de que quieres eliminar esta parametrización?
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="green darken-1" text @click="performDelete">
          Confirmar
        </v-btn>
        <v-btn color="red darken-1" text @click="cancelDelete">
          Cancelar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <!-- Default Param Warning Dialog -->
  <v-dialog v-model="defaultParamDialogVisible" persistent max-width="500px">
    <v-card>
      <v-card-title>Acción no permitida</v-card-title>
      <v-card-text>
        No puedes eliminar esta parametrización porque forma parte de las
        parametrizaciones por defecto.
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="closeDefaultParamDialog">
          Entendido
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <!-- Confirm Clone Dialog -->
  <v-dialog v-model="confirmCloneDialogVisible" persistent max-width="500px">
    <v-card>
      <v-card-title>Confirmar Clonación</v-card-title>
      <v-card-text>
        ¿Estás seguro de que deseas clonar esta parametrización por defecto para
        hacer cambios en la copia?
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="green darken-1" text @click="performClone">
          Confirmar
        </v-btn>
        <v-btn color="red darken-1" text @click="cancelClone"> Cancelar </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapMutations, mapActions } from "vuex";
import ProfileEvaluationService from "@/services/ProfileEvaluationService"; // Importa tu servicio

export default {
  name: "CandidatesDesktopTableOfIdealParam",
  props: {
    table_top_sheet_height: {
      type: Number,
    },
    table_sheet_height: {
      type: Number,
    },
    paramCandidates: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      search: "",
      page: 1,
      itemsPerPage: 8,

      confirmDialogVisible: false,
      defaultParamDialogVisible: false,
      confirmCloneDialogVisible: false,
      itemToDelete: null,
      itemToClone: null,
      localParamCandidates: [...this.paramCandidates], // Copia local de paramCandidates
    };
  },
  watch: {
    paramCandidates: {
      handler(newVal) {
        this.localParamCandidates = [...newVal];
      },
      deep: true,
    },
  },
  computed: {
    filteredParams() {
      if (!this.search) {
        return this.localParamCandidates;
      } else {
        return this.localParamCandidates.filter((item) =>
          item.param_name.toLowerCase().includes(this.search.toLowerCase())
        );
      }
    },
    pageCount() {
      return Math.ceil(this.filteredParams.length / this.itemsPerPage);
    },
    paginatedParams() {
      const start = (this.page - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.filteredParams.slice(start, end);
    },
  },
  methods: {
    ...mapMutations([
      "parametrization_view_change_step",
      "setSelectedParametrization",
      "setCurrentStep",
      "detailed_view_change_step",
    ]),
    ...mapActions(["fetchParametrization"]),

    modifyParam(item) {
      this.setSelectedParametrization({
        id: item.param_id,
        name: item.param_name,
        data: item.param_data,
      });
      this.setCurrentStep(5);
    },

    confirmClone(item) {
      this.itemToClone = item;
      this.confirmCloneDialogVisible = true;
    },

    performClone() {
      if (!this.itemToClone) return;

      this.modifyParam(this.itemToClone);
      this.confirmCloneDialogVisible = false;
      this.itemToClone = null;
    },

    cancelClone() {
      this.itemToClone = null;
      this.confirmCloneDialogVisible = false;
    },

    confirmDelete(item) {
      this.itemToDelete = item;
      this.confirmDialogVisible = true;
    },
    cancelDelete() {
      this.itemToDelete = null;
      this.confirmDialogVisible = false;
    },
    async performDelete() {
      if (!this.itemToDelete) return;

      const id = this.itemToDelete.param_id;
      console.log("Attempting to delete item with id:", id);

      try {
        const response =
          await ProfileEvaluationService.DeleteParametrizeCandidate(id);
        console.log("Response from delete service:", response);

        // Comprobación detallada de la respuesta
        if (response.status === 200 || response.status === 204) {
          console.log("Successfully deleted item with id:", id);
          // Eliminar la parametrización de la lista localmente
          this.localParamCandidates = this.localParamCandidates.filter(
            (param) => param.param_id !== id
          );
          this.confirmDialogVisible = false;
          this.itemToDelete = null;
          this.setCurrentStep(4); // Cambia la vista o realiza la acción que necesites
        } else {
          console.error(
            `Error deleting item with id: ${id}. Status code:`,
            response.status
          );
          this.handleDeleteError(response);
        }
      } catch (error) {
        console.error("Error deleting item with id:", id, "Error:", error);
        this.handleDeleteError(error);
      }
    },

    handleDeleteError(error) {
      // Manejo de errores más detallado
      if (error.response) {
        // El servidor respondió con un código de estado que cae fuera del rango de 2xx
        console.error("Error response data:", error.response.data);
        console.error("Error response status:", error.response.status);
        console.error("Error response headers:", error.response.headers);
      } else if (error.request) {
        // La solicitud fue hecha pero no se recibió respuesta
        console.error("Error request:", error.request);
      } else {
        // Algo pasó al configurar la solicitud
        console.error("Error message:", error.message);
      }
      console.error("Error config:", error.config);

      // Aquí puedes agregar lógica adicional para manejar errores, como mostrar una notificación al usuario
      alert(
        "Hubo un error al intentar eliminar la parametrización. Por favor, inténtalo de nuevo."
      );
    },

    closeDefaultParamDialog() {
      this.defaultParamDialogVisible = false;
    },

    addNewParam() {
      this.parametrization_view_change_step(2);
    },
    onPageChange(newPage) {
      this.page = newPage;
    },
  },
};
</script>

<style scoped>
.table-container {
  overflow-x: auto;
}

.styled-table {
  width: 100%;
  border-collapse: collapse;
  font-size: 0.9em;
  font-family: "Roboto", sans-serif;
  min-width: 400px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.styled-table thead tr {
  background-color: #00c3c0;
  color: #ffffff;
  text-align: left;
}

.styled-table th,
.styled-table td {
  padding: 1% 1%;
  text-align: center;
}

@media (max-width: 1500px) {
  .styled-table th,
  .styled-table td {
    padding: 0.6% 0.6%;
    text-align: center;
  }
}

.styled-table tbody tr {
  border-bottom: 1px solid #dddddd;
}

.styled-table tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

.styled-table tbody tr:last-of-type {
  border-bottom: 2px solid #00c3c0;
}

.styled-table tbody tr:hover {
  background-color: #f4ffff;
}

.table_actions_icons {
  background-color: #34495e !important;
  color: white !important;
  margin: 0 5px;
  border-radius: 4px;
  padding: 10px 10px;
  cursor: pointer;
  display: flex;
}
.btn-delete {
  background-color: #962c2c !important;
}

.table_actions_icons:hover {
  background-color: #2c3e50 !important;
}
.new-param-buttonBack {
  background-color: #2c3e50;
  color: white;
  display: flex;

  padding: 20px;
  font-size: 0.9em;
}

.new-param-button {
  background-color: #00c3c0;
  color: white;
  display: flex;

  padding: 20px;
  font-size: 0.9em;
}

.new-param-button v-icon {
  margin-left: 8px;
}

.no-param-message {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 200px);
}

.centered-new-param-button {
  margin-bottom: 300px;
}

.tooltip-content {
  background-color: #808080;
  color: white;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 0.875em;
}

.filter_icon {
  background-color: #00c3c0;
}

.search_tf {
  font-style: italic;
  width: 25%;
  margin-bottom: 10px;
}

.cand_total {
  color: #00c3c0;
}

.mx-4 {
  margin-left: 16px;
  margin-right: 16px;
}

.bottom-pagination {
  position: absolute;
  bottom: 7%;
  width: 100%;
}

.new-param-bottom {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}
</style>
