import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import { loadFonts } from "./plugins/webfontloader";
import VueApexCharts from "vue3-apexcharts";
import { select_leng } from "./loadSessionData";

loadFonts();

async function initializeApp() {
  try {
    await select_leng();

    const app = createApp(App);
    app.use(router).use(store).use(vuetify).use(VueApexCharts).mount("#app");
  } catch (error) {
    console.error("Error loading session data:", error);
    // Aquí podrías manejar el error, por ejemplo mostrando un mensaje o redirigiendo a una página de error
  }
}

initializeApp();
