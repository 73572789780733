export default {
  spanish: [
    //Login
    "Iniciar Sesión", //0
    "Contacto", //1
    "Registrarse", //2
    "Acceder", //3
    "Rellene los siguientes campos obligatorios", //4
    "Evalúa valores, reduce los errores de contratación y optimiza la gestión del talento.", //5
    "Nombre Completo", //6
    "E-mail Corporativo", //7
    "Nombre de la empresa", //8
    "Contraseña", //9
    "Confirmar Contraseña", //10
    "*La contraseña debe contener mínimo 6 caracteres, máximo 20, dígitos, al menos una mayúscula y al menos una minúscula.", //11
    "He olvidado mi contraseña", //12
    "Continuar con Linkedin", //13
    "VERIFICACIÓN DE EMAIL", //14
    "Para continuar necesitamos verificar tu dirección de email. Por este motivo te enviamos un código de verificación al email proporcionado.", //15
    "Ingresa tu código de verificación aquí", //16
    "Código", //17
    "Validar Código", //18

    //Hacer Test
    "Identifica el talento que encaja con la cultura de la empresa", //19
    "Empezar", //20
    "Termina el test en menos de 6 minutos", //21
    "¡Nos encargamos de todo! Sólo tendrás que priorizar valores", //22
    "Construiremos la cultura actual y deseada a partir de tus respuestas", //23
    "Quiero hacerlo en otro momento", //24
    "Al completar el test regresa a esta pantalla para acceder a la plataforma", //25
    "Ir al Dashboard", //26

    //Editar usuario
    "Cerrar Sesión", //27
    "Datos de Usuario", //28
    "Cargo", //29
    "Teléfono de Contacto", //30
    "Nueva Contraseña", //31
    "Guardar", //32
    "Volver", //33
    "Guardar Cambios", //34
    "¿Está seguro de que desea guardar los cambios realizados?", //35
    "Cancelar", //36

    //Dashboard
    "", //37
    "Team & Company Diagnose", //38
    "Mindset Profiles", //39
    "Respuestas Recibidas", //40
    "Comparte este link si quieres que más miembros de la empresa contribuyan a la parametrización de la cultura", //41
    "Link al CFAM ", //42
    "Team & Company Assessment©", //43
    "Informe Detallado", //44
    "Contactar con un experto", //45
    "Contactar con soporte técnico", //46
    "PERFIL CULTURAL DE", //47
    "Cómo el equipo percibe la cultura vivida en la organización", //48
    "PREDOMINANCIA CULTURAL", //49
    "Ranking de culturas predominantes en la organización", //50
    "Cohesión cultural empresa-colaboradores", //51
    "Cohesión cultural entre la empresa y los colaboradores", //52
    "KPI de cohesión cultural", //53
    "KPI de cohesión cultural entre empresa y colaboradores", //54
    //Leyenda
    "Cultura actual", //55
    "Cultura equipo", //56
    //
    //Grado de egagement
    "Más de", //57
    "Menos de", //58
    //
    "Editar Perfil", //59
    "Copiar Link", //60
    "Obtener reporte", //61
    //Footer
    "Queda prohibida la reproducción total o parcial del material protegido por estos derechos de propiedad intelectual (NÚMERO DE ASIENTO REGISTRAL 04 / 2022 / 3374), o su uso en cualquier forma, o por cualquier medio, ya sea electrónico o mecánico, incluyendo fotocopiado, grabación, transmisión o cualquier sistema de almacenamiento y recuperación de información, sin el permiso por escrito de Cultural Fit Solutions S.L.", //62
    //Link Popup
    "Link copiado al portapapeles", //63
    "Para compartir el test de valores con tu equipo, sólo tienes que enviar este enlace a las personas que consideres", //64

    //Evaluacion de Perfiles
    "", //65 Haz click en este botón para compartir el assessment de mentalidad con las personas que quieras evaluar
    "Link al CFAM", //66  Linea 1 del boton para dispositivos grandes
    "Mindset Assessment©", //67 Linea 2 del boton para dispositivos grandes
    "Assessment de", //68 Linea 1 del boton para dispositivos medianos
    "Evaluación de Perfiles", //69 Linea 2 del boton para dispositivos medianos
    "", //70
    "Etiquetas", //71
    "Sin Etiquetas", //72
    "Nueva", //73
    "Nueva Etiqueta", //74
    "Nombre de la Etiqueta", //75
    "Crear", //76
    "Editar Etiqueta", //77
    "Eliminar", //78
    "¿Está seguro que desea eliminar esta etiqueta?", //79
    "Se eliminará a todos lo candidatos que la tengan asignada", //80
    "Escribe y filtra por la palabra que quieras", //81

    //Campos de la tabla
    "Email", //82
    "Nombre Candidato", //83
    "MindsetFit", //84
    "Fit Per-Cult Meta", //85
    "Mentalidad Predominante", //86
    "Mentalidad Predominante 2", //87
    "Acciones", //88
    //
    "Total Candidatos", //89
    "Ítems por página", //90
    "Todos", //91
    "Para compartir el test de valores con los perfiles que quieras evaluar, sólo tienes que enviar este enlace a las personas que consideres", //92
    "Identifica en qué grado encaja la mentalidad de la persona con la mentalidad parametrizada", //93
    "Identifica en qué grado encaja la mentalidad de la persona con la cultura deseada", //94
    "Al registrarte aceptas nuestra política de Uso de Datos y Prestación de Servicios. Para obtener más información, entra", //95

    "aquí", //96
    "Predominancia", //97

    //Candidate Detailed View
    "Mentalidad", //98
    "Cultural Fit", //99
    "Fecha de Registro", //100
    "Su periodo de pruebas ha finalizado. Para seguir utilizando CFAM póngase en contacto con nuestro Dpto de Ventas", //101
    "Credenciales incorrectas (email y/o  contraseña). Vuelva a intentarlo escribiendo de nuevo su email y/o contraseña. Si sigue sin poder acceder pulse ‘He olvidado mi contraseña’ y recibirá un email para restaurarla.", //102
    "Ética", //103
    "Liderazgo", //104
    "Valores", //105
    "Parametrización", //106
    "Mindset Fit", //107
    "Parametrizar Mentalidad Ideal", //108
  ],

  english: [
    "Sign in", //0
    "Contact", //1
    "Sign up", //2
    "Log in", //3
    "Fill in the following mandatory fields", //4
    "Evaluate values, reduce hiring errors and optimize talent management.", //5
    "Full Name", //6
    "Corporate Email", //7
    "Company Name", //8
    "Password", //9
    "Confirm Password", //10
    "*Password must contain at least 6 characters, maximum 20, digits, at least one uppercase and at least one lowercase.", //11
    "I forgot my password", //12
    "Continue with Linkedin", //13
    "EMAIL VERIFICATION", //14
    "To continue we need to verify your email address. For this reason we send you a verification code to the email provided.", //15
    "Enter your verification code here", //16
    "Code", //17
    "Validate Code", //18
    "Identify the talent that fits with the company culture", //19
    "Start", //20
    "Finish the test in less than 6 minutes", //21
    "We take care of everything! You will only have to prioritize values", //22
    "We will build the current and desired culture based on your answers", //23
    "Do it in another moment", //24
    "After completing the test return to this screen to access the platform", //25
    "Go to Dashboard", //26
    "Log out", //27
    "User Data", //28
    "Position", //29
    "Contact Phone", //30
    "New Password", //31
    "Save", //32
    "Back", //33
    "Save Changes", //34
    "Are you sure you want to save the changes made?", //35
    "Cancel", //36
    "", //37
    "Team & Company Diagnose", //38
    "Mindset Profiles", //39
    "Received Answers", //40
    "Share this link if you want more company members to contribute to culture parameterization", //41
    "Internal Assessment", //42
    "Anonymous", //43
    "Detailed Report", //44
    "Contact us", //45
    "Contact support", //46
    "CULTURAL PROFILE OF", //47
    "How the team perceives the culture lived in the organization", //48
    "CULTURAL DOMINANCE", //49
    "Ranking of predominant cultures in the organization", //50
    "Cultural cohesion between company and employees", //51
    "Cultural cohesion between the company and its employees", //52
    "Cultural cohesion KPI", //53
    "KPI for cultural cohesion between company and collaborators", //54
    "Current Culture", //55
    "Team Culture", //56
    "More than", //57
    "Less than", //58
    "Edit Profile", //59
    "Copy Link", //60
    "Get report", //61
    "It is forbidden to reproduce in whole or in part the material protected by these intellectual property rights (REGISTRATION NUMBER 04 / 2022 / 3374), or its use in any form, or by any means, whether electronic or mechanical, including photocopying, recording, transmission or any information storage and retrieval system, without the written permission of Cultural Fit Solutions S.L.", //62
    "Link copied to clipboard", //63
    "To share the values test with your team, you just have to send this link to the people you consider", //64
    "Share the link to the Profile Assessment Assessment to the people you want to analyze", //65
    "Link to the Mindset Assessment", //66
    "Profile", //67
    "Assessment of", //68
    "Profile Assessment", //69
    "Not Anonymous", //70
    "Tags", //71
    "No Tags", //72
    "New", //73
    "New Tag", //74
    "Tag Name", //75
    "Create", //76
    "Edit Tag", //77
    "Delete", //78
    "Are you sure you want to delete this tag?", //79
    "All candidates assigned to it will be deleted", //80
    "Write and filter by the word you want", //81
    "Email", //82
    "Candidate Name", //83
    "MindsetFit", //84
    "Fit Per-Cult Meta", //85
    "Dominant Mindset ", //86
    "Dominant Mindset 2", //87
    "Actions", //88
    "Total Candidates", //89
    "Items per page", //90
    "All", //91
    "To share the values test with the profiles you want to evaluate, you just have to send this link to the people you consider", //92
    "Identify the degree to which the person's mindset fits the current culture", //93
    "Identify the degree to which the person's mindset fits the desired culture", //94
    "By registering you accept our Data Use and Service Provision policy. For more information, enter", //95
    "here", //96
    "Dominance", //97
    "Mindset", //98
    "Cultural Fit", //99
    "Registration Date", //100
    "Your trial period has ended. To continue using CFAM please contact our Sales Dept", //101
    "Incorrect credentials (email and/or password). Please try again by typing your email and/or password again. If you still can't access click 'I forgot my password' and you will receive an email to restore it.", //102
    "Ethics", //103
    "Leadership", //104
    "Values", //105
    "Parameterization", //106
    "Ideal Profile", //107
    "Parameterize Ideal Mindset", //108
  ],
};
