<template>
  <v-card
    elevation="0"
    :class="
      mdAndUp
        ? 'overflow-auto principles_card cardwhitePValues mt-7 '
        : 'mt-1 mb-10 text-center'
    "
    :height="principles_card_height"
    width="100%"
  >
    <v-row dense class="header-row">
      <v-col cols="3" class="d-flex align-center justify-center borderblackeEV">
        <h5 class="text-uppercase">Valores</h5>
      </v-col>
      <v-col cols="6" class="d-flex align-center justify-center borderblackeEV">
        <h5 class="text-uppercase">Comportamientos</h5>
      </v-col>
      <v-col cols="3" class="d-flex align-center justify-center borderblackeEV">
        <h5 class="text-uppercase">Probabilidad</h5>
      </v-col>
    </v-row>

    <v-row dense class="data-row" style="height: 100%">
      <v-col cols="12" md="12" class="d-flex flex-column justify-space-between">
        <div
          v-for="item in sortedEthicalData"
          :key="item"
          class="d-flex justify-space-around align-center data-entry my-1"
        >
          <v-col cols="3" class="d-flex align-center justify-center">
            <p class="descriptionPredValues">{{ item.value }}</p>
          </v-col>
          <v-col cols="6" class="d-flex align-center justify-center pa-0">
            <p class="descriptionPredValues">
              {{ item.behaviour }}
            </p>
          </v-col>
          <v-col
            cols="3"
            class="d-flex align-center justify-center pa-0 h-100"
            :style="{ backgroundColor: item.backgroundColor }"
          >
            <p class="descriptionPredValues">
              {{ item.probability }}
            </p>
          </v-col>
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { mapState } from "vuex";
import { computed } from "vue";
import { useDisplay } from "vuetify";

export default {
  name: "EthicCommonBehavior",

  data: () => ({}),

  computed: {
    ...mapState(["cultureProfileData", "ethicalData"]),
    sortedEthicalData() {
      return this.ethicalData.integrityProb
        .map((prob, index) => {
          let backgroundColor;
          switch (prob) {
            case "Baja":
              backgroundColor = "#FFFC8B";
              break;
            case "Media":
              backgroundColor = "#E7F0DE";
              break;
            case "Alta":
              backgroundColor = "#B1D095";
              break;
            default:
              backgroundColor = "transparent";
              break;
          }
          return {
            value: this.ethicalData.integrityValues[index],
            behaviour: this.ethicalData.integrityValBehaviour[index],
            probability: prob,
            backgroundColor: backgroundColor,
          };
        })
        .sort((a, b) => {
          // Ordenar por probabilidad de forma ascendente
          if (a.probability < b.probability) return -1;
          if (a.probability > b.probability) return 1;
          return 0;
        });
    },
  },

  setup() {
    // Destructure only the keys we want to use
    const { mdAndUp } = useDisplay();
    const { xsAndUp } = useDisplay();

    const { name } = useDisplay();

    const principles_card_height = computed(() => {
      // name is reactive and
      // must use .value
      switch (name.value) {
        case "xs":
          return "auto";
        case "sm":
          return "auto";
        case "md":
          return (80 * ((92 * window.innerHeight) / 100)) / 100;
        case "lg":
          return (74 * ((92 * window.innerHeight) / 100)) / 100;
        case "xl":
          return (83 * ((92 * window.innerHeight) / 100)) / 100;
        case "xxl":
          return (83 * ((92 * window.innerHeight) / 100)) / 100;
      }

      return undefined;
    });

    // eslint-disable-next-line vue/no-dupe-keys
    return {
      principles_card_height,
      mdAndUp,
      xsAndUp,
    };
  },
};
</script>

<style>
/* Estilos para el texto de descripción en las celdas */
.descriptionPredValues {
  color: #636363; /* Color de texto general */
  font-size: 13px; /* Tamaño de texto general */
}

/* Estilos para las celdas de los valores */
.linencolorPredValues {
  border-bottom: 1px solid #e0e0e0;
}

/* Estilos para las cabeceras */
.borderblackeEV {
  border-bottom: 1px solid #34495e; /* Borde inferior para las cabeceras */
}

/* Estilos generales para la tarjeta */
.principles_card {
  overflow-y: auto; /* Habilita el desplazamiento vertical si el contenido es más alto que el contenedor */
  background-color: #ffffff; /* Fondo blanco para la tarjeta */
  border-radius: 10px; /* Bordes redondeados para la tarjeta */
  border: 1px solid #efefef; /* Borde sutil alrededor de la tarjeta */
}

/* Ajustes para el scrollbar de la tarjeta */
.principles_card::-webkit-scrollbar {
  width: 10px; /* Ancho del scrollbar */
}

.principles_card::-webkit-scrollbar-thumb {
  background-color: #00c3c0;
  border-radius: 20px;
  border: 2px solid #f1f2f3;
}

.principles_card::-webkit-scrollbar-track {
  border-radius: 10px;
}

/* Media queries para ajustes responsivos */
@media (max-width: 768px) {
  /* Estilos para dispositivos más pequeños como tabletas */
  .descriptionPredValues {
    font-size: 12px; /* Tamaño de texto más pequeño para una mejor legibilidad */
  }

  /* Posibles ajustes adicionales para mejorar la presentación en dispositivos más pequeños */
}

@media (max-width: 600px) {
  /* Estilos para dispositivos móviles */
  .descriptionPredValues {
    font-size: 11px; /* Tamaño de texto aún más pequeño para pantallas de móviles */
  }

  /* Ajustes adicionales para una mejor visualización en móviles */
}
</style>
